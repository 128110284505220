import { useSelector, useDispatch } from "react-redux";
import { itemsNotTransferred } from "@zaxbys/wrapper/frontendwrapper/redux/basket/actions";
import { setReorderNotificationPopup } from "redux/reducer/ReOrder/ReOrderAction";
import { RootState, UnavailableProductResponse } from "types/index";
import Paragraph from "components/atomic-components/atoms/typography/Paragraphs/Paragraph";
import Headings from "components/atomic-components/atoms/typography/Headings/Headings";
import ProductCard from "components/atomic-components/atoms/ProductCard/ProductCard";
import ScrollableModal from "components/atomic-components/atoms/layout-elements/ScrollableModal/ScrollableModal";
import { ItemsNotAddedModalStyled } from "./ItemsNotAddedModalStyled";

const ItemsNotAddedModal = () => {
  const { itemsNotTransfer, device, basket } = useSelector((state: RootState) => ({
    itemsNotTransfer: state.basket?.itemsNotTransferred,
    device: state.currentDevice?.device,
    basket: state.basket?.basket,
  }));

  const dispatch = useDispatch();
  const closeModal = () => {
    dispatch(itemsNotTransferred([]));
    dispatch(setReorderNotificationPopup(true));
  };

  const isMobile = device === "MOBILE";
  const areAnyAdded = !basket?.products.length;
  const shouldNotScroll = itemsNotTransfer?.length < 3;

  return (
    <ScrollableModal showCloseButton isOpen onClose={closeModal} closeIconVariant="whiteVariant">
      <ItemsNotAddedModalStyled shouldNotScroll={shouldNotScroll}>
        <div className="header">
          <Headings className="heading" variant="primaryHeading3">
            We couldn&apos;t add
          </Headings>
          <div className="second-row">
            <Headings variant="secondaryHeading1" mobileFontSize={21} className="highlight">
              {!areAnyAdded ? "ALL" : "ANY"}&nbsp;&nbsp;
            </Headings>
            <Headings variant="primaryHeading3">{!areAnyAdded ? "" : "OF"} your items</Headings>
          </div>
          <Paragraph variant="secondaryParagraph3" className="subtitle">
            The items below are not available at your chosen location.
          </Paragraph>
        </div>
        <div className="body">
          {itemsNotTransfer?.map((item: UnavailableProductResponse, index: number) => (
            <ProductCard key={item.productId} item={item} isMobile={isMobile} />
          ))}
        </div>
      </ItemsNotAddedModalStyled>
    </ScrollableModal>
  );
};

export default ItemsNotAddedModal;
