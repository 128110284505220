import PrimaryButton from "components/global/Buttons/PrimaryButton/PrimaryButton";
import SecondaryButton from "components/global/Buttons/SecondaryButton/SecondaryButton";
import StackedButtonsContainer from "components/global/StackedButtonsContainer/StackedButtonsContainer";
import AddressDeliveryCard from "../AddressDeliveryCard/AddressDeliveryCard";

import { FooterOrderInfo } from "../DeliveryCardStyles";

interface Props {
  onBack: () => void;
  etaMin: number;
  etaMax: number;
  onConfirmAddress: () => void | null;
  onDeliverHere: () => void | null;
  className?: string;
}

const AddressSelectedCardDelivery = ({ onBack, etaMin, etaMax, onConfirmAddress, onDeliverHere, className }: Props) => {
  const Footer = (
    <>
      <FooterOrderInfo variant="BLUE">DELIVERY</FooterOrderInfo>
      <FooterOrderInfo variant="RED">
        ETA {etaMin} - {etaMax}MIN
      </FooterOrderInfo>
      <StackedButtonsContainer direction="col">
        <SecondaryButton onClick={onConfirmAddress} variant="second">
          CONFIRM ADDRESS
        </SecondaryButton>
        <PrimaryButton onClick={onDeliverHere} variant="fourth">
          DELIVER HERE
        </PrimaryButton>
      </StackedButtonsContainer>
    </>
  );

  return <AddressDeliveryCard className={className} onBack={onBack} Footer={Footer} isUserAddress />;
};

export default AddressSelectedCardDelivery;
