import { apiCall } from "@zaxbys/wrapper/frontendwrapper/apis/api";
import { CommonLayerResponseDataWithType } from "src/types";
import { CMS_NEWS_PAGE } from "api/endpoints";
import { NewsCMSData } from "types/cms/news";

export const getNewsCMSData = () =>
  apiCall({
    requestType: "GET",
    apiEndPoint: CMS_NEWS_PAGE,
  }) as Promise<CommonLayerResponseDataWithType<NewsCMSData>>;
