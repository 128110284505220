import { apiCall } from "@zaxbys/wrapper/frontendwrapper/apis/api";
import { CommonLayerResponseDataWithType } from "src/types";
import { CMS_FRANCHISE_HOME } from "api/endpoints";
import { FranchiseHomeCMSData } from "types/cms/franchise-home";

export const getFranchiseHomeCMSData = () => {
  return apiCall({
    requestType: "GET",
    apiEndPoint: CMS_FRANCHISE_HOME,
  }) as Promise<CommonLayerResponseDataWithType<FranchiseHomeCMSData>>;
};
