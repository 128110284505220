import mParticle from "@mparticle/web-sdk";
import { ANALYTICS_CONST } from "@zaxbys/wrapper";
import Analytics from "analytics/Analytics";

export const getPrice = (product: any) => {
  if (product?.quantity === 1) return product?.totalCost;
  return product?.totalCost / product?.quantity;
};

export const createProductForMparticle = (
  productName: string,
  productId: number,
  productCost: number,
  productQuantity: number,
  customAttributes: {}
) => {
  // TODO refactor after checking how to test or disable analytics during testing
  try {
    return mParticle.eCommerce.createProduct(
      productName ?? "", // Name
      productId?.toString() ?? "", // SKU
      productCost ?? 0, // Price
      productQuantity ?? 0, // Quantity
      undefined, // Variant
      undefined, // Category
      undefined, // Brand
      undefined, // Position
      undefined, // Coupon code
      { ...customAttributes } // The custom attributes defined in a separate variable above
    );
  } catch {
    return {
      Name: "",
      Sku: "",
      Price: 0,
      Quantity: 0,
      Variant: "",
      Category: "",
      Brand: "",
      Position: 0,
      Coupon: "",
      Attributes: undefined,
    };
  }
};

export const createProductForAmplitude = (product: any) => {
  return {
    item_id: product?.productId, // Name
    item_name: product?.name, // SKU
    price: getPrice(product), // Price
    currency: "USD",
    quantity: product?.quantity, // Quantity
  };
};

export const logAnalyticsEvents = (response: any, payload: any) => {
  const mparticleProduct: any = [];
  const amplitudeProduct: any = [];

  if (response?.products?.length > 0) {
    // add to cart event on recent order and Fav orders
    response?.products?.forEach((product: any) => {
      // mparticle Product creation
      mparticleProduct.push(
        createProductForMparticle(product?.name, product?.productId, getPrice(product), product?.quantity, {
          currency: "USD",
          store_id: payload?.storeId,
          modifiers: JSON.stringify(product?.choices) ?? null,
        })
      );

      amplitudeProduct.push(createProductForAmplitude(product));
    });
    // amplitude add to cart event
    Analytics.getInstance().logEvent(
      ANALYTICS_CONST.ORDER.ADD_TO_CART,
      {
        ecommerce: {
          store_id: payload?.storeId,
          value: amplitudeProduct?.cost,
          items: [...amplitudeProduct],
        },
      },
      false
    );
    // mparticle add to cart event
    mParticle.eCommerce.logProductAction(mParticle.ProductActionType.AddToCart, mparticleProduct);
  } else if (response?.unavailableProducts?.length > 0) {
    response?.unavailableProducts?.forEach((product: any) => {
      mparticleProduct.push(
        createProductForMparticle(product?.name, product?.productId, getPrice(product), product?.quantity, {
          currency: "USD",
          store_id: payload?.storeId,
          modifiers: JSON.stringify(product?.choices) ?? null,
        })
      );
      amplitudeProduct.push(createProductForAmplitude(product));
    });
    // amplitude remove from cart event
    Analytics.getInstance().logEvent(
      ANALYTICS_CONST.ORDER.REMOVE_ITEM_FROM_CART,
      {
        ecommerce: {
          store_id: payload?.storeId,
          value: amplitudeProduct?.cost,
          items: [...amplitudeProduct],
        },
      },
      false
    );
    // mparticle remove from cart event
    mParticle.eCommerce.logProductAction(mParticle.ProductActionType.RemoveFromCart, mparticleProduct);
  }
};

export const mparticleLogoutIdentity = () => {
  const identityCallback = function (result: any) {
    if (result.getUser()) {
      // proceed with any action on logout
    }
  };
  mParticle.Identity.logout({}, identityCallback);
};
