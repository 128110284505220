import styled from "styled-components";

export const AccountCreatedStyled = styled.h1`
  text-align: center;
  font-size: 32px;
  font-family: ${(props) => props.theme.font.barlowCondensed};
  font-weight: 700;
  color: ${(props) => props.theme.color.core.white};
  .accountCreatedModal {
    color: ${(props) => props.theme.color.primary.foundersBlue};
    font-size: 40px;
  }
  .accountSubTitle {
    color: ${(props) => props.theme.color.limitedUse.pureWhite};
    font-size: 24px;
  }

  .underline {
    text-decoration: underline;
    font-family: ${(props) => props.theme.font.barlow};
    margin-left: 2px;
    color: ${(props) => props.theme.color.primary.coleslawWhite};
  }

  .layout {
    display: flex;
    flex-direction: row;
    margin-top: 20px;

    @media only screen and (max-width: ${(props) => props.theme.size.lg}) {
      width: 197px;
      margin-top: 40px;
      justify-content: center;
      font-size: 14px;
      .regularText {
        font-size: 14px;
      }
    }
  }
  .regularText {
    font-family: ${(props) => props.theme.font.barlow};
    text-transform: none;
    color: ${(props) => props.theme.color.primary.coleslawWhite};
    @media only screen and (min-width: ${(props) => props.theme.size.lg}) {
      margin-left: 40px;
      margin-top: 0;
    }
  }

  .cardFooter {
    padding: 20px 30px;
    padding-bottom: 0;
    @media only screen and (max-width: ${(props) => props.theme.size.md}) {
      padding: 30px 30px 20px;
    }
  }
  .authCardClose {
    background: ${(props) => props.theme.color.primary.coleslawWhite};
    top: 40px;
    right: 28px;
    padding: 0;

    @media only screen and (min-width: ${(props) => props.theme.size.md}) {
      top: 28px;
    }
    @media only screen and (max-width: ${(props) => props.theme.size.sm}) {
      top: 50px;
    }
  }
  .buttonPrimary {
    width: 180px;
    height: 44px;
    @media only screen and (max-width: ${(props) => props.theme.size.md}) {
      width: 148px;
      height: 36px;
    }
  }
  ${(props) => props.theme.responsive?.max.lg} {
    .authCardClose {
      top: 40px;
    }
    .modal {
      width: 272px;
    }
    .accountModal {
      width: 272px;
      margin: 0 auto;
    }
    .cardbody {
      padding: 0;
    }
  }
`;

export const HeaderStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  .welcomeBackTitle {
    color: ${(props) => props.theme.color.primary.tongueTorchRed};
    padding-left: 5px;
    padding-top: 5px;
    font-size: 34px;
  }

  ${(props) => props.theme.responsive?.max.lg} {
    display: flex;
    flex-direction: column;
    .accountCreatedModal {
      margin: 20px 0px 0px 0px;
      color: ${(props) => props.theme.color.primary.foundersBlue};
      font-size: 24px !important;
      font-weight: 800;
    }
    .welcomeBackTitle {
      font-size: 30px;
      font-weight: 700;
    }
    .accountSubTitle {
      margin-bottom: 20px;
    }
  }
`;
export const BackButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: ${(props) => props.theme.font.barlow};
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;

  .buttons {
    gap: 16px;
  }
`;

export const SubHeading = styled.p`
  font-family: ${(props) => props.theme.font.barlow};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 15px;
  text-align: center;
  padding: 0 10px;
  color: ${(props) => props.theme.color.limitedUse.pureWhite};
  margin-bottom: 10px;
  @media only screen and (max-width: ${(props) => props.theme.size.lg}) {
    max-width: 400px;
    margin: 10px;
  }
`;

export const Additional = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Buttons = styled.div`
  display: flex;
  width: 100%;

  @media only screen and (min-width: ${(props) => props.theme.size.xs}) {
    flex-direction: column;
  }

  @media only screen and (min-width: ${(props) => props.theme.size.md}) {
    flex-direction: row;
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 32px;
  .scaledText {
    margin: 0px auto 20px;
    font-size: 16px;
  }
  .buttons {
    gap: 20px;
  }
  ${(props) => props.theme.responsive?.max.lg} {
    padding-top: 12px;
    .scaledText {
      padding: 0 40px;
      padding-top: 12px;
      font-size: 14px;
      font-weight: 400;
    }
  }
`;
