import Image from "next/image";
import { ChangeEvent, MouseEventHandler, MutableRefObject, KeyboardEvent } from "react";
import { useSelector } from "react-redux";
import { RootState, SmartyStreet } from "src/types";
import { IC_LOCATION_FILTER } from "assets/images";
import GpsIcon from "components/atomic-components/atoms/GpsIcon/GpsIcon";
import TextField from "components/atomic-components/atoms/form-elements/TextField/TextField";
import { LocationSearchContainerStyled } from "./LocationSearchContainerStyled";

interface SearchOption {
  searchAddress: string;
  isDeliveryOptionSelected: boolean;
  searchAddressSmarty: string;
}

export interface LocationSearchContainerProps {
  searchOption: SearchOption;
  handleChange: (name: string, value: string, e?: ChangeEvent<HTMLInputElement>) => void;
  inputRef?: MutableRefObject<HTMLInputElement | null>;
  handleKeyDownSearch: (e: KeyboardEvent<HTMLInputElement>) => void;
  errorMessageFlag: boolean;
  smartySuggestionClassName?: string;
  smartySuggestion?: SmartyStreet[];
  getStoresList: Function;
  pickupInputLabel?: string;
  handleClearIcon: MouseEventHandler<HTMLDivElement>;
  handleGPSIconClick: () => void;
  handleLocationFilterClick?: () => void;
}

const LocationSearchContainer = ({
  searchOption,
  handleChange,
  inputRef,
  handleKeyDownSearch,
  errorMessageFlag,
  smartySuggestionClassName,
  smartySuggestion,
  getStoresList,
  pickupInputLabel,
  handleClearIcon,
  handleGPSIconClick,
  handleLocationFilterClick,
}: LocationSearchContainerProps) => {
  const { isAuthenticated, userCurrentLocation } = useSelector((state: RootState) => ({
    isAuthenticated: state.user.isAuthenticated,
    userCurrentLocation: state.globalReducer?.userCurrentLocation,
  }));

  const hasPlaceHolder = searchOption.searchAddress?.length === 0 && Boolean(userCurrentLocation);

  const hightlightText = (text: string) => {
    const regex = new RegExp(searchOption.searchAddressSmarty, "gi");
    const highlightedText = text.replace(/(<strong class="highlight">|<\/strong>)/gim, "");
    return highlightedText.replace(regex, '<strong class="highlight">$&</strong>');
  };

  return (
    <LocationSearchContainerStyled
      className={`searchContainer ${!isAuthenticated ? "notLoggedIn" : ""}`}
      hasPlaceHolder={searchOption.isDeliveryOptionSelected ? searchOption.searchAddressSmarty?.length !== 0 : hasPlaceHolder}
    >
      {searchOption.isDeliveryOptionSelected ? (
        <>
          <TextField
            name="searchAddressSmarty"
            value={searchOption.searchAddressSmarty}
            onChange={handleChange}
            onClear={handleClearIcon}
            label="Delivery Address"
            id="searchAddressSmarty"
            placeholder=""
            className="searchInput"
            isSearch
            isClear={searchOption.searchAddressSmarty.length > 0}
            customRef={inputRef}
            onKeyDown={handleKeyDownSearch}
            disabled={errorMessageFlag}
          />
          <div className={smartySuggestionClassName}>
            {smartySuggestion &&
              smartySuggestion.length > 0 &&
              smartySuggestion.map((item: SmartyStreet, index: number) => {
                return (
                  <li
                    className="addressItem"
                    key={`${item.addressLineOne}-${index}`}
                    onClick={() => getStoresList(item)}
                    role="presentation"
                  >
                    <span className="pinIcon"></span>
                    <div>
                      <div
                        className="addressOneSmarty"
                        dangerouslySetInnerHTML={{
                          __html: `${hightlightText(item.addressLineOne)}`,
                        }}
                      />
                      <div>{`${item.city}, ${item.state}`}</div>
                    </div>
                  </li>
                );
              })}
          </div>
        </>
      ) : (
        <TextField
          name="searchAddress"
          id="searchAddress"
          value={searchOption.searchAddress}
          onChange={handleChange}
          className="searchInput"
          label={pickupInputLabel}
          placeholder={hasPlaceHolder ? "Near Me" : ""}
          onKeyDown={handleKeyDownSearch}
          customRef={inputRef}
          isSearch
          isClear={searchOption.searchAddress.length > 0}
          onClear={handleClearIcon}
        />
      )}
      <GpsIcon onClick={handleGPSIconClick} className="searchGpsIcon" />
      {!searchOption?.isDeliveryOptionSelected && (
        <Image src={IC_LOCATION_FILTER} alt="Filter Icon" className="filter-icon" onClick={handleLocationFilterClick} />
      )}
    </LocationSearchContainerStyled>
  );
};

export default LocationSearchContainer;
