import { useDispatch, useSelector } from "react-redux";
import useModal from "hooks/useModal";
import { RootState } from "src/types";
import Button from "components/atomic-components/atoms/button/Button";
import { setIsPasswordResetLimitExceeded, setMigratedUserInfo } from "redux/reducer/UserStatus/UserStatusAction";
import ValueUpdated from "../ValueUpdated/ValueUpdated";

const PasswordUpdated = () => {
  const { setModal } = useModal();
  const dispatch = useDispatch();
  const migratedUser = useSelector((state: RootState) => state.userStatus.migratedUser);

  const isMigratedUser = migratedUser.errorCode === 1106;

  const setLoginModal = () => {
    dispatch(setMigratedUserInfo({ errorCode: 0, username: "" }));
    dispatch(setIsPasswordResetLimitExceeded(false));
    setModal("LOGIN");
  };

  const buttons = (
    <>
      <Button className="closeButton" variant="secondaryBlue" onClick={() => setLoginModal()}>
        close
      </Button>
      <Button className="loginInButton" variant="primaryBlue" onClick={() => setLoginModal()}>
        log in
      </Button>
    </>
  );
  return (
    <ValueUpdated
      valueName={isMigratedUser ? "account" : "password"}
      actionType={isMigratedUser ? "migrated!" : "updated"}
      buttons={buttons}
      successMessage="Hurray! Use your new password to securely Log In."
    />
  );
};

export default PasswordUpdated;
