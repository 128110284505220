import { apiCall } from "@zaxbys/wrapper/frontendwrapper/apis/api";
import { CommonLayerResponseDataWithType } from "src/types";
import { CMS_RESTAURANT_OPPORTUNITIES } from "api/endpoints";
import { RestaurantOpportunitiesCMSData } from "types/cms/restaurant-opportunities";

export const getRestaurantOpportunitiesData = () => {
  return apiCall({
    requestType: "GET",
    apiEndPoint: CMS_RESTAURANT_OPPORTUNITIES,
  }) as Promise<CommonLayerResponseDataWithType<RestaurantOpportunitiesCMSData>>;
};
