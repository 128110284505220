import { useSelector } from "react-redux";
import Headings from "components/atomic-components/atoms/typography/Headings/Headings";
import LocationTabContainer from "components/atomic-components/molecules/LocationTabContainer/LocationTabContainer";
import LocationTabDisplayList from "components/atomic-components/molecules/LocationTabDisplayList/LocationTabDisplayList";
import LocationNoStoreFound from "components/atomic-components/molecules/LocationNoStoreFound/LocationNoStoreFound";
import ErrorModal from "components/atomic-components/molecules/ErrorModal/ErrorModal";
import ToggleInput from "components/atomic-components/atoms/form-elements/ToggleInput/ToggleInput";
import { PICKUP_TABS } from "constants/constant";
import useSearchCard from "./useSearchCard";
import NearByCardContainer from "./NearBy/NearByCardContainer";
import { SearchCardStyled } from "./SearchCardStyled";

interface Props {
  className?: string;
  isLoggedIn?: boolean;
  onClose?: () => void;
}

const SearchCard = ({ className }: Props) => {
  const {
    searchOption,
    handleClearIcon,
    handleGPSIconClick,
    handleChange,
    handleToggle,
    inputRef,
    handleKeyDownSearch,
    isAlternativeFlow,
    smartySuggestion,
    getStoresList,
    errorMessageFlag,
    storeList,
    showLocationEnableModal,
    variant,
    activeTAB,
    tabOptions,
    selectActive,
    isAuthenticated,
    userCurrentLocation,
    hasFaveOrRecentStores,
  } = useSearchCard();

  const { device } = useSelector((state: any) => ({
    device: state?.currentDevice.device,
  }));

  const showStartingText = searchOption.searchAddress.length === 0 && !isAlternativeFlow && activeTAB === "search";
  const startingText = searchOption.isDeliveryOptionSelected
    ? "Where do you want your Zaxbys delivered?"
    : "Enter your location and we’ll find the nearest Zaxbys for your pickup order.";
  const startingTextWidthForDeliveryOptionSearch = device === "MOBILE" ? "144" : "200";
  const widthBasedOnDevice = device === "MOBILE" ? "230" : "274";
  const startingTextWidth = searchOption.isDeliveryOptionSelected ? startingTextWidthForDeliveryOptionSearch : widthBasedOnDevice;
  const smartySuggestionClassName = smartySuggestion?.length ? "storeAddressContainer" : "d-none";
  const pickupInputLabel = searchOption.isDeliveryOptionSelected ? "Delivery Address" : "City, State, or Zip";
  const renderComponentBasedonActiveTab = () => {
    // Display nearby stores based on location for pickup only

    if (storeList?.length > 0 && !searchOption.isDeliveryOptionSelected && activeTAB === PICKUP_TABS[0].id && !hasFaveOrRecentStores) {
      return <NearByCardContainer />;
    }

    if (showStartingText && userCurrentLocation && storeList?.length === 0 && !searchOption.isDeliveryOptionSelected) {
      return <LocationNoStoreFound className="noFavoriteStoresText" descriptionLineOne="There are no stores nearby." />;
    }

    if (showStartingText) {
      // Default message display, if user has not searched anything yet.
      // Probably it won't be display because we are showing No Result for near by stores on the page load
      return <LocationNoStoreFound className="noFavoriteStoresText" descriptionLineOne={startingText} width={startingTextWidth} />;
    }

    // Display Fav Address and Address Book if user is authenticated and for delivery only
    if (isAuthenticated && searchOption.isDeliveryOptionSelected) {
      return <LocationTabDisplayList {...{ variant: "delivery", activeTAB }} />;
    }

    // Display Recent Stores and Fav Stores if user is authenticated and for pickup only
    if (isAuthenticated && activeTAB !== PICKUP_TABS[0].id && !searchOption.isDeliveryOptionSelected) {
      return <LocationTabDisplayList {...{ variant: "pickup", activeTAB }} />;
    }

    if (activeTAB === PICKUP_TABS[0].id) {
      return (
        <LocationNoStoreFound
          descriptionLineOne="Good flavor is hard to find!"
          descriptionLineTwo=" Search again to find your nearest Zaxbys"
          title="No Results"
          width="274"
          className="noResults"
        />
      );
    }
  };

  return (
    <SearchCardStyled
      className={className}
      isSearching={searchOption.searchAddress.length >= 3}
      isDelivery={searchOption.isDeliveryOptionSelected}
      isAuth={isAuthenticated}
    >
      {isAlternativeFlow && (
        <div className="underlinedHeading">
          <Headings variant="primaryHeading2" fontSize={32} mobileFontSize={32}>
            SELECT A&nbsp;
          </Headings>
          <Headings variant="primaryHeading1Red" fontSize={28} mobileFontSize={28}>
            STORE
          </Headings>
        </div>
      )}
      {!isAlternativeFlow && (
        <ToggleInput
          optionFalse="PICKUP"
          optionTrue="DELIVERY"
          name="isDeliveryOptionSelected"
          value={searchOption.isDeliveryOptionSelected}
          onChange={handleToggle}
          className="searchCardtoggleInput"
          id="isDeliveryOptionSelected"
        />
      )}
      <LocationTabContainer
        {...{
          variant,
          searchOption,
          handleClearIcon,
          handleGPSIconClick,
          handleChange,
          inputRef,
          handleKeyDownSearch,
          smartySuggestion,
          getStoresList,
          pickupInputLabel,
          errorMessageFlag,
          smartySuggestionClassName,
          activeTAB,
          tabOptions,
          selectActive,
        }}
      />
      {renderComponentBasedonActiveTab()}
      {errorMessageFlag && showLocationEnableModal && (
        <ErrorModal
          heading="WHERE ARE YOU?"
          subHeading="To get the best experience, turn on location sharing  to find stores near you."
          className="locationErrorModal"
          showSubheadingInNextLine
          showCloseButton
        />
      )}
    </SearchCardStyled>
  );
};

export default SearchCard;
