import { RevenueProperties } from "types/index";
import AmplitudeEventListener from "./Amplitude";
import { AnalyticsListener } from "./AnalyticsListener";
import BrazeAnalyticsListener from "./BrazeAnalytics";
import MparticleEventListener from "./MparticleAnalytics";
import GTMAnalyticsListener from "./GTMAnalytics";

export default class Analytics {
  private static instance: Analytics;
  // eslint-disable-next-line no-array-constructor
  private static eventListeners = new Array<AnalyticsListener>();
  public static getInstance(): Analytics {
    if (!Analytics.instance) {
      Analytics.instance = new Analytics();
      Analytics.registerListeners();
    }
    return Analytics.instance;
  }

  private static registerListeners() {
    Analytics.eventListeners.push(new AmplitudeEventListener());
    Analytics.eventListeners.push(new BrazeAnalyticsListener());
    Analytics.eventListeners.push(new GTMAnalyticsListener());
    Analytics.eventListeners.push(new MparticleEventListener());
  }

  public init() {
    Analytics.eventListeners.forEach((eventListener) => {
      eventListener.init();
    });
  }

  public getExperiment() {
    const amplitudeEventListener = Analytics.eventListeners.find(
      (eventListener) => eventListener instanceof AmplitudeEventListener
    ) as AmplitudeEventListener;
    return amplitudeEventListener.getExperiment();
  }

  private static isIncluded = (events: string[] | undefined, eventName: string) => {
    // if events undefined || empty, return true
    if (!events || events.length === 0) {
      return true;
    }
    // if event contains eventName then return true
    return events.includes(eventName);
  };

  private static isExcluded = (events: string[] | undefined, eventName: string) => {
    // if events undefined || empty, return true
    if (!events || events.length === 0) {
      return false;
    }
    // if event contains eventName then return true
    return events.includes(eventName);
  };

  public logEvent(eventName: string, properties?: Record<string, unknown>, isMparticle: boolean = true, isAmplitude: boolean = true) {
    Analytics.eventListeners.forEach((eventListener) => {
      const excludedEvents: string[] | undefined = eventListener?.getExcludedEvents?.();
      const includedEvents: string[] | undefined = eventListener?.getIncludedEvents?.();
      if (!Analytics.isExcluded(excludedEvents, eventName) && Analytics.isIncluded(includedEvents, eventName)) {
        eventListener.logEvent(eventName, properties, isMparticle, isAmplitude);
      }
    });
  }

  // Set user details
  public setIdentityDetails = (properties: Record<string, unknown>) => {
    Analytics.eventListeners.forEach((eventListener) => {
      eventListener.setIdentityDetails?.(properties);
    });
  };

  // Set user details
  public setUserId = (userId: string) => {
    Analytics.eventListeners.forEach((eventListener) => {
      eventListener.setUserId?.(userId);
    });
  };

  public handleError = (error: Error, errorInfo?: any) => {
    Analytics.eventListeners.forEach((eventListener) => {
      eventListener.handleError?.(error, errorInfo);
    });
  };

  public logRevenue(properties: RevenueProperties) {
    Analytics.eventListeners.forEach((eventListner) => {
      eventListner.logRevenue?.(properties);
    });
  }

  // log content card clicks
  public logContentCardImpressions(properties: []) {
    Analytics.eventListeners.forEach((eventListner) => {
      eventListner.logContentCardImpressions?.(properties);
    });
  }

  public logContentCardClick(properties: {}) {
    Analytics.eventListeners.forEach((eventListner) => {
      eventListner.logContentCardClick?.(properties);
    });
  }
}
