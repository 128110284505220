import { GiftCard, PaymentGiftCard } from "src/types";
import {
  APPLY_GIFT_CARD_BALANCE,
  GET_ALL_GIFT_CARDS,
  GIFT_CARD_PAYMENT,
  PREVIOUS_SELECTED_GIFT_CARD,
  RESET_GIFT_CARD_PAYMENT,
  SELECTED_GIFT_CARD,
  SET_IS_GIFT_CARDS_AVAILABLE,
  SET_IS_SHOW_USE_GIFT_CARD,
} from "./GiftCardsConst";

export const setGiftCards = (payload: any) => {
  return { type: GET_ALL_GIFT_CARDS, payload: payload };
};

export const setApplyBalanceAmt = (payload: number) => {
  return { type: APPLY_GIFT_CARD_BALANCE, payload: payload };
};

export const setCard = (data: GiftCard | [], isSinglePayment: boolean = false) => ({
  type: SELECTED_GIFT_CARD,
  payload: { data, isSinglePayment },
});

export const setPrevSelectedCard = (data: Array<GiftCard>) => ({
  type: PREVIOUS_SELECTED_GIFT_CARD,
  payload: data,
});

export const giftCardLaterAction = (payload: Array<PaymentGiftCard>) => {
  return { type: GIFT_CARD_PAYMENT, payload: payload };
};

export const resetGiftCardLaterAction = () => {
  return { type: RESET_GIFT_CARD_PAYMENT };
};

export const setIsGiftCardsAvailable = (payload: boolean) => {
  return { type: SET_IS_GIFT_CARDS_AVAILABLE, payload: payload };
};

export const setShowUseGiftCard = (payload: boolean) => {
  return { type: SET_IS_SHOW_USE_GIFT_CARD, payload: payload };
};
