import React, { useEffect } from "react";
import { ANALYTICS_CONST, PAGE_NAME } from "@zaxbys/wrapper";
import { confirmPasswordVerificationCode } from "api/users/confirm-password-verification-code";
import { getLocalStorageData } from "utils/storage";
import Analytics from "analytics/Analytics";
import useModal from "hooks/useModal";
import ConfirmPassword from "components/global/ConfirmPassword/ConfirmPassword";
import ForgotPasswordContext from "../ForgotPasswordContext";

function ForgotPasswordConfirm() {
  const { username, code, setStep } = React.useContext(ForgotPasswordContext);
  const { setModal } = useModal();

  const logPageViewEvent = async () => {
    const isUserMigrated = getLocalStorageData("isUserMigrated");

    if (isUserMigrated === "Yes") {
      Analytics.getInstance().logEvent("page_view", {
        page_name: PAGE_NAME.MIGRATION_CHANGE_PASSWORD,
      });
    }
  };

  useEffect(() => {
    logPageViewEvent();
  }, []);

  const handleSubmit = async (password: string) => {
    const isUserMigrated = getLocalStorageData("isUserMigrated");

    return confirmPasswordVerificationCode({
      username,
      code,
      password,
    })
      .then((res) => {
        if (res.success) {
          setStep("UPDATED");

          if (isUserMigrated === "Yes") {
            Analytics.getInstance().logEvent(ANALYTICS_CONST.AUTH.MIGRATION_PASSWORD_SUCCESS);
          }
        } else {
          if (isUserMigrated === "Yes") {
            Analytics.getInstance().logEvent(ANALYTICS_CONST.AUTH.MIGRATION_PASSWORD_ERROR);
          }
        }
        return res;
      })
      .catch((err) => {
        if (isUserMigrated === "Yes") {
          Analytics.getInstance().logEvent(ANALYTICS_CONST.AUTH.MIGRATION_PASSWORD_ERROR);
        }
        return err;
      });
  };

  return <ConfirmPassword onCancel={() => setModal("LOGIN")} onSubmit={handleSubmit} />;
}

export default ForgotPasswordConfirm;
