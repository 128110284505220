import { useSelector } from "react-redux";
import Heading from "components/global/Heading/Heading";
import LocationPin from "components/global/LocationPin/LocationPin";
import TextBlock from "components/global/TextBlock/TextBlock";
import PrimaryButton from "components/global/Buttons/PrimaryButton/PrimaryButton";
import { NotNearAddressStyled } from "./NotNearAddressStyled";
import { useNotNearAddress } from "./useNotNearAddress";

const NotNearAddress = () => {
  const { userAddressFromModal } = useSelector((state: any) => ({
    userAddressFromModal: state?.address?.userAddressFromModal,
  }));
  const { handleConfirm } = useNotNearAddress();

  return (
    <NotNearAddressStyled modalName="NOT_NEAR_ADDRESS" closeModalButtonType="BACK">
      <Heading size="md" className="heading">
        Hmm, looks like you’re not near this address.
      </Heading>
      <TextBlock size="sm" maxWidth={310}>
        Is this the address where you want your order delivered?
      </TextBlock>
      <div className="addressCard">
        <div className="locationPin">
          <LocationPin place="STORE" status="ACTIVE" />
        </div>
        <p className="addressLineOne">{userAddressFromModal?.addressLineOne}</p>
        <p className="addressLine">{userAddressFromModal?.addressLineTwo}</p>
        <p className="addressLine">
          {userAddressFromModal?.city}, {userAddressFromModal?.state}, {userAddressFromModal?.zip}
        </p>
      </div>
      <PrimaryButton variant="fourth" onClick={handleConfirm}>
        Confirm
      </PrimaryButton>
    </NotNearAddressStyled>
  );
};

export default NotNearAddress;
