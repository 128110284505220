import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import { getFilteredStoreList, setFilteredList, setFilteredRadius } from "@zaxbys/wrapper/frontendwrapper/redux/store/actions";
import { defaultRadiusRange, initialState } from "constants/constant";
import { setLocationOrderModal } from "redux/reducer/Location/LocationAction";
import { RootState } from "types/index";

export const useFilteredSearch = () => {
  const dispatch = useDispatch();

  const { filteredItems, filteredRadius, orderModal, userAddressFromAutoComplete, userLocation } = useSelector((state: RootState) => ({
    filteredItems: state.store?.filteredItems,
    filteredRadius: state.store?.filteredRadius,
    orderModal: state.location.orderModal,
    userAddressFromAutoComplete: state.address?.userAddressFromAutoComplete,
    userLocation: state.store?.userLocation,
  }));

  const [values, setValues] = useState(initialState);

  const [radius, setRadius] = useState(parseInt(defaultRadiusRange));
  const [selectedRadioBtn, setSelectedRadioBtn] = useState(defaultRadiusRange);

  const getSelectRadioButton = (getvalue: string): boolean => selectedRadioBtn === getvalue;

  const handleChange = (name: string, value: boolean) => {
    setValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleToggle = (radiusVal: string) => {
    setSelectedRadioBtn(() => radiusVal);
    setRadius(parseFloat(radiusVal));
  };

  const handleClose = () => {
    setValues(initialState);
    setSelectedRadioBtn(defaultRadiusRange);
    dispatch(setLocationOrderModal(""));
    dispatch(setFilteredList(initialState));
    dispatch(setFilteredRadius(+defaultRadiusRange));
  };

  useEffect(() => {
    if (filteredItems?.delivery !== undefined) {
      setValues((prev) => ({
        ...prev,
        delivery: filteredItems.delivery,
        curbsidePickUp: filteredItems.curbsidePickUp,
        driveThruPickUp: filteredItems.driveThruPickUp,
        openLate: filteredItems.openLate,
        freeWifi: filteredItems.freeWifi,
        cateringManagerOnsite: filteredItems.cateringManagerOnsite,
        cateringDelivery: filteredItems.cateringDelivery,
      }));
      setSelectedRadioBtn(filteredRadius.toString());
      setRadius(filteredRadius);
    }
  }, [setValues, setSelectedRadioBtn, setRadius, filteredItems, filteredRadius]);

  const handleApply = () => {
    dispatch(
      getFilteredStoreList({
        lat: Object.keys(userAddressFromAutoComplete).length > 0 ? userAddressFromAutoComplete.latitude : userLocation.lat,
        lng: Object.keys(userAddressFromAutoComplete).length > 0 ? userAddressFromAutoComplete.longitude : userLocation.lng,
        startdate: dayjs().format("YYYY-MM-DD"),
        radius: radius,
        delivery: values.delivery,
        curbside: values.curbsidePickUp,
        drivethru: values.driveThruPickUp,
        openlate: values.openLate,
        freewifi: values.freeWifi,
        cateringmanageronsite: values.cateringManagerOnsite,
        cateringdelivery: values.cateringDelivery,
      })
    );
    dispatch(setFilteredRadius(radius));
    dispatch(setFilteredList(values));
    dispatch(setLocationOrderModal(""));
  };

  return {
    values,
    orderModal,
    handleChange,
    handleToggle,
    getSelectRadioButton,
    handleClose,
    handleApply,
    radius,
  };
};
