import { apiCall } from "@zaxbys/wrapper/frontendwrapper/apis/api";
import { CMS_VIOLATOR_BAR } from "api/endpoints";
import { CommonLayerResponseDataWithType } from "src/types";
import { ViolatorBarCMSData } from "src/types/cms/violator-bar";

export const getViolatorCMSData = () => {
  return apiCall({
    requestType: "GET",
    apiEndPoint: `${CMS_VIOLATOR_BAR}`,
  }) as Promise<CommonLayerResponseDataWithType<ViolatorBarCMSData>>;
};
