import { useRouter } from "next/router";
import { ReactNode } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setDeliveryLocationCoordinates,
  setDeliveryLocationName,
  setPickupLocationName,
} from "@zaxbys/wrapper/frontendwrapper/redux/store/actions";
import { setClearCache } from "redux/reducer/Careers/CareersAction";
import CloseButton from "components/atomic-components/atoms/button/CloseButton/CloseButton";
import CustomGoogleMap from "components/global/CustomGoogleMap/CustomGoogleMap";
import ZoomButtons from "components/OrderNow/ZoomButtons/ZoomButtons";
import ConfirmAddress from "containers/Order/modals/ConfirmAddress/ConfirmAddress";
import LocationFilteredSearch from "components/atomic-components/molecules/LocationFilteredSearch/LocationFilteredSearch";
import NewAddress from "containers/Order/modals/NewAddress/NewAddress";
import NotNearAddress from "containers/Order/modals/NotNearAddress/NotNearAddress";
import Login from "containers/Login/Login";
import { checkHistoryLength } from "utils/helper";
import { RootState } from "types/index";
import ErrorModal from "components/atomic-components/molecules/ErrorModal/ErrorModal";
import { MobileLayout } from "./types";
import { useOrderPageContainer } from "./useOrderPageContainer";
import { OrderPageContainerStyled } from "./OrderPageContainerStyled";

interface Props {
  children: ReactNode;
  mobileLayout: MobileLayout;
  className?: string;
}

const OrderPageContainer = ({ children, mobileLayout, className }: Props) => {
  const dispatch = useDispatch();
  const {
    step,
    zoomLevel,
    googleMapRef,
    center,
    handleZoomIn,
    handleZoomOut,
    handleZoomChange,
    handleOnLoad,
    onDragEndHandle,
    orderModal,
    modal,
    errorMessageFlag,
    showLocationEnableModal,
  } = useOrderPageContainer();
  const router = useRouter();

  const { device } = useSelector((state: RootState) => ({
    device: state?.currentDevice.device,
  }));

  const handleCloseButton = () => {
    dispatch(setClearCache(false));
    dispatch(setPickupLocationName(""));
    dispatch(setDeliveryLocationName(""));
    dispatch(setDeliveryLocationCoordinates({}));
    if (checkHistoryLength()) {
      router.push("/");
    } else {
      router.back();
    }
  };

  return (
    <OrderPageContainerStyled className={className} mobileLayout={mobileLayout}>
      <CustomGoogleMap
        className="map"
        center={center}
        zoom={zoomLevel}
        onZoomChange={handleZoomChange}
        refObj={googleMapRef}
        onLoad={handleOnLoad}
        onDragEnd={onDragEndHandle}
      />
      <div className="content">
        {orderModal === "CONFIRM_ADDRESS" && <ConfirmAddress />}
        {modal === "LOGIN" && <Login />}
        {orderModal === "NEW_ADDRESS" && <NewAddress />}
        {orderModal === "NOT_NEAR_ADDRESS" && <NotNearAddress />}
        {orderModal === "FILTERED_SEARCH" && <LocationFilteredSearch />}
        {device === "DESKTOP" && <div className="childrenDesktop">{children}</div>}
        <ZoomButtons className="zoomButtons" onZoomIn={handleZoomIn} onZoomOut={handleZoomOut} />
        <div className="circle closeButton" onClick={handleCloseButton}>
          <svg width="10" height="10" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M7.00156 8.5914L2.19721 13.3957C1.98598 13.607 1.72076 13.7126 1.40156 13.7126C1.08236 13.7126 0.817146 13.607 0.605912 13.3957C0.394679 13.1845 0.289062 12.9193 0.289062 12.6001C0.289062 12.2809 0.394679 12.0157 0.605912 11.8044L5.41026 7.0001L0.605912 2.19575C0.394679 1.98451 0.289062 1.7193 0.289062 1.4001C0.289062 1.0809 0.394679 0.815681 0.605912 0.604448C0.817146 0.393215 1.08236 0.287598 1.40156 0.287598C1.72076 0.287598 1.98598 0.393215 2.19721 0.604448L7.00156 5.4088L11.8059 0.604448C12.0171 0.393215 12.2824 0.287598 12.6016 0.287598C12.9208 0.287598 13.186 0.393215 13.3972 0.604448C13.6084 0.815681 13.7141 1.0809 13.7141 1.4001C13.7141 1.7193 13.6084 1.98451 13.3972 2.19575L8.59286 7.0001L13.3972 11.8044C13.6084 12.0157 13.7141 12.2809 13.7141 12.6001C13.7141 12.9193 13.6084 13.1845 13.3972 13.3957C13.186 13.607 12.9208 13.7126 12.6016 13.7126C12.2824 13.7126 12.0171 13.607 11.8059 13.3957L7.00156 8.5914Z"
              fill="white"
            />
          </svg>
        </div>
      </div>

      {device === "MOBILE" && orderModal !== "NEW_ADDRESS" && orderModal !== "CONFIRM_ADDRESS" && (
        <div className={step === "ADDRESS_PICKUP" ? "nearByStoreAllDetails childrenMobile" : "childrenMobile"}>
          <CloseButton className="mobileCloseButton" onClick={handleCloseButton} variant="eleventh" showText={false} />
          {children}
        </div>
      )}

      {errorMessageFlag && showLocationEnableModal && (
        <ErrorModal isHeadingItalic heading="Oops!" subHeading="Please enable location sharing to use this feature." />
      )}
    </OrderPageContainerStyled>
  );
};

export default OrderPageContainer;
