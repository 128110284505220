import styled from "styled-components";

export const CheckmarkIconWrapper = styled.div`
  display: flex;
  width: 228px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${({ theme }) => theme.color.core.white};
  border-radius: 15px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 91000;
  padding: 48px 0 48px;
`;

export const ItemName = styled.p`
  color: ${({ theme }) => theme.color.primary.foundersBlue};
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  font-family: ${({ theme }) => theme.font.barlowSemiCondensed};
  text-transform: uppercase;
  padding: 20px 0 8px;
`;

export const AddedToOrderText = styled.p`
  color: ${({ theme }) => theme.color.primary.foundersBlue};
  font-size: 20px;
  text-transform: uppercase;
  line-height: 20px;
  font-weight: 700;
  font-family: ${({ theme }) => theme.font.barlow};
`;
