import { ReactNode } from "react";
import { isUrlExternal } from "utils/helper";
import { LinkContainerStyled, LinkStyled } from "./LinksStyled";

interface Props {
  ariaLabel?: string;
  children: ReactNode;
  to: string;
  passHref?: boolean;
  className?: string;
  containerClassName?: string;
  onClick?: Function;
  target?: string;
  variant?: "inlineBody1" | "inlineBody2" | string;
  fontWeight?: number;
}

const Link = ({
  ariaLabel,
  children,
  to,
  target,
  passHref = true,
  className = "",
  variant = "inlineBody1",
  onClick,
  fontWeight,
  containerClassName = "",
  ...rest
}: Props) => {
  const isLinkExternal = isUrlExternal(to);
  const anchorTarget = isLinkExternal ? "_blank" : "";
  return (
    <LinkContainerStyled variant={variant} fontWeight={fontWeight} className={containerClassName}>
      <LinkStyled href={to} passHref={passHref} {...rest}>
        <a className={className} target={target || anchorTarget} aria-label={ariaLabel} onClick={() => onClick && onClick()}>
          {children}
        </a>
      </LinkStyled>
    </LinkContainerStyled>
  );
};

export default Link;
