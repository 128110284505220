import { apiCall } from "@zaxbys/wrapper/frontendwrapper/apis/api";
import { CommonLayerResponseDataWithType } from "src/types";
import { CMS_WEB_CART_TEXT } from "api/endpoints";

export const getCMSDataForWebCart = () => {
  return apiCall({
    requestType: "GET",
    apiEndPoint: CMS_WEB_CART_TEXT,
  }) as Promise<CommonLayerResponseDataWithType<any>>;
};
