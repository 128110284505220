import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFavouriteStore, getRecentStoreList, setStoreDetailsPin } from "@zaxbys/wrapper/frontendwrapper/redux/store/actions";
import { setLocationOrderModal } from "redux/reducer/Location/LocationAction";
import { PICKUP_TABS } from "constants/constant";
import { getAddressList } from "redux/reducer/Address/AddressAction";
import LocationSearchContainer, { LocationSearchContainerProps } from "../LocationSearchContainer/LocationSearchContainer";
import { LocationTabContainerStyled, TabOption } from "./LocationTabContainerStyled";

interface Props extends LocationSearchContainerProps {
  variant: "pickup" | "delivery" | string;
  activeTAB?: string;
  tabOptions?: any;
  selectActive?: any;
}

const LocationTabContainer = ({
  variant,
  searchOption,
  handleClearIcon,
  handleGPSIconClick,
  handleChange,
  inputRef,
  handleKeyDownSearch,
  smartySuggestion,
  getStoresList,
  pickupInputLabel,
  errorMessageFlag,
  smartySuggestionClassName,
  activeTAB,
  tabOptions,
  selectActive,
}: Props) => {
  const dispatch = useDispatch();
  const { details, tokens, isAuthenticated } = useSelector((state: any) => ({
    details: state.user.details,
    tokens: state.user.tokens,
    isAuthenticated: state.user.isAuthenticated,
  }));
  const idToken = tokens?.idToken ?? "";
  const externalId = details?.externalId ?? "";
  const accessToken = tokens?.accessToken ?? "";

  useEffect(() => {
    if (variant === "pickup") {
      dispatch(setStoreDetailsPin(true));
    }
  }, [variant]);

  useEffect(() => {
    if (idToken && externalId) {
      variant === "delivery"
        ? dispatch(getAddressList({ idToken: idToken, externalId: externalId }))
        : dispatch(
            getFavouriteStore({
              externalId: externalId,
              idToken: idToken,
            })
          );
    }
  }, [idToken, externalId]);

  const onTabSeleted = (tabId: string) => {
    selectActive(tabId);
    if (!idToken && !externalId) {
      return null;
    }
    if (variant === "pickup") {
      tabId === "faveStores" &&
        dispatch(
          getFavouriteStore({
            externalId: externalId,
            idToken: idToken,
          })
        );
      tabId === "recent" && dispatch(getRecentStoreList(accessToken));
    } else if (variant === "delivery") {
      tabId === "faveAddresses" && dispatch(getAddressList({ idToken: idToken, externalId: externalId }));
    }
  };

  return (
    <LocationTabContainerStyled>
      {isAuthenticated && (
        <div className="tabsOptions">
          {tabOptions?.map((option: any) => {
            return (
              <TabOption onClick={() => onTabSeleted(option.id)} isSelected={activeTAB === option.id} key={option.id}>
                {option.name}
              </TabOption>
            );
          })}
        </div>
      )}
      {activeTAB === PICKUP_TABS[0].id && (
        <LocationSearchContainer
          {...{
            searchOption,
            handleChange,
            inputRef,
            handleKeyDownSearch,
            errorMessageFlag,
            smartySuggestionClassName,
            smartySuggestion,
            getStoresList,
            pickupInputLabel,
            handleClearIcon,
            handleGPSIconClick,
            handleLocationFilterClick: () => dispatch(setLocationOrderModal("FILTERED_SEARCH")),
          }}
        />
      )}
    </LocationTabContainerStyled>
  );
};

export default LocationTabContainer;
