import AccountPageTitle from "components/Account/AccountPageTitle/AccountPageTitle";
import GenericError from "components/atomic-components/atoms/GenericError/GenericError";
import { CommonLayerResponseData } from "src/types";
import Button from "components/atomic-components/atoms/button/Button";
import TextField from "components/atomic-components/atoms/form-elements/TextField/TextField";
import useWindowDimensions from "hooks/useWindowDimensions";
import Paragraph from "components/atomic-components/atoms/typography/Paragraphs/Paragraph";
import StackedButtonsContainer from "../StackedButtonsContainer/StackedButtonsContainer";
import useConfirmPassword from "./useConfirmPassword";
import { ConfirmPasswordStyled } from "./ConfirmPasswordStyled";

interface Props {
  onCancel: () => void;
  onSubmit: (password: string) => Promise<CommonLayerResponseData>;
}

const ConfirmPassword = ({ onCancel, onSubmit }: Props) => {
  const { values, errors, handleChange, handleSubmit, handleBlur, errorExists, serverError } = useConfirmPassword({
    onSubmit,
  });
  const sliderBreakpoint = 768;
  const { width } = useWindowDimensions();
  const isMobile = width < sliderBreakpoint;

  return (
    <ConfirmPasswordStyled>
      <AccountPageTitle className="changePasswordTitle">CHANGE PASSWORD</AccountPageTitle>
      {isMobile && <hr className="horizontalDivider" />}
      <div className="textGroup">
        <TextField
          type="password"
          id="password"
          name="password"
          className="textfield"
          label="New Password"
          value={values.password}
          error={errors.password}
          onChange={handleChange}
          onBlur={handleBlur}
          maxLength={50}
          required
        />
        <TextField
          type="password"
          id="confirmPassword"
          name="confirmPassword"
          className="textfield"
          label="Re-enter New Password"
          value={values.confirmPassword}
          error={errors.confirmPassword}
          onChange={handleChange}
          onBlur={handleBlur}
          maxLength={50}
          required
        />
      </div>

      <div className="requiredText">
        <Paragraph variant={"primaryParagraph2"}>
          Password should include: 8 characters | Upper & lowercase | Number | Special character.
        </Paragraph>
      </div>
      {serverError && (
        <GenericError fontSize={11} textAlign={"center"} variant="loginError" className="error">
          {serverError}
        </GenericError>
      )}
      <StackedButtonsContainer direction={isMobile ? "col" : "row"} className="stackedForgotButtons forgotButtonsMobileView">
        <Button className="buttonSize" variant="secondaryBlue" onClick={onCancel}>
          Cancel
        </Button>
        <Button className="buttonSize" variant="primaryBlue" onClick={handleSubmit} disabled={errorExists} data-testid="updateButton">
          Update
        </Button>
      </StackedButtonsContainer>
    </ConfirmPasswordStyled>
  );
};

export default ConfirmPassword;
