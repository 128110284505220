import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { GoogleMap } from "@react-google-maps/api";
import * as actions from "@zaxbys/wrapper/frontendwrapper/redux/store/actions";
import useModal from "hooks/useModal";
import { DEFAULT_LAT, DEFAULT_LNG, DEFAULT_ZOOM_LEVEL } from "constants/constant";
import { RootState } from "types/index";
import { setErrorMessageToModalFlag } from "redux/reducer/UserStatus/UserStatusAction";
import { setIsMapDragged } from "redux/reducer/Location/LocationAction";

export const useOrderPageContainer = () => {
  const dispatch = useDispatch();

  const googleMapRef = useRef<GoogleMap>(null);
  const [zoomLevel, setZoomLevel] = useState(DEFAULT_ZOOM_LEVEL);
  const [showLocationEnableModal, setShowLocationEnableModal] = useState(false);
  const [center, setCenter] = useState({
    lat: DEFAULT_LAT,
    lng: DEFAULT_LNG,
  });

  const { userLocation, storeLocation, step, isMapDragged, orderModal, errorMessageFlag, storeList } = useSelector((state: RootState) => ({
    userLocation: state.store?.userLocation,
    storeLocation: state.store?.storeLocation,
    orderModal: state.location.orderModal,
    step: state.location.step,
    isMapDragged: state.location.isMapDragged,
    errorMessageFlag: state.userStatus?.errorMessageFlag,
    storeList: state.store?.storeList,
  }));

  const [mapref, setMapRef] = useState<any>(null);
  useEffect(() => {
    if (!isMapDragged) {
      const { lat: storeLat, lng: storeLng } = storeLocation || {};
      const { lat: userLat, lng: userLng } = userLocation || {};
      const steps = ["ADDRESS_DELIVERY_CARD", "ADDRESS_PICKUP"];

      if (storeList?.length > 0 && storeLat && storeLng) {
        setCenter({ lat: storeLat, lng: storeLng });
      } else if (steps.includes(step) && storeLat && storeLng) {
        setCenter({ lat: storeLat, lng: storeLng });
      } else if (userLat && userLng) {
        setCenter({ lat: userLat, lng: userLng });
      } else {
        setCenter({ lat: DEFAULT_LAT, lng: DEFAULT_LNG });
      }
    }
  }, [userLocation, storeLocation, isMapDragged, storeList?.length, step]);

  const { setModal, modal } = useModal();

  const MAX_ZOOM_LEVEL = 20;
  const MIN_ZOOM_LEVEL = 1;

  const handleZoomIn = () => {
    setZoomLevel((prev) => Math.min(MAX_ZOOM_LEVEL, prev + 1));
  };

  const handleZoomOut = () => {
    setZoomLevel((prev) => Math.max(MIN_ZOOM_LEVEL, prev - 1));
  };

  const handleClosePage = () => {
    setModal("");
  };
  const map = googleMapRef.current?.state?.map;
  map?.setOptions({
    styles: [
      {
        featureType: "poi.business",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
    ],
  });

  const handleZoomChange = () => {
    // When the user zooms in using ctrl + scroll, then update the state variable

    if (map) {
      setZoomLevel(map.getZoom() || 5);
    }
  };

  const handleOnLoad = (mapDetails: any) => {
    setMapRef(mapDetails);
  };

  const onDragEndHandle = () => {
    if (mapref) {
      dispatch(setIsMapDragged(true));
    }
  };

  // Display user's location or default location in case if user doesn't allow accessing current location.
  const handleCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          dispatch(actions.setStoreLocation({ lat: position.coords.latitude, lng: position.coords.longitude }));
        },
        (error) => {
          if (error.code === error.PERMISSION_DENIED) {
            dispatch(setErrorMessageToModalFlag(true));
            setShowLocationEnableModal(true);
          }
        }
      );
    }
  };

  return {
    step,
    zoomLevel,
    googleMapRef,
    center,
    handleZoomIn,
    handleZoomOut,
    handleZoomChange,
    handleClosePage,
    handleOnLoad,
    onDragEndHandle,
    handleCurrentLocation,
    orderModal,
    modal,
    errorMessageFlag,
    showLocationEnableModal,
  };
};
