import styled from "styled-components";
import { responsive } from "theme/responsive";

export const FaveCreatedConfirmationModalStyled = styled.div`
  .modalContent {
    z-index: 999999;
  }
  .bodyContent {
    display: flex;
    flex-direction: column;
    text-align: center;
    .title {
      font-family: ${(props) => props.theme.font.barlowSemiCondensed};
      color: ${(props) => props.theme.color.primary.foundersBlue};
      font-weight: 700;
      font-size: 40px;
      line-height: 42px;
      margin-bottom: 24px;
    }
    .subTextBlock {
      font-family: ${(props) => props.theme.font.barlow};
      color: ${(props) => props.theme.color.core.black};
      font-weight: 400;
      font-size: 18px;
      line-height: 21.6px;
      width: 396px;
    }
    .closeBtn {
      position: absolute;
      margin: 0px 0px 0 auto;
      background: none;
    }
  }
  .createStackedButtons {
    > button {
      width: 210px;
    }
  }

  ${responsive.max.lg(`
  .bodyContent {
    display: flex;
    flex-direction: column;
    text-align: center;
    .title {
      font-weight: 800;
      font-size: 24px;
      line-height: 24px;
      margin-bottom: 28px;
    }
    .subTextBlock {
      font-weight: 500;
      font-size: 18px;
      line-height: 21.6px;
      width: 224px;
      margin-bottom: 36px;
    }
    .closeBtn {
      position: absolute;
      margin: 0px 0px 0 auto;
      background: none;
    }
  }
  .createStackedButtons {
    gap: 16px;
    > button {
      width: 200px;
    }
  }

  `)}
`;
