import { useMemo } from "react";
import { useRouter } from "next/router";
import Link from "components/atomic-components/atoms/typography/Links/Links";
import CMSImage from "../CMSImage/CMSImage";
import { useViolatorBar } from "./useViolatorBar";
import { ViolatorBarStyled } from "./ViolatorBarStyled";
import ViolatorBarTemplates from "./ViolatorBarTemplate/ViolatorBarTemplates";

interface Props {
  className?: string;
  noOffsetTop?: boolean;
}

const ViolatorBar = ({ className, noOffsetTop }: Props) => {
  const router = useRouter();
  const { violatorCMSData, handleCloseViolatorBar } = useViolatorBar();
  // on some environments, on load, home page route will be "/home" so this needs to be checked
  const isHomePage = useMemo(() => router.pathname === "/" || router.pathname === "/home", [router.pathname]);

  if (!violatorCMSData) {
    return null;
  }

  const isCurrentPageHidden = violatorCMSData?.HiddenPages?.find((value) => (value?.Link ? router.pathname.startsWith(value.Link) : false));

  if (isCurrentPageHidden) {
    return null;
  }

  const isViolatorBarInfoEmpty = Boolean(violatorCMSData?.Title) || Boolean(violatorCMSData?.Subtitle) || Boolean(violatorCMSData?.CTAText);

  const isNullChecker = (text: any) => {
    if (!text) {
      return "";
    }
    return text;
  };

  return (
    <ViolatorBarStyled
      className={`${className}`}
      noOffsetTop={noOffsetTop}
      isHomePage={isHomePage}
      pathname={router?.asPath}
      role="navigation"
    >
      {violatorCMSData.ForegroundImage?.url && (
        <CMSImage
          className="responsivePictures"
          layout="fill"
          alt={violatorCMSData?.ForegroundImage?.alternativeText ?? "violator bar foreground"}
          objectFit="cover"
          src={violatorCMSData.ForegroundImage.url}
        />
      )}
      {violatorCMSData.BackgroundImage?.url && (
        <CMSImage
          className="responsivePicturesBg"
          alt={violatorCMSData?.BackgroundImage?.alternativeText ?? "responsive picture"}
          src={violatorCMSData.BackgroundImage.url}
          layout="fill"
          objectFit="cover"
        />
      )}
      {isViolatorBarInfoEmpty && (
        <Link to={violatorCMSData.CTALink ?? "/"} className="link">
          <ViolatorBarTemplates
            templateName={violatorCMSData.Template ?? "OneDark"}
            handleClose={handleCloseViolatorBar}
            title={isNullChecker(violatorCMSData.Title)}
            subTitle={isNullChecker(violatorCMSData?.Subtitle)}
            ctaText={isNullChecker(violatorCMSData.CTAText)}
          />
        </Link>
      )}
    </ViolatorBarStyled>
  );
};

export default ViolatorBar;
