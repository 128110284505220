import { apiCall } from "@zaxbys/wrapper/frontendwrapper/apis/api";
import { CommonLayerResponseDataWithType } from "src/types";
import { CMS_FRANCHISE_LEADERSHIP } from "api/endpoints";
import { FranchiseLeadershipCMSData } from "types/cms/franchise-leadership";

export const getFranchiseLeadershipCMSData = () => {
  return apiCall({
    requestType: "GET",
    apiEndPoint: CMS_FRANCHISE_LEADERSHIP,
  }) as Promise<CommonLayerResponseDataWithType<FranchiseLeadershipCMSData>>;
};
