import styled from "styled-components";
import Link from "components/global/Link/Link";

import { responsive } from "theme/responsive";
import { StyledError } from "components/atomic-components/atoms/form-elements/Checkbox/CheckboxStyled";

export const SignUpModalStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  .modalClose {
    position: absolute;
    top: 20px;
  }
  .modal {
    overflow-y: auto;
  }
  .stackedButton {
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 16px;
  }
  .enterCode {
    .input-wrapper {
      ${StyledError} {
        width: 300px;
      }
    }
  }

  .pButton {
    height: 44px;
    width: 188px;
    @media only screen and (max-width: ${(props) => props.theme.size.md}) {
      height: 33px;
      width: 148px;
    }
  }

  .cardBody {
    background: ${(props) => props.theme.color.primary.coleslawWhite};
    padding: 30px 30px 36px 30px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  .cardFooter {
    background: ${(props) => props.theme.color.primary.foundersBlue};
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    width: 106%;
    transform: translate(-12px, 0px);
    margin-bottom: -1px;
  }
  .signUp {
    overflow: hidden;
    background: linear-gradient(
      to bottom,
      ${(props) => props.theme.color.primary.coleslawWhite} 64%,
      ${(props) => props.theme.color.primary.foundersBlue}
    );
    @media only screen and (min-width: ${(props) => props.theme.size.md}) {
      border-radius: 20px;
    }
  }
  .authCardClose {
    background: ${(props) => props.theme.color.primary.coleslawWhite};
    top: 28px;
    right: 28px;
    padding: 0;
    @media only screen and (min-width: ${(props) => props.theme.size.md}) {
      top: 28px;
    }
    @media only screen and (max-width: ${(props) => props.theme.size.lg}) {
      top: 20px;
    }
  }

  @media only screen and (max-width: ${(props) => props.theme.size.md}) {
    .cardBody {
      padding: 10px 24px 36px 24px;
      margin-bottom: 20px;
    }
    .modal {
      height: 100%;
      border-radius: 20px;
      width: 70%;
      margin-top: 23%;
    }

    .authCardClose {
      background: ${(props) => props.theme.color.primary.coleslawWhite};
      top: 20px;
      right: 28px;
      padding: 0;
    }

    .cardFooter {
      background: ${(props) => props.theme.color.primary.foundersBlue};

      width: 100%;
      transform: translate(0, -30px);
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      @media only screen and (min-width: ${(props) => props.theme.size.lg}) {
        padding: 10px 0;
      }
    }
  }
  @media only screen and (max-width: ${(props) => props.theme.size.md}) {
    display: grid;
    justify-content: center;
    grid-gap: 10px;
    width: 72%;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .welcomeBackTitle {
    font-size: 34px;
    margin-top: 30px;
    color: ${(props) => props.theme.color.primary.tongueTorchRed};
  }
  .welcomeAuthSubTitle {
    font-size: 34px;
  }

  @media only screen and (max-width: ${(props) => props.theme.size.lg}) {
    .welcomeBackTitle {
      margin: 20px 0 0 0;
      color: ${(props) => props.theme.color.primary.tongueTorchRed};
    }
    .welcomeAuthSubTitle {
      font-size: 20px;
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 26px;
  padding-bottom: 20px;
  > button {
    height: 44px;
  }
  .heading {
    font-family: ${(props) => props.theme.font.barlowCondensed} !important;
    font-weight: 800;
    font-size: 24px;
    margin-top: 25px;
    color: ${(props) => props.theme.color.limitedUse.pureWhite};
  }
`;

export const Body = styled.div`
  justify-items: center;
  .signupTitle {
    margin: 0 100px 0 0px;
    @media only screen and (max-width: ${(props) => props.theme.size.lg}) {
      margin-bottom: 20px;
    }
  }
  .genericError {
    margin-top: 10px;
  }

  .verficationText {
    font-size: 10px;
    font-weight: 700;
    font-family: ${(props) => props.theme.font.barlow};
    align-items: right;
  }

  @media only screen and (max-width: ${(props) => props.theme.size.lg}) {
    justify-items: center;
  }

  .errorRed {
    color: ${(props) => props.theme.color.primary.tongueTorchRed};
    margin-top: -10px;
  }

  .signupActions {
    gap: 0;
    margin-top: 10px;
    margin-bottom: 20px;
    > button {
      margin: 0 auto;
      height: 44px;
      width: 188px;
    }
    @media only screen and (max-width: ${(props) => props.theme.size.lg}) {
      justify-content: center;
      gap: 19px;
      > button {
        width: 155px;
      }
    }
  }

  .TermLinkAction {
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    text-decoration: underline;
    font-family: ${(props) => props.theme.font.barlow};
    pointer-events: auto;
    color: ${(props) => props.theme.color.primary.foundersBlue};
  }
  ${responsive.min.xs(`
  display: flex;
  flex-direction: column;
  width: 100%; 
  height: 100%;
`)}

  .codeText {
    padding: 5px;
  }
`;

export const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 16px;
  width: 100%;

  @media only screen and (max-width: ${(props) => props.theme.size.lg}) {
    .icon-position {
      cursor: pointer;
    }
  }
  ${responsive.min.xs(`
    grid-gap: 12px;
  `)}
`;
export const VerficationTextGroup = styled.div`
  display: flex;
  flex-direction: row;
`;
export const Wrapper = styled.div`
  display: grid;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
`;

export const VerficationText = styled.div`
  font-size: 10px;
  font-weight: 700;
  font-family: ${(props) => props.theme.font.sourceSansPro};
  color: ${(props) => props.theme.color.secondary.coolGray10};
  margin-top: -12px;
  margin-left: 132px;
  align-items: end;
  @media only screen and (min-width: ${(props) => props.theme.size.lg}) {
    margin-left: 180px;
    font-size: 11px;
  }
`;

export const Checkboxes = styled.div`
  display: grid;
  padding: 40px 0 16px;
  text-align: left;
  color: ${(props) => props.theme.color.primary.foundersBlue};
  font-family: ${(props) => props.theme.font.barlow};

  .input-checkbox:after {
    gap: 10px;
  }

  .checkbox-label {
    font-size: 16px;
    font-weight: 700;
    margin: 0 auto;
    font-family: ${(props) => props.theme.font.barlow};
    ${responsive.max.md(`
    font-size: 12px !important;
    font-weight: 500;
    `)}
  }
  ${responsive.max.xs(`
   margin-left: 8px;
  `)}
  ${responsive.max.xs(`
    grid-gap: 12px;
    margin: 0 20px;
    .input-checkbox {
      height: 18px;
      weight: 18px;
    }
  `)}
`;

export const SignInCheckboxes = styled.div`
  display: grid;
  padding: 16px 0;
  text-align: left;
  color: ${(props) => props.theme.color.primary.foundersBlue};
  font-family: ${(props) => props.theme.font.barlow};

  .input-checkbox:after {
    gap: 10px;
  }

  .checkbox-label {
    font-size: 14px;
    font-weight: 400;
    margin: 0 auto;
    font-family: ${(props) => props.theme.font.barlow};
    ${responsive.max.md(`
    font-size: 12px;
    font-weight: 500;
    `)}
  }
  ${responsive.max.xs(`
   margin-left: 8px;
  `)}
  ${responsive.max.xs(`
    grid-gap: 12px;
    margin: 0 20px;
    .input-checkbox {
      height: 18px;
      weight: 18px;
    }
  `)}
`;

export const SignupSubText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 24px;
  .text {
    font-size: 14px;
    font-weight: 400;
    font-family: ${(props) => props.theme.font.barlow};
    color: ${(props) => props.theme.color.primary.foundersBlue};
    width: 326px;
    @media only screen and (min-width: ${(props) => props.theme.size.lg}) {
      width: 392px;
    }
    @media only screen and (max-width: ${(props) => props.theme.size.md}) {
      font-size: 12px;
      margin-left: 10px;
    }
  }
  .TermLinkAction {
    font-weight: 700;
  }

  &:first-of-type {
    padding-bottom: 0;
  }
  &.signUpText {
    margin-top: 5px;
  }
`;

export const Messages = styled.div`
  margin-bottom: 10px !important;
  ${responsive.min.xs(`
    margin-top: 9px;
    margin-bottom: 6px;
  `)}
`;

export const TermLink = styled(Link)`
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: underline;
  font-family: ${(props) => props.theme.font.barlow};
  color: ${(props) => props.theme.color.primary.foundersBlue};
`;

export const SubHeading = styled.div`
  text-align: center;
  font-size: 16px;
  font-family: ${(props) => props.theme.font.barlow};
  font-weight: 500;
  font-style: normal;
  padding: 20px;
`;

export const SignUpErrorComponent = styled.div`
  margin-top: -10px;
  text-align: center;
  font-size: 11px;
  font-style: normal;
  .errorMessage {
    color: ${(props) => props.theme.color.primary.tongueTorchRed};
    font-family: ${(props) => props.theme.font.sourceSansPro};
    font-weight: 700;
    line-height: 11px;
    margin-top: 15px;
  }
  .orMessage {
    font-family: ${(props) => props.theme.font.sourceSansPro};
    color: ${(props) => props.theme.color.primary.foundersBlue};
    font-weight: 700;
    margin-bottom: 40px;
    text-align: center;
    line-height: normal;
    margin-left: 5px;
  }
`;
