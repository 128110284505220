import { apiCall } from "@zaxbys/wrapper/frontendwrapper/apis/api";
import { CommonLayerResponseDataWithType } from "src/types";
import { CMS_SELECT_STORE_MODAL } from "api/endpoints";
import { StoreDetailsCMSData } from "types/cms/store-details";

export const getSelectStoreModalDataFromCMS = () => {
  return apiCall({
    requestType: "GET",
    apiEndPoint: CMS_SELECT_STORE_MODAL,
  }) as Promise<CommonLayerResponseDataWithType<StoreDetailsCMSData>>;
};
