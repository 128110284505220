import { useRouter } from "next/router";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { getStoreList, setStoreLocationName, setUserLocation } from "@zaxbys/wrapper/frontendwrapper/redux/store/actions";
import { PAGE_NAME, PAGE_TYPE } from "@zaxbys/wrapper";
import { RootState } from "src/types";
import DeliveryCardSelectedAddress from "components/OrderNow/AddressSelectedCard/DeliveryCardSelectedAddress/DeliveryCardSelectedAddress";
import DeliveryCardOutsideRadius from "components/OrderNow/AddressSelectedCard/DeliveryCardOutsideRadius/DeliveryCardOutsideRadius";
import ErrorModal from "components/atomic-components/molecules/ErrorModal/ErrorModal";
import { setIsAlternativeFlow, setLocationOrderType, setLocationStep } from "redux/reducer/Location/LocationAction";
import { SET_CONFIRM_ADDRESS_SAVE_LOGIN_FLAG } from "redux/reducer/UserStatus/UserStatusConst";
import { setLocationsScreenFlag } from "redux/reducer/UserStatus/UserStatusAction";
import { checkHistoryLength } from "utils/helper";
import { setLocalStorageData } from "utils/storage";
import Analytics from "analytics/Analytics";
import { OrderStepsChoice, OrderType } from "./types";
import AddressOutOfRadius from "./AddressOutOfRadius/AddressOutOfRadius";
import AddressSelected from "./AddressSelected/AddressSelected";
import AddressSelectedPickup from "./AddressSelectedPickup/AddressSelectedPickup";
import LoggedInSearch from "./LoggedInSearch/LoggedInSearch";
import { ErrorModalWrapper } from "./OrderStyled";

const Order = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const {
    userStatus,
    hasLocationsVisited,
    fromStorePage,
    step,
    confirmAddressSaveFlag,
    isAlternativeFlow,
    errorMessageToModal,
    errorMessageFlag,
    isShowSelectModalFlag,
    device,
  } = useSelector((state: RootState) => ({
    userStatus: state.userStatus?.presentUserStatus,
    hasLocationsVisited: state.userStatus?.hasLocationsVisited,
    fromStorePage: state.store?.fromStorePage,
    step: state.location.step, // Fetch current location step
    confirmAddressSaveFlag: state.userStatus?.confirmAddressSaveFlag,
    isAlternativeFlow: state.location.isAlternativeFlow,
    errorMessageToModal: state.userStatus?.errorMessageToModal,
    errorMessageFlag: state.userStatus?.errorMessageFlag,
    isShowSelectModalFlag: state.userStatus?.isShowSelectModalFlag,
    device: state.currentDevice.device,
  }));

  useEffect(() => {
    Analytics.getInstance().logEvent("page_view", {
      page_name: PAGE_NAME.LOCATION,
      page_type: PAGE_TYPE.TYPE_LOCATION,
    });
  }, []);

  useEffect(() => {
    let orderType: OrderType = "";
    if (router.query.orderType?.toString().toUpperCase() === "DELIVERY") {
      orderType = "DELIVERY";
    } else if (router.query.orderType?.toString().toUpperCase() === "PICKUP") {
      orderType = "PICKUP";
    }

    if (orderType) {
      dispatch(setLocationStep("LOGGED_IN_SEARCH"));
      dispatch(setLocationOrderType(orderType));
    } else if (isAlternativeFlow) {
      // If user will come from the Career/Corporate opportunities page, it will directly set the next step with PICKUP order type
      dispatch(setLocationStep("LOGGED_IN_SEARCH"));
      dispatch(setLocationOrderType("PICKUP"));
    } else if (
      userStatus === "RETURNING" ||
      (userStatus === "GUEST" && hasLocationsVisited) ||
      (userStatus === "FIRST_TIME" && hasLocationsVisited)
    ) {
      if (confirmAddressSaveFlag) {
        // Need to check with Mishri
        dispatch({ type: SET_CONFIRM_ADDRESS_SAVE_LOGIN_FLAG, payload: false });
      } else {
        dispatch(setLocationStep("LOGGED_IN_SEARCH"));
      }
    } else if (!hasLocationsVisited && (userStatus === "GUEST" || userStatus === "FIRST_TIME")) {
      // If user visit the website first time, it will display COLD_WELCOME step
      if (isShowSelectModalFlag) dispatch(setLocationStep("LOGGED_IN_SEARCH"));
      dispatch(setLocationsScreenFlag(true));
    }

    return () => {
      if (isAlternativeFlow) dispatch(setStoreLocationName(""));
      dispatch(setIsAlternativeFlow(false));
    };
  }, [userStatus]);

  useEffect(() => {
    // User click on the SELECT STORE button from store detail page, it should display ADDRESS_PICKUP step.
    if (fromStorePage) {
      dispatch(setLocationStep("ADDRESS_PICKUP"));
    }
  }, [fromStorePage]);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        dispatch(
          getStoreList({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
            startdate: dayjs().format("YYYY-MM-DD"),
          })
        );
        dispatch(setUserLocation({ lat: position.coords.latitude, lng: position.coords.longitude }));
      });
    }
  }, []);

  if (checkHistoryLength()) {
    setLocalStorageData("previousURL", "");
  }

  // Update the location page step based on the user actions received
  const components: OrderStepsChoice = {
    LOGGED_IN_SEARCH: <LoggedInSearch />,
    ADDRESS_SELECTED: <AddressSelected />,
    ADDRESS_OUT_OF_RADIUS: <AddressOutOfRadius />,
    ADDRESS_PICKUP: <AddressSelectedPickup />,
    ADDRESS_DELIVERY_CARD: <DeliveryCardSelectedAddress className="deliveryCardWrapper" />,
    DELIVERY_OUTSIDE_RADIUS: <DeliveryCardOutsideRadius className="deliveryOutOfRadius" />,
  };

  const component = components[step];
  return (
    <>
      <div>{component}</div>
      {errorMessageFlag && errorMessageToModal && (
        <ErrorModalWrapper>
          <ErrorModal
            heading="Invalid Address"
            className="errorMessage"
            subHeading={typeof errorMessageToModal === "string" ? errorMessageToModal : errorMessageToModal.message}
            showCloseButton={device === "DESKTOP"}
            buttonCTA="OK"
          />
        </ErrorModalWrapper>
      )}
    </>
  );
};

export default Order;
