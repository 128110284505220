import { ReactNode, useEffect } from "react";
import { useSelector } from "react-redux";
import Image from "next/image";
import { RootState } from "types/index";
import { CLOSE_X, WHITE_CLOSE_ICON } from "assets/images";
import NavButton from "../../button/NavButton/NavButton";
import { ScrollableModalStyled } from "./ScrollableModalStyled";

export interface Props {
  onClose?: () => void;
  onBackButton?: () => void;
  isOpen: boolean;
  children?: ReactNode;
  className?: string;
  showCloseButton?: boolean;
  showBackButton?: boolean;
  mobileVariant?: "fullScreen" | "croppedVariant" | string;
  hideMobileBackground?: boolean;
  closeIconVariant?: "blackVariant" | "whiteVariant" | string;
}

const ScrollableModal = ({
  onClose,
  onBackButton,
  isOpen = false,
  children,
  mobileVariant = "croppedVariant",
  showCloseButton = false,
  showBackButton = false,
  hideMobileBackground = true,
  closeIconVariant = "blackVariant",
  ...rest
}: Props) => {
  const { device } = useSelector((state: RootState) => ({
    device: state.currentDevice.device,
  }));
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    // Cleanup function to remove the class when the component unmounts
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isOpen]);

  const onBack = () => {
    if (device === "DESKTOP" || onBackButton) {
      onBackButton?.();
    } else {
      onClose?.();
    }
  };

  return (
    <ScrollableModalStyled
      isOpen={isOpen}
      {...rest}
      mobileVariant={mobileVariant}
      hideMobileBackground={hideMobileBackground}
      closeIconVariant={closeIconVariant}
    >
      <div className="modal-style">
        <div className="head">
          {showBackButton && (
            <div className="show-backbutton">
              <NavButton variant="ghostBlue" onClick={onBack} data-testid="backButton">
                BACK
              </NavButton>
            </div>
          )}
          {showCloseButton && (
            <div className="image-wrapper close-button" onClick={() => onClose?.()} data-testid="closeButton">
              <Image src={closeIconVariant === "blackVariant" ? CLOSE_X : WHITE_CLOSE_ICON} layout="fill" alt="close" />
            </div>
          )}
        </div>
        {children}
      </div>
    </ScrollableModalStyled>
  );
};

export default ScrollableModal;
