import styled from "styled-components";
import { Size, TextBlockProps } from "./types";

const fontSize = (size: Size) => {
  return {
    lg: "18px",
    md: "16px",
    sm: "14px",
    xs: "12px",
  }[size];
};

const lineHeight = (size: Size) => {
  return {
    lg: "21px",
    md: "19px",
    sm: "17px",
    xs: "14px",
  }[size];
};

const TextBlock = styled.p<TextBlockProps>`
  width: min(95%, ${(props) => `${props.maxWidth}px`});
  font-family: ${(props) => props.theme.font.barlow};
  color: ${(props) => props.theme.color.core.black};
  text-align: center;

  font-size: ${(props) => fontSize(props.size)};
  line-height: ${(props) => lineHeight(props.size)};
`;

export default TextBlock;
