import { PropsWithChildren } from "react";
import { HeadingStyled } from "./HeadingStyled";

interface Props extends PropsWithChildren {
  size: "xs" | "sm" | "md" | "lg";
  className?: string;
  variant?: string;
}

const Heading = ({ size, children, className, variant }: Props) => {
  const fontSize = {
    lg: "54px",
    md: "34px",
    sm: "30px",
    xs: "22px",
  }[size];

  const lineHeight = {
    lg: "65px",
    md: "40px",
    sm: "36px",
    xs: "26px",
  }[size];

  return (
    <HeadingStyled className={className} fontSize={fontSize} lineHeight={lineHeight} variant={variant}>
      {children}
    </HeadingStyled>
  );
};

export default Heading;
