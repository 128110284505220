import amplitude from "amplitude-js";
import { Experiment, ExperimentClient } from "@amplitude/experiment-js-client";
import { RevenueProperties } from "types/index";
import DataDogAnalytics from "analytics/Datadog";
import { AnalyticsListener } from "./AnalyticsListener";

export default class AmplitudeEventListener implements AnalyticsListener {
  private experiment!: ExperimentClient;

  getExcludedEvents() {
    return ["click"];
  }

  init() {
    try {
      amplitude.getInstance().init(`${process.env.NEXT_PUBLIC_AMPLITUDE_KEY}`, undefined, { includeUtm: true, includeReferrer: true });
      this.experiment = Experiment.initializeWithAmplitudeAnalytics(`${process.env.NEXT_PUBLIC_AMPLITUDE_EXPERIMENT_DEPLOYMENT_KEY}`);
      this.experiment.fetch();
    } catch (error: any) {
      DataDogAnalytics.getInstance().datadogAddError(error, { errorFrom: "Amplitude Init" });
    }
  }

  public getExperiment() {
    return this.experiment;
  }

  logEvent(eventName: string, properties?: Record<string, unknown>, isMparticle: boolean = true, isAmplitude: boolean = true) {
    try {
      if (isAmplitude) {
        let propertyExtend = null;
        if (eventName === "page_view") {
          propertyExtend = {
            ...properties,
            source: "WEB",
          };
        }

        amplitude.getInstance().logEvent(eventName, propertyExtend ?? properties);
      }
    } catch (error: any) {
      DataDogAnalytics.getInstance().datadogAddError(error, { errorFrom: "Amplitude logEvent" });
    }
  }

  // Set user details
  public setIdentityDetails = (properties: Record<string, unknown>) => {
    try {
      amplitude.getInstance().setUserProperties(properties);
    } catch (error: any) {
      DataDogAnalytics.getInstance().datadogAddError(error, { errorFrom: "Amplitude setIdentityDetails" });
    }
  };

  // Set user details
  public setUserId = (userId: string) => {
    try {
      amplitude.getInstance().setUserId(userId);
    } catch (error: any) {
      DataDogAnalytics.getInstance().datadogAddError(error, { errorFrom: "Amplitude setUserId" });
    }
  };

  // Track Revenue
  logRevenue(userProperties: RevenueProperties) {
    try {
      new amplitude.Revenue()
        .setProductId(userProperties.productId)
        .setPrice(userProperties.price)
        .setQuantity(userProperties.quantity)
        .setRevenueType(userProperties.revenueType)
        .setEventProperties({ orderId: userProperties.orderId });
    } catch (error: any) {
      DataDogAnalytics.getInstance().datadogAddError(error, { errorFrom: "Amplitude logRevenue" });
    }
  }
}
