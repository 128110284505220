import { apiCall } from "@zaxbys/wrapper/frontendwrapper/apis/api";
import { USERS } from "../endpoints";

interface Request {
  externalId: string;
  idToken: string;
  latitude: number;
  longitude: number;
  addressType: string;
  addressLineOne: string;
  addressLineTwo: string;
  city: string;
  state: string;
  zip: string;
  favorite: boolean;
  notes: string;
  isBusiness: boolean;
}

interface Response {
  data: {
    message: string;
  };
}

export const saveAddress = async (data: Request) => {
  return apiCall({
    apiEndPoint: `${USERS}/addresses`,
    requestType: "POST",
    isAuth: true,
    token: data.idToken,
    apiPostData: {
      latitude: data.latitude,
      longitude: data.longitude,
      addressType: data.addressType,
      addressLineOne: data.addressLineOne,
      addressLineTwo: data.addressLineTwo,
      city: data.city,
      state: data.state,
      zip: data.zip,
      favorite: data.favorite,
      notes: data.notes,
      isBusiness: data?.isBusiness,
    },
  });
};

interface UpdateRequest {
  externalId: string;
  idToken: string;
  latitude: number;
  longitude: number;
  addressType: string;
  addressLineOne: string;
  addressLineTwo: string;
  city: string;
  state: string;
  zip: string;
  favorite: boolean;
  id: string;
  notes: string;
  isBusiness?: boolean;
}

export const updateAddress = async (data: UpdateRequest) => {
  return apiCall({
    apiEndPoint: `${USERS}/addresses/${data.id}`,
    requestType: "PUT",
    isAuth: true,
    token: data.idToken,
    apiPostData: {
      latitude: data.latitude,
      longitude: data.longitude,
      addressType: data.addressType,
      addressLineOne: data.addressLineOne,
      addressLineTwo: data.addressLineTwo,
      city: data.city,
      state: data.state,
      zip: data.zip,
      favorite: data.favorite,
      notes: data.notes,
      isBusiness: data?.isBusiness,
    },
  }) as Promise<Response>;
};
