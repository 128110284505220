import { apiCall } from "@zaxbys/wrapper/frontendwrapper/apis/api";
import { CommonLayerResponseDataWithType } from "src/types";
import { CMS_STORE_DETAILS } from "api/endpoints";
import { StoreDetailsCMSData } from "types/cms/store-details";

export const getStoreDetailsCMSData = () => {
  return apiCall({
    requestType: "GET",
    apiEndPoint: CMS_STORE_DETAILS,
  }) as Promise<CommonLayerResponseDataWithType<StoreDetailsCMSData>>;
};
