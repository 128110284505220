import { PASSWORD_REGEX } from "@zaxbys/wrapper/frontendwrapper/constants/regexPatterns";
import { useState, FormEvent } from "react";
import { getLocalFieldError } from "utils/validation";
import { CommonLayerResponseData } from "src/types";

interface Values {
  password: string;
  confirmPassword: string;
}

interface Errors {
  password: string;
  confirmPassword: string;
}

interface Props {
  onSubmit: (password: string) => Promise<CommonLayerResponseData>;
}

const useConfirmPassword = ({ onSubmit }: Props) => {
  const [values, setValues] = useState<Values>({
    password: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState<Errors>({
    password: "",
    confirmPassword: "",
  });

  const [serverError, setServerError] = useState<string>("");

  const handleChange = (name: string, value: string) => {
    if (serverError) {
      setServerError("");
    }
    setValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleBlur = (name: string, value: string) => {
    if (!value) {
      setErrors((prev) => ({ ...prev, [name]: "Required Field" }));
    } else if (!value.match(PASSWORD_REGEX)) {
      setErrors((prev) => ({ ...prev, [name]: "Password does not meet requirements" }));
    } else if (values.password && values.confirmPassword && values.password !== values.confirmPassword) {
      setErrors({
        password: "",
        confirmPassword: "Password and confirm password does not match",
      });
    } else {
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (values.password.length === 0) {
      setErrors((prev) => ({ ...prev, password: "Password cannot be empty" }));
      return;
    }

    const invalidError = getLocalFieldError("password", values.password);

    if (invalidError) {
      setErrors({
        password: invalidError,
        confirmPassword: "",
      });
      return;
    }

    if (values.confirmPassword.length === 0) {
      setErrors({
        password: "",
        confirmPassword: "Password cannot be empty",
      });
      return;
    }

    if (values.password !== values.confirmPassword) {
      setErrors({
        password: "",
        confirmPassword: "Passwords do not match",
      });
      return;
    }

    return onSubmit(values.password)
      .then((res) => {
        if (res.success === false && res.error) {
          setServerError(res.error.message);
        }
      })
      .catch((err) => setErrors({ password: "", confirmPassword: err.response?.data?.message }));
  };

  const errorExists = !!(!values.password || !values.confirmPassword || errors.password || errors.confirmPassword || serverError);

  return {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleBlur,
    errorExists,
    serverError,
  };
};

export default useConfirmPassword;
