import { apiCall } from "@zaxbys/wrapper/frontendwrapper/apis/api";
import { CommonLayerResponseDataWithType } from "src/types";
import { CMS_HOME } from "api/endpoints";
import { HomeCMSData } from "src/types/cms/home";

export const getHomeCMSData = () => {
  return apiCall({
    requestType: "GET",
    apiEndPoint: CMS_HOME,
  }) as Promise<CommonLayerResponseDataWithType<HomeCMSData>>;
};
