import React from "react";
import { useToggle } from "@zaxbys/wrapper/frontendwrapper/hooks/useToggle";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import customParseFormat from "dayjs/plugin/customParseFormat";
import OrderAccordion from "components/global/OrderDropdown/OrderAccordion";
import SubHeadings from "components/atomic-components/atoms/typography/SubHeadings/SubHeadings";
import Paragraph from "components/atomic-components/atoms/typography/Paragraphs/Paragraph";
import ViewOrdersAccordionDropdown from "./ViewOrderAccordionDropdown";

interface Props {
  orderDetails: any;
  key: number;
}

const OrderedItemDetailsAccordion = ({ orderDetails, key }: Props) => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.extend(customParseFormat);
  const [isExpanded, toggleIsExpanded] = useToggle(false);

  const formatCardName = (products: any) => {
    let result = "";
    products.map((value: any, index: number) => {
      if (index <= 2) {
        result += `${value.name}${products.length - 1 === index ? "" : ","} `;
      }
      return result;
    });

    return result.slice(0, 40);
  };

  const accordionBody = (products: any) => (
    <>
      <Paragraph variant="primaryParagraph2" mobileFontSize={16} mobileFontWeight={800} className="reorderTitle ">
        {formatCardName(products)}
        {products.length >= 2 ? <span>{`...+${products.length - 1} Item${products.length - 1 === 1 ? "" : "s"}`}</span> : ""}
      </Paragraph>
    </>
  );

  const footer = (products: any, total: any, orderId: string) => {
    return (
      <ViewOrdersAccordionDropdown
        orderId={orderId}
        total={total}
        items={products}
        className="faveAccordionDropdown"
        isCostAvailable={true}
      />
    );
  };
  const formattedDate = dayjs(orderDetails?.readyTime).locale("en").format("MMM DD, YYYY - HH:mm A");
  return (
    <div className="modalAccordion" key={key}>
      <div className="summaryTop">
        <SubHeadings variant="rewardTilePoint" fontSize={16} fontWeight={600} className="item-name">
          {formattedDate}
        </SubHeadings>
      </div>
      <OrderAccordion
        accordionBody={accordionBody(orderDetails.products)}
        accordionDropdown={footer(orderDetails.products, orderDetails.total, orderDetails.orderRef)}
        isExpanded={isExpanded}
        onDropdownToggle={toggleIsExpanded}
      />
    </div>
  );
};

export default OrderedItemDetailsAccordion;
