import { MigratedUser } from "src/types";
import { CMSUserProfileData } from "types/cms/user-profile";
import { ErrorMessage } from "./types";
import {
  SET_USER_STATUS_CONST,
  SET_USER_GEOLOCATION_STATUS_CONST,
  SET_USER_ORDER_TYPE,
  SET_LOCATIONS_SCREEN_FLAG,
  SET_REMEMBER_STATUS,
  SET_USER_PAGE_FLAG,
  SET_CONTACTLESS_DRIVER_NOTE_FLAG,
  SET_ERROR_MESSAGE_TO_MODAL,
  SET_ERROR_MESSAGE_TO_MODAL_FLAG,
  SET_USER_FROM_CHECKOUT,
  SET_BLACKOUT_ERROR_MESSAGE,
  SET_MIGRATED_USER_INFO,
  SET_STORE_CLOSING_FLAG,
  SET_SELECT_VALID_DATE,
  SET_IS_OLD_PHONE_NUM_VALIDATED,
  SET_USER_PROFILE_PHONE_UPDATE_DATA,
  SET_IS_USER_EXCEEDED_PASSWORD_RESET_LIMIT,
  SET_WARNING_MESSAGE_TO_MODAL,
  SET_IS_SHOW_WARNING_MODAL_FLAG,
  SET_SELECT_STORE_MODAL_FLAG,
  SET_USER_SELECTED_TIP_PERCENTAGE,
  SET_SHOW_ENTER_CODE_MODAL,
  SET_DOUBLE_CHECK_DATE_TIME_BUTTON_CLICKED,
} from "./UserStatusConst";

export const setMigratedUserInfo = (payload: MigratedUser) => {
  return { type: SET_MIGRATED_USER_INFO, payload: payload };
};

export const setPresentUserStatus = (payload: any) => {
  return { type: SET_USER_STATUS_CONST, payload: payload || null };
};

export const setPresentUserGeolocationStatus = (payload: any) => {
  return { type: SET_USER_GEOLOCATION_STATUS_CONST, payload: payload || null };
};

export const setPresentUserOrderType = (payload: any) => {
  return { type: SET_USER_ORDER_TYPE, payload: payload || null };
};

export const setLocationsScreenFlag = (payload: any) => {
  return { type: SET_LOCATIONS_SCREEN_FLAG, payload: payload || null };
};

export const setRememberStatus = (payload: boolean) => {
  return { type: SET_REMEMBER_STATUS, payload: payload };
};

export const setPresentPageLocation = (payload: boolean) => {
  return { type: SET_USER_PAGE_FLAG, payload: payload || null };
};

export const setSelectedTipPercentage = (payload: number) => {
  return { type: SET_USER_SELECTED_TIP_PERCENTAGE, payload: payload || null };
};

export const setContactlessDriverNote = (payload: boolean) => {
  return { type: SET_CONTACTLESS_DRIVER_NOTE_FLAG, payload: payload || false };
};

export const setErrorMessageToModal = (payload: ErrorMessage | string | any) => {
  return { type: SET_ERROR_MESSAGE_TO_MODAL, payload: payload };
};

export const setErrorMessageToModalFlag = (payload: boolean) => {
  return { type: SET_ERROR_MESSAGE_TO_MODAL_FLAG, payload: payload };
};

export const setUserFromCheckout = (payload: boolean) => {
  return { type: SET_USER_FROM_CHECKOUT, payload: payload };
};
export const setBlackoutErrorMessage = (payload: string) => {
  return { type: SET_BLACKOUT_ERROR_MESSAGE, payload: payload };
};
export const setStoreClosingFlag = (isError: boolean, errorMsg?: string) => {
  return { type: SET_STORE_CLOSING_FLAG, payload: { isError, errorMsg: errorMsg || "" } };
};
export const setSelectValidDate = (payload: boolean) => {
  return { type: SET_SELECT_VALID_DATE, payload: payload };
};

export const setIsOldPhoneNumValidated = (payload: boolean) => {
  return { type: SET_IS_OLD_PHONE_NUM_VALIDATED, payload: payload };
};

export const setUserProfilePhoneUpdateData = (payload: CMSUserProfileData) => {
  return { type: SET_USER_PROFILE_PHONE_UPDATE_DATA, payload: payload };
};

export const setIsPasswordResetLimitExceeded = (payload: boolean) => {
  return { type: SET_IS_USER_EXCEEDED_PASSWORD_RESET_LIMIT, payload: payload };
};

export const setWarningMessageToModal = (payload: string) => {
  return { type: SET_WARNING_MESSAGE_TO_MODAL, payload: payload };
};

export const setShowWarningModal = (payload: boolean) => {
  return { type: SET_IS_SHOW_WARNING_MODAL_FLAG, payload: payload };
};

export const setSelectStoreModalFlag = (payload: boolean) => {
  return { type: SET_SELECT_STORE_MODAL_FLAG, payload };
};

export const setShowEnterCodeModal = (payload: boolean) => {
  return { type: SET_SHOW_ENTER_CODE_MODAL, payload };
};

export const setDoubleCheckDateTimeButtonClicked = (payload: boolean) => {
  return { type: SET_DOUBLE_CHECK_DATE_TIME_BUTTON_CLICKED, payload };
};
