import validator from "validator";
import dayjs from "dayjs";
import { NAME_REGEX } from "@zaxbys/wrapper/frontendwrapper/constants/regexPatterns";
import { PHONE_REGEX, PASSWORD_REGEX, US_STATE_REGEX, ZIP_CODE_REGEX, MASK_FORMAT_PHONE_REGEX } from "constants/regexPatterns";
const getNameError = (value: string, label: string) => {
  if (!value.match(NAME_REGEX)) {
    return `${label} cannot contain numeric or special characters`;
  }
  return "";
};
const getFirstNameError = (value: string) => {
  if (!value) {
    return "Required Field";
  }
  return getNameError(value, "First Name");
};
const getLastNameError = (value: string) => {
  if (!value) {
    return "Required Field";
  }
  return getNameError(value, "Last Name");
};

const getEmailError = (value: string, passwordLimitExceeded?: boolean) => {
  if (!value) return "Email is required";

  if (passwordLimitExceeded && !validator.isEmail(value)) return "PLEASE ENTER A VALID EMAIL ADDRESS";

  if (!validator.isEmail(value)) return "PLEASE ENTER A VALID EMAIL ADDRESS";

  return "";
};

const getPasswordError = (value: string) => {
  if (!value.match(PASSWORD_REGEX)) {
    return "Password does not meet requirements";
  }
  return "";
};
const getPhoneError = (value: string) => {
  if (value.match(MASK_FORMAT_PHONE_REGEX)) {
    return "";
  } else if (!value.match(PHONE_REGEX)) {
    return "Please Enter a valid phone number";
  }
  return "";
};
const getStateError = (value: string) => {
  return value.match(US_STATE_REGEX) ? "" : "Please enter a valid state";
};
const getZipError = (value: string) => {
  return value.match(ZIP_CODE_REGEX) ? "" : "Please enter a valid zip code";
};
const getRequiredFieldError = (value: boolean) => {
  if (!value) {
    return "Required Field";
  }
  return "";
};
export const getBirthDateError = (birthDate: string) => {
  if (birthDate === "Invalid Date") {
    return "*Entered date of birth is an invalid date";
  }
  const [year] = birthDate.split("-");
  const intYear = parseInt(year);
  if (intYear < 1900) {
    return "*Entered date of birth is an invalid date";
  }
  if (dayjs(birthDate, "YYYY-MM-DD").format("YYYY-MM-DD") !== birthDate) {
    return "*Entered date of birth is an invalid date";
  }
  const age = dayjs().diff(birthDate, "years");
  if (age < 13) {
    return "*Must be 13 years or older to participate";
  }
  return "";
};
export const getbirthMonthDay = (birthMonthDay: string) => {
  if (birthMonthDay !== "Invalid Date") {
    birthMonthDay = "2000-" + birthMonthDay;
  }
  if (birthMonthDay === "Invalid Date") {
    return "*Entered date of birth is an invalid date";
  }
  if (dayjs(birthMonthDay, "YYYY-MM-DD").format("YYYY-MM-DD") !== birthMonthDay) {
    return "*Entered date of birth is an invalid date";
  }
  return "";
};
export const getLocalFieldError = (name: string, value: string, passwordLimitExceeded?: boolean): string => {
  if (name !== "birthDate" && name !== "birthMonthDay" && !value) {
    return "Required Field";
  }
  if ((name === "birthDate" || name === "birthMonthDay") && !value) {
    return "";
  }
  switch (name) {
    case "firstName":
      return getFirstNameError(value);
    case "lastName":
      return getLastNameError(value);
    case "email":
      return getEmailError(value, passwordLimitExceeded);
    case "password":
      return getPasswordError(value);
    case "phone":
      return getPhoneError(value);
    case "birthDate":
      return getBirthDateError(value);
    case "state":
      return getStateError(value);
    case "zip":
      return getZipError(value);
    case "birthMonthDay":
      return getbirthMonthDay(value);
    default:
      return "";
  }
};
export const getLocalCheckboxError = (name: string, value: boolean) => {
  switch (name) {
    case "ageConsent":
      return getRequiredFieldError(value);
    case "termsAndConditions":
      return getRequiredFieldError(value);
    default:
      return "";
  }
};
