import styled from "styled-components";

export const ValueUpdatedStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .heading {
    font-family: ${(props) => props.theme.font.barlowSemiCondensed};
    color: ${(props) => props.theme.color.primary.foundersBlue};
    font-weight: 800;
    font-size: 40px;
    margin-bottom: 25px;
    max-width: 400px;
    text-transform: uppercase;
  }

  .text {
    margin-bottom: 25px;
    width: auto;
    font-family: ${(props) => props.theme.font.barlow} !important;
    color: ${(props) => props.theme.color.primary.foundersBlue};
    font-weight: 400;
    font-size: 14px !important;
    @media only screen and (max-width: ${(props) => props.theme.size.md}) {
      font-family: ${(props) => props.theme.font.barlow};
      font-size: 16px !important;
      font-weight: 500;
      margin-top: 10px;
    }
  }
  .horizontalDivider {
    border-bottom: 1px solid ${(props) => props.theme.color.secondary.coolGray4};
    width: 294px;
    margin-top: 30px;
    margin-bottom: 20px;
  }
  .closeButton {
    background-color: transparent;
    width: 190px;
    height: 44px !important;
    @media only screen and (max-width: ${(props) => props.theme.size.md}) {
      width: 200px;
      height: 36px !important;
    }
  }

  .loginInButton {
    width: 190px;
    height: 44px !important;
    @media only screen and (max-width: ${(props) => props.theme.size.md}) {
      width: 200px;
      height: 36px !important;
    }
  }
`;
