import styled from "styled-components";

interface Props {
  size: "xs" | "sm" | "md" | "lg" | "xl";
  color?: "blue" | "white" | "red";
  maxWidth?: number;
  isRegular?: boolean;
}

const fontSizes = {
  xs: "12px",
  sm: "22px",
  md: "26px",
  lg: "34px",
  xl: "54px",
};

const lineHeights = {
  xs: "14px",
  sm: "26px",
  md: "28px",
  lg: "41px",
  xl: "65px",
};

// This must be a function to stop nested template literal code smells
const getMaxWidth = (maxWidth: number) => `max-width: ${maxWidth}px`;

const Prompt = styled.h2<Props>`
  font-size: ${(props) => fontSizes[props.size]};
  line-height: ${(props) => lineHeights[props.size]};
  color: ${(props) => props.theme.color.core[props.color ? props.color : "white"]};
  font-family: ${(props) => (props.isRegular ? props.theme.font.scriptRegular : props.theme.font.scriptRough)};
  text-align: center;
  ${(props) => props.maxWidth && getMaxWidth(props.maxWidth)}
`;

export default Prompt;
