import { Group, Option, ProductItemOfStore } from "types/index";
import {
  SET_STORE_MENU,
  SET_STORE_MENU_WITH_MODIFIERS,
  GET_CATEGORY_MENU_LIST,
  SET_SELECTED_TAB_NAME,
  GET_AN_ITEM_FROM_STORE,
  GET_DEFAULT_ITEM_SELECTED,
  GET_ITEM_SECTIONS,
  SET_SELECTED_OPTION,
  SET_OPTION_LIST,
  GET_GENERIC_MENU,
  SET_UPDATED_QUANTITY,
  SET_PRICE,
  GET_MENU_BASED_ON_CATEGORY,
  SET_FILES_PATH,
  SET_SELECTED_CATEGORY_NAME,
  IS_REDIRECTED_FROM_HOME,
  SET_PRIMARY_MODIFIERS,
  SELECTED_PRODUCT_OPTIONS_LIST,
  RESET_CUSTOMIZATION_DATA,
  SET_SELECTED_PRODUCT_TO_MODAL,
  ITEM_COST,
  ITEM_BASE_CALORIE,
  ITEM_MAX_CALORIE,
  UNSELECTED_GROUPS,
  UNSELECTED_GROUPS_DETAILS,
  IS_SHOW_PULSE_EFFECT,
  MENU_IS_LOADING,
  IS_PRODUCT_AVAILABLE,
  SET_BRAND_PRODUCT_ID,
  SET_MENU_ERROR_MESSAGE_TO_MODAL_FLAG,
  IS_MENU_ERROR,
  SET_SELECTED_MENU_CATEGORY,
  GET_GENERIC_MENU_WITH_MODIFIERS,
  SET_PRODUCT_NAME_TO_NAVIGATE,
  SET_SELECTED_LOCATION_PRODUCT,
  SET_PREVIOUS_ROUTE_URL,
  SET_SELECTED_CATEGORY_PRODUCTS,
  SET_FROM_HOME_REORDER_SECTION,
  INCREMENT_ITEM_QUANTITY,
  DECREMENT_ITEM_QUANTITY,
} from "./MenuConst";

const initialState = {
  isLoading: false,
  isRedirectedFromHome: false,
  storeMenu: [],
  storeMenuWithModifires: [],
  selectedTabName: "",
  getCategoryMenuList: [],
  getItemFromStore: {},
  defaultItemSelected: [],
  getItemSections: [],
  selectedOption: {},
  optionList: [],
  getGenericMenu: [],
  genericMenuWithModifiers: [],
  productQuantity: 1,
  totalPrice: 0,
  menuBasedOnCategory: [],
  fullMenuFilePath: "",
  cateringMenuFilePath: "",
  selectedCategoryName: "",
  primaryModifiers: [],
  productOptionsGroup: [],
  selectedProductOptions: [] as any,
  selectedProductOptionsList: [],
  selectedProduct: [],
  itemCost: 0,
  itemBaseCalorie: 0,
  itemMaxCalorie: 0,
  unSelectedGroups: [],
  unSelectedGroupsDetails: [],
  isShowPulse: false,
  isProductAvailable: true,
  brandProductId: 0,
  isError: false,
  errorMessageToModal: null,
  errorMessageFlag: false,
  selectedMenuCategory: "",
  productNameToNavigate: "",
  selectedLocationProducts: {
    productId: 0,
    categoryName: "",
    productName: "",
  },
  previousRouteURL: "",
  selectedCategoryProducts: null,
  fromHomeReorderSection: false,
};

export default function MenuReducer(state = initialState, action: { type: string; payload: any }) {
  switch (action.type) {
    case IS_MENU_ERROR:
      return { ...state, isError: action.payload || false };

    case MENU_IS_LOADING:
      return { ...state, isLoading: action.payload || false };

    case SET_STORE_MENU:
      return {
        ...state,
        storeMenu: action.payload,
      };
    case SET_STORE_MENU_WITH_MODIFIERS:
      return {
        ...state,
        storeMenuWithModifires: action.payload,
      };
    case GET_CATEGORY_MENU_LIST:
      return {
        ...state,
        getCategoryMenuList: action.payload,
      };
    case SET_SELECTED_TAB_NAME:
      return {
        ...state,
        selectedTabName: action.payload,
      };
    case SET_SELECTED_CATEGORY_NAME:
      return {
        ...state,
        selectedCategoryName: action.payload,
      };
    case GET_AN_ITEM_FROM_STORE:
      return {
        ...state,
        getItemFromStore: action.payload,
        productOptionsGroup: setArrayMainGroups(action.payload, state.primaryModifiers),
        selectedProductOptions: action.payload?.groups?.length ? new Array(action.payload?.groups?.length).fill([]) : [],
      };
    case SET_MENU_ERROR_MESSAGE_TO_MODAL_FLAG:
      return {
        ...state,
        errorMessageFlag: true,
        errorMessageToModal: action.payload,
      };
    case GET_DEFAULT_ITEM_SELECTED:
      return {
        ...state,
        defaultItemSelected: action.payload,
      };
    case GET_ITEM_SECTIONS:
      return {
        ...state,
        getItemSections: action.payload,
      };
    case SET_SELECTED_OPTION:
      return {
        ...state,
        selectedOption: action.payload,
      };
    case INCREMENT_ITEM_QUANTITY: {
      const itemId = state.selectedProductOptionsList.findIndex((item: Option) => item.id === action.payload.index);
      const selectedItem: Option = state.selectedProductOptionsList[itemId];
      const newSelectedProductOptionsList: Array<any> = state.selectedProductOptionsList;
      const newItemQuantity = selectedItem?.quantity ? selectedItem.quantity + action.payload.count : 1;
      newSelectedProductOptionsList[itemId] = { ...selectedItem, quantity: newItemQuantity };
      return {
        ...state,
        selectedProductOptionsList: [...newSelectedProductOptionsList],
      };
    }
    case DECREMENT_ITEM_QUANTITY: {
      const itemId = state.selectedProductOptionsList.findIndex((item: Option) => item.id === action.payload.index);
      const selectedItem: Option = state.selectedProductOptionsList[itemId];
      const newSelectedProductOptionsList: Array<any> = state.selectedProductOptionsList;
      const newItemQuantity = selectedItem?.quantity ? selectedItem.quantity - action.payload.count : 0;
      newSelectedProductOptionsList[itemId] = { ...selectedItem, quantity: newItemQuantity };
      return {
        ...state,
        selectedProductOptionsList: [...newSelectedProductOptionsList],
      };
    }
    case SET_OPTION_LIST:
      return {
        ...state,
        optionList: action.payload,
      };
    case GET_GENERIC_MENU:
      return {
        ...state,
        getGenericMenu: action.payload,
      };
    case GET_GENERIC_MENU_WITH_MODIFIERS:
      return {
        ...state,
        genericMenuWithModifiers: action.payload,
      };
    case SET_UPDATED_QUANTITY:
      return {
        ...state,
        productQuantity: action.payload,
      };
    case SET_PRICE:
      return {
        ...state,
        totalPrice: action.payload,
      };
    case GET_MENU_BASED_ON_CATEGORY:
      return {
        ...state,
        menuBasedOnCategory: action.payload,
      };
    case SET_FILES_PATH:
      return {
        ...state,
        fullMenuFilePath: action.payload.FullMenu?.url,
        cateringMenuFilePath: action.payload.CateringMenu?.url,
      };
    case IS_REDIRECTED_FROM_HOME:
      return {
        ...state,
        isRedirectedFromHome: action.payload,
      };
    case SET_PRIMARY_MODIFIERS:
      return {
        ...state,
        primaryModifiers: action.payload,
      };
    case SELECTED_PRODUCT_OPTIONS_LIST:
      if (action?.payload?.length >= 1) {
        state.selectedProductOptions[action.payload[0]] = action.payload[1];
      }
      return {
        ...state,
        selectedProductOptionsList: state.selectedProductOptions.flat(),
      };
    case RESET_CUSTOMIZATION_DATA:
      return {
        ...state,
        productOptionsGroup: [],
        selectedProductOptions: [],
        selectedProductOptionsList: [],
        getItemFromStore: {},
      };
    case SET_SELECTED_PRODUCT_TO_MODAL:
      return {
        ...state,
        selectedProduct: action.payload,
      };
    case ITEM_COST:
      return {
        ...state,
        itemCost: action.payload,
      };
    case ITEM_BASE_CALORIE:
      return {
        ...state,
        itemBaseCalorie: action.payload,
      };
    case ITEM_MAX_CALORIE:
      return {
        ...state,
        itemMaxCalorie: action.payload,
      };
    case UNSELECTED_GROUPS:
      return {
        ...state,
        unSelectedGroups: action.payload,
      };
    case UNSELECTED_GROUPS_DETAILS:
      return {
        ...state,
        unSelectedGroupsDetails: action.payload,
      };
    case IS_SHOW_PULSE_EFFECT:
      return {
        ...state,
        isShowPulse: action.payload,
      };

    case IS_PRODUCT_AVAILABLE:
      return {
        ...state,
        isProductAvailable: action.payload,
      };

    case SET_BRAND_PRODUCT_ID:
      return {
        ...state,
        brandProductId: action.payload,
      };
    case SET_SELECTED_MENU_CATEGORY:
      return {
        ...state,
        selectedMenuCategory: action.payload,
      };
    case SET_PRODUCT_NAME_TO_NAVIGATE:
      return {
        ...state,
        productNameToNavigate: action.payload,
      };
    case SET_SELECTED_LOCATION_PRODUCT:
      return {
        ...state,
        selectedLocationProducts: action.payload,
      };
    case SET_PREVIOUS_ROUTE_URL:
      return {
        ...state,
        previousRouteURL: action.payload,
      };
    case SET_SELECTED_CATEGORY_PRODUCTS:
      return {
        ...state,
        selectedCategoryProducts: action.payload,
      };
    case SET_FROM_HOME_REORDER_SECTION:
      return {
        ...state,
        fromHomeReorderSection: action.payload,
      };
    default:
      return state;
  }
}

let productOptions: ProductItemOfStore;

const setArrayMainGroups = (productOptionsList: ProductItemOfStore, menuOptionArray: Array<Group>) => {
  productOptions = productOptionsList;
  const idArray = menuOptionArray.map((item: any) => {
    return item.id;
  });

  // added idArray and productOptions length conditions for getItemFromStore
  if (idArray?.length && productOptions?.groups?.length) {
    editResponse(productOptions?.groups, idArray);
  }

  const arrayProductOptions: any[] = [];
  if (productOptions?.groups?.length) {
    productOptions?.groups?.forEach((value: any) => {
      arrayProductOptions.push([value]);
    });
  }
  return arrayProductOptions;
};

const editResponse = (groups: any, idArray: any) => {
  for (const group of groups) {
    const foundIndex = group.options.findIndex((item: Option) => idArray.includes(item.id));
    if (foundIndex > -1) {
      group.options.forEach((element: Option, index: number) => {
        if (index === foundIndex) {
          element.default = true;
        } else {
          element.default = false;
        }
      });
      editResponse(group.options[foundIndex]?.groups, idArray);
    } else {
      const foundIndexDefault = group.options.findIndex((item: any) => item.default);
      if (foundIndexDefault > -1) {
        editResponse(group.options[foundIndexDefault]?.groups, idArray);
      }
    }
  }
};
