import {
  MARK_FAVE_FROM_ORDERS,
  SET_ORDERID_FROM_RECENT_ORDER,
  CREATE_FAVE_RECENT_ORDERS,
  REORDER_CLICKED,
  SET_REORDER_ITEMID,
  REORDER_IS_LOADING,
  SET_REORDER_NOTIFICATION_POPUP,
  SET_IS_FAVE_REORDER,
} from "./ReOrderConst";

const initialState = {
  isLoading: false,
  markFavFlagFromOrders: false,
  orderIdFromRecentOrder: "",
  createRecentOrderFave: "",
  linkBasket: "",
  reorderClicked: false,
  reorderItemId: 0,
  reOrderNotificationPopup: false,
  isFaveReorder: false,
};

export default function ReOrderReducer(state = initialState, action: { type: string; payload: any }) {
  switch (action.type) {
    case REORDER_IS_LOADING:
      return { ...state, isLoading: action.payload || false };

    case MARK_FAVE_FROM_ORDERS:
      return {
        ...state,
        markFavFlagFromOrders: action.payload,
      };
    case SET_ORDERID_FROM_RECENT_ORDER:
      return {
        ...state,
        orderIdFromRecentOrder: action.payload,
      };
    case CREATE_FAVE_RECENT_ORDERS:
      return {
        ...state,
        createRecentOrderFave: action.payload,
      };
    case REORDER_CLICKED:
      return {
        ...state,
        reorderClicked: action.payload,
      };
    case SET_REORDER_ITEMID:
      return {
        ...state,
        reorderItemId: action.payload,
      };
    case SET_REORDER_NOTIFICATION_POPUP:
      return {
        ...state,
        reOrderNotificationPopup: action.payload,
      };
    case SET_IS_FAVE_REORDER:
      return {
        ...state,
        isFaveReorder: action.payload,
      };
    default:
      return state;
  }
}
