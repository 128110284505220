import { apiCall } from "@zaxbys/wrapper/frontendwrapper/apis/api";
import { CommonLayerResponseDataWithType } from "src/types";
import { CMS_FOOTER } from "api/endpoints";
import { FooterCMSData } from "types/cms/footer";

export const getFooterCMSData = () => {
  return apiCall({
    requestType: "GET",
    apiEndPoint: CMS_FOOTER,
  }) as Promise<CommonLayerResponseDataWithType<FooterCMSData>>;
};
