import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import Image from "next/image";
import { useDispatch, useSelector } from "react-redux";
import { Menu } from "src/types";
import { IC_BACK_TO_TOP } from "assets/images";
import theme from "theme/theme";
import AppStoreLogos from "components/atomic-components/molecules/AppStoreLogos/AppStoreLogos";
import Link from "components/global/Link/Link";
import { CMSRootState } from "src/redux/reducer/CMS/types";
import { setFooterCMSData } from "redux/reducer/CMS/CMSAction";
import { setSelectedCategoryName, setSelectedTabName } from "redux/reducer/Menu/MenuAction";
import ActionLink from "../ActionLink/ActionLink";
import Prompt from "../Prompt/Prompt";
import { MobileComponent } from "../MobileComponent/MobileComponents";
import MarkdownComponent from "../MarkdownComponent/MarkdownComponent";
import FooterLogo from "./FooterLogo";
import FooterRewards from "./FooterRewards";
import FooterSocialIconsContainer from "./FooterSocialIconsContainer";
import { FooterContainer } from "./FooterStyled";

type RootState = {
  cms: CMSRootState;
  store: {
    selectedStoreId: string;
  };
  menu: {
    storeMenu: Array<Menu>;
    getGenericMenu: Array<Menu>;
  };
};

const Footer = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const [isPopped, setPop] = useState(0);
  const onButtonClick = () => {
    setPop(isPopped + 1);
    if (isPopped >= 1) {
      window.location.reload();
    }
  };
  const isContactPage: boolean = router.pathname === "/contact-us";
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const { footerCMS, isFooterCMSLoaded, getGenericMenu, storeMenu, contactUs } = useSelector((state: RootState) => ({
    footerCMS: state.cms.footer,
    isFooterCMSLoaded: state.cms.isFooterLoaded,
    selectedStoreId: state?.store?.selectedStoreId,
    getGenericMenu: state?.menu?.getGenericMenu,
    storeMenu: state?.menu?.storeMenu,
    contactUs: state?.cms?.contactUs,
  }));

  const needMoreHelp = contactUs?.NeedMoreHelpContactUsPage;

  useEffect(() => {
    if (typeof isFooterCMSLoaded === "boolean" && !isFooterCMSLoaded) {
      dispatch(setFooterCMSData());
    }
  }, [isFooterCMSLoaded]);

  const appLogos = footerCMS?.PlayStoreLink && footerCMS?.AppStoreLink && (
    <AppStoreLogos className="footerLogos" appStoreLink={footerCMS.AppStoreLink} playStoreLink={footerCMS.PlayStoreLink} />
  );

  const socialLinks = footerCMS?.SocialLinks && <FooterSocialIconsContainer socialLinks={footerCMS.SocialLinks} />;

  const handleNavigation = (Label: string) => {
    if (Label.toLowerCase() === "catering") {
      let cateringCategoryName = storeMenu?.filter((item: Menu) => item.catering).shift();

      if (!cateringCategoryName) {
        cateringCategoryName = getGenericMenu?.filter((item: Menu) => item.catering).shift();
      }
      if (cateringCategoryName?.name !== undefined) {
        dispatch(setSelectedCategoryName(cateringCategoryName?.name));
      }
      dispatch(setSelectedTabName("Menu"));
    } else if (Label.toLowerCase() === "menu") {
      dispatch(setSelectedTabName("Menu"));
    }
  };
  return (
    <FooterContainer id="footer" className="common-footer">
      {isContactPage && needMoreHelp && (
        <div className="footerContactUs">
          <h1 className="title">{needMoreHelp?.Title || "NEED MORE HELP?"}</h1>
          <h3 className="subtitle"> {needMoreHelp?.Subtitle || "Call us for further assistance."} </h3>
          <h1 className="bodyCopy"> {needMoreHelp?.Body || "Monday - Sunday, 10AM EST to 9:30PM EST | 1-866-8-ZAXBYS (929297)"}</h1>
        </div>
      )}
      <div className="footerTop">
        <div className="footerTopContents">
          <div className="backToTopButton">
            <Image src={IC_BACK_TO_TOP} onClick={scrollToTop} alt="Chicken within circle" width={58} height={58} />
          </div>
          <div className="footerTopLinks">
            {footerCMS?.SubLinks?.map(({ Label, Link: LinkURL }, index: number) => (
              <ActionLink className="link" to={LinkURL} key={index}>
                {Label}
              </ActionLink>
            ))}
          </div>
          {socialLinks}
        </div>
        <div className="footerTopBorder"></div>
      </div>
      <div className="footerBottom">
        <div>
          {footerCMS?.AdviceText && (
            <div className="caloriesMessage">
              <MarkdownComponent markdown={footerCMS.AdviceText}></MarkdownComponent>
            </div>
          )}

          <div className="footerBottomLinksContainer">
            <div className="footerBottomLinksContainerPrimary">
              {footerCMS?.MainLinks?.map(({ Label, Link: LinkURL }, index: number) => {
                return (
                  <div key={index} onClick={() => Label && handleNavigation(Label)}>
                    <Link
                      className="footerBottomLink"
                      to={String(LinkURL)}
                      target={Label?.toLocaleLowerCase() === "allergen info" ? "__blank" : ""}
                    >
                      {Label}
                    </Link>
                  </div>
                );
              })}
            </div>
            <div className="footerBottomLinksContainerSecondary">
              {footerCMS?.LegalLinks?.map(({ Label, Link: LinkURL }, index: number) => (
                <Link className="footerBottomLink" to={LinkURL || ""} key={index}>
                  {Label?.toUpperCase()}
                </Link>
              ))}
              {/* inline styles will only apply for cookie setting */}
              <button
                onClick={onButtonClick}
                id="ot-sdk-btn"
                className="ot-sdk-show-settings footerBottomLink"
                style={{
                  background: "none",
                  color: theme.color.primary.foundersBlue,
                  border: "none",
                  padding: 0,
                  fontSize: "16px",
                  textAlign: "left",
                  fontWeight: 500,
                  textTransform: "uppercase",
                  marginTop: 0,
                  fontFamily: theme.font.barlow,
                  cursor: "pointer",
                  outline: "inherit",
                }}
              >
                Cookie Settings
              </button>
            </div>
          </div>
        </div>

        <Prompt size="lg" className="zaxAppAdvertisementMobile downloadZaxApp">
          <span className="zaxAppAdvertisementMobile downloadWord">DOWNLOAD&nbsp;&nbsp;</span>
          THE ZAX APP
        </Prompt>
        <div className="appStoreLogosMobile">{appLogos}</div>
        <span className="zaxAppAdvertisementMobile socialHeader">CONNECT WITH ZAXBYS</span>
        <MobileComponent>{socialLinks}</MobileComponent>

        <div className="footerLogoCopyrightSection">{footerCMS?.Copyright && <FooterLogo copyrightText={footerCMS?.Copyright} />}</div>
        <div className="footerContentContainer">
          {footerCMS?.AppBannerImage && <FooterRewards image={footerCMS.AppBannerImage} />}
          <Prompt size="sm" className="zaxAppAdvertisement">
            <span className="footerDownloadApp">Download </span>the Zax App
          </Prompt>
          {appLogos}
        </div>

        <div className="footerBottomLinksContainerMobile">
          {footerCMS?.LegalLinks?.map(({ Label, Link: LinkURL }, index: number) => (
            <Link className="footerBottomLink" to={LinkURL || ""} key={index}>
              {Label?.toUpperCase()}
            </Link>
          ))}
          {/* inline styles will only apply for cookie setting */}
          <button
            onClick={onButtonClick}
            id="ot-sdk-btn"
            className="ot-sdk-show-settings footerBottomLink"
            style={{
              background: "none",
              color: theme.color.primary.foundersBlue,
              border: "none",
              padding: 0,
              fontSize: "13px",
              textAlign: "left",
              fontWeight: 700,
              textTransform: "uppercase",
              marginTop: 0,
              cursor: "pointer",
              outline: "inherit",
              fontFamily: theme.font.barlow,
            }}
          >
            Cookie Settings
          </button>
        </div>
      </div>
    </FooterContainer>
  );
};

export default Footer;
