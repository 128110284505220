import thunk from "redux-thunk";
import sessionStorage from "redux-persist/lib/storage/session";
import { combineReducers, legacy_createStore as createStore, applyMiddleware } from "redux";
import { persistReducer } from "redux-persist";
import { composeWithDevTools } from "redux-devtools-extension";
import { userReducer } from "@zaxbys/wrapper/frontendwrapper/redux/user/reducer";
import { signupReducer } from "@zaxbys/wrapper/frontendwrapper/redux/auth/signup/reducer";
import { forgotPasswordReducer } from "@zaxbys/wrapper/frontendwrapper/redux/auth/forgot-password/reducer";
import { changePasswordReducer } from "@zaxbys/wrapper/frontendwrapper/redux/account/change-password/reducer";
import { updatePhoneReducer } from "@zaxbys/wrapper/frontendwrapper/redux/account/update-phone/reducer";
import { cmsReducer } from "@zaxbys/wrapper/frontendwrapper/redux/cms/reducer";
import globalLoaderReducer from "@zaxbys/wrapper/frontendwrapper/redux/globalLoader/reducer";
import freedomPayReducer from "@zaxbys/wrapper/frontendwrapper/redux/freedompay/reducer";
import { applePayReducer } from "@zaxbys/wrapper/frontendwrapper/redux/applepay/reducer";
import { rewardsReducer } from "@zaxbys/wrapper/frontendwrapper/redux/rewards/reducer";
import { storeReducer } from "@zaxbys/wrapper/frontendwrapper/redux/store/reducer";
import { basketReducer } from "@zaxbys/wrapper/frontendwrapper/redux/basket/reducer";
import { googlePayReducer } from "@zaxbys/wrapper/frontendwrapper/redux/googlepay/reducer";
import Me from "./reducer/Me/MeReducer";
import Address from "./reducer/Address/AddressReducer";
import Menu from "./reducer/Menu/MenuReducer";
import userStatusReducer from "./reducer/UserStatus/UserStatusReducer";
import CMSReducer from "./reducer/CMS/CMSReducer";
import PlaceOrderReducer from "./reducer/PlaceOrder/PlaceOrderReducer";
import LocationReducer from "./reducer/Location/LocationReducer";
import GiftCardReducer from "./reducer/GiftCards/GiftCardsReducer";
import ReOrderReducer from "./reducer/ReOrder/ReOrderReducer";
import DeviceReducer from "./reducer/Device/DeviceReducer";
import { CareersReducer } from "./reducer/Careers/CareersReducer";
import FranchiseReducer from "./reducer/Franchise/FranchiseReducer";
import GlobalReducer from "./reducer/Global/GlobalReducer";
import { CheckoutReducer } from "./reducer/Checkout/CheckoutReducer";

const persistConfig = {
  key: "root",
  storage: sessionStorage,
  whitelist: ["user", "address", "menu", "basket", "placeOrder", "userStatus", "store", "rewards", "currentDevice"], // We need add whitelist persist
};

export const rootReducer = combineReducers({
  user: userReducer,
  rewards: rewardsReducer,
  signup: signupReducer,
  forgotPassword: forgotPasswordReducer,
  changePassword: changePasswordReducer,
  updatePhone: updatePhoneReducer,
  cms1: cmsReducer,
  store: storeReducer,
  address: Address,
  me: Me,
  menu: Menu,
  basket: basketReducer,
  userStatus: userStatusReducer,
  cms: CMSReducer,
  placeOrder: PlaceOrderReducer,
  payment: freedomPayReducer,
  applepayPayment: applePayReducer,
  location: LocationReducer,
  giftcards: GiftCardReducer,
  reorder: ReOrderReducer,
  globalLoader: globalLoaderReducer,
  currentDevice: DeviceReducer,
  careers: CareersReducer,
  googlePayPayment: googlePayReducer,
  franchise: FranchiseReducer,
  globalReducer: GlobalReducer,
  checkout: CheckoutReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk)));

store.dispatch as any;

export default store;
