import {
  IS_SHOW_TOGGLE_MODAL,
  SET_TOGGLE_MENUS,
  SET_IS_FORM_VALID,
  SET_IS_APPLEPAY_PAYMENT_SUPPORTED,
  SAVE_SELECTED_SPOTLIGHT_ITEM_INDEX,
  SET_ADDED_NOTIFICATION_OPEN,
  SET_ADDED_NOTIFICATION_CLOSE,
  SET_CLOSE_RIBBON_BANNER,
  SET_IS_SHOW_RIBBON_BANNER,
  SET_USER_CURRENT_LOCATION,
} from "./GlobalConst";

const initialState = {
  isShowToggleModal: false,
  toggleMenus: true,
  isFormValid: false,
  isApplePayPaymentSupported: false,
  selectedSpotlightItemIndex: null,
  lastInteractionIsClick: false,
  addedNotificationIsOpened: null,
  addedNotificationDuration: 3000,
  closeRibbonBanner: false,
  isShowRibbonBanner: false,
  userCurrentLocation: null,
};

function GlobalReducer(state = initialState, action: { type: string; payload: any }) {
  switch (action.type) {
    case IS_SHOW_TOGGLE_MODAL:
      return { ...state, isShowToggleModal: action.payload };

    case SET_TOGGLE_MENUS:
      return { ...state, toggleMenus: action.payload };

    case SET_IS_FORM_VALID:
      return { ...state, isFormValid: action.payload };

    case SET_IS_APPLEPAY_PAYMENT_SUPPORTED:
      return { ...state, isApplePayPaymentSupported: action.payload };

    case SAVE_SELECTED_SPOTLIGHT_ITEM_INDEX:
      return {
        ...state,
        selectedSpotlightItemIndex: action.payload,
        lastInteractionIsClick: true,
      };

    case SET_ADDED_NOTIFICATION_OPEN:
      return { ...state, addedNotificationIsOpened: action.payload.itemName, addedNotificationDuration: action.payload.duration };

    case SET_ADDED_NOTIFICATION_CLOSE:
      return { ...state, addedNotificationIsOpened: null, addedNotificationDuration: 3000 };

    case SET_CLOSE_RIBBON_BANNER:
      return { ...state, closeRibbonBanner: action.payload };

    case SET_IS_SHOW_RIBBON_BANNER:
      return { ...state, isShowRibbonBanner: action.payload };

    case SET_USER_CURRENT_LOCATION:
      return {
        ...state,
        userCurrentLocation: action.payload,
      };

    default:
      return state;
  }
}

export default GlobalReducer;
