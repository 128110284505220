import { useSelector } from "react-redux";
import Headings from "components/atomic-components/atoms/typography/Headings/Headings";
import Checkbox from "components/atomic-components/atoms/form-elements/Checkbox/Checkbox";
import { useOrderNowModal } from "containers/Order/modals/OrderNowModal/useOrderNowModal";
import { RootState } from "types/index";
import Button from "components/atomic-components/atoms/button/Button";
import { amenitiesArray, milesInArray } from "constants/constant";
import { AmenitiesCheckbox, DistanceRadioButton, HorizontalLine, LocationFilteredSearchStyled } from "./LocationFilteredSearchStyled";
import { useFilteredSearch } from "./useFilteredSearch";
interface AmenitiesValues {
  delivery: boolean;
  curbsidePickUp: boolean;
  driveThruPickUp: boolean;
  openLate: boolean;
  freeWifi: boolean;
  cateringManagerOnsite: boolean;
  cateringDelivery: boolean;
}

const LocationFilteredSearch = () => {
  const { values, handleChange, handleToggle, getSelectRadioButton, handleApply, radius } = useFilteredSearch();
  const device = useSelector((state: RootState) => state.currentDevice.device);
  const { orderModal, closeModal } = useOrderNowModal();
  const onHandleClick = (value: string) => {
    if (value === radius.toString()) {
      return;
    }
    handleToggle(value);
  };

  const isMobile = device === "MOBILE";
  return (
    <LocationFilteredSearchStyled
      isOpen={orderModal === "FILTERED_SEARCH"}
      showCloseButton={!isMobile}
      showBackButton={isMobile}
      onClose={closeModal}
      mobileVariant="fullScreen"
    >
      <div className="locationFilterSearch">
        <Headings variant="primaryHeading3" className="title">
          Refine Your Search
        </Headings>
        <HorizontalLine />
        <DistanceRadioButton>
          <Headings variant="secondaryHeading2" fontSize={20} fontWeight={700} className="subHeading">
            DISTANCE
          </Headings>
          <div className="distanceGroup">
            {milesInArray.map((mile: any) => (
              <Checkbox
                key={mile.id}
                variant="primaryBlueFilled"
                value={getSelectRadioButton(mile.value)}
                name={mile.value}
                onChange={onHandleClick}
                rounded
              >
                {mile.value} miles
              </Checkbox>
            ))}
          </div>
        </DistanceRadioButton>
        <AmenitiesCheckbox>
          <Headings variant="secondaryHeading2" fontSize={20} fontWeight={700} className="subHeading">
            Amenities
          </Headings>
          <div className="amenitiesGroup">
            {amenitiesArray?.map((amenity: any) => (
              <Checkbox
                name={amenity.value}
                value={values[amenity.value as keyof AmenitiesValues]}
                onChange={handleChange}
                className="amenitiesCheck"
                variant="primaryBlueFilled"
                key={amenity.id}
              >
                {amenity.label}
              </Checkbox>
            ))}
          </div>
        </AmenitiesCheckbox>
        <Button variant="primaryRed" onClick={handleApply} className="applyBtn">
          APPLY
        </Button>
      </div>
    </LocationFilteredSearchStyled>
  );
};

export default LocationFilteredSearch;
