import styled from "styled-components";

export const ForgotPasswordWrapperStyled = styled.div`
  .customCard {
    background: ${(props) => props.theme.color.primary.coleslawWhite};

    .authCardClose {
      top: 28px;
      right: 28px;
      padding: 0;
      background-color: ${(props) => props.theme.color.primary.coleslawWhite};
    }
    margin: 0px;
    border-radius: 40px;
    @media only screen and (max-width: ${(props) => props.theme.size.lg}) {
      border-radius: 0px;
    }
    .cardBody {
      padding: 50px 30px;
      border-radius: 20px;
      font-family: ${(props) => props.theme.font.barlowSemiCondensed};
      font-size: 28px;
      background-color: ${(props) => props.theme.color.primary.coleslawWhite};
      .requiredText {
        margin-top: 10px;
      }
      .requiredHead {
        margin-top: 15px;
        display: none;
      }
    }

    .codeInputStyle {
      font-family: ${(props) => props.theme.font.barlowSemiCondensed};
      font-size: 24px;

      &:focus {
        font-size: 32px;
      }
    }
  }

  .forgotPasswordTextInput {
    padding: 31.59px 10px 8.41px 10.46px;
  }
  @media only screen and (max-width: ${(props) => props.theme.size.sm}) {
    .customModal {
      width: calc(100% - 40px);
      height: auto;
      top: 20px;

      .customCard {
        margin: 0;

        .cardBody {
          padding-left: 15px;
          padding-right: 15px;
        }
      }
    }
    > div {
      top: 97px;
      height: calc(100vh - 97px);
    }
    .forgotPasswordOverlay {
      top: 97px;
      height: calc(100vh - 85px);
    }
  }
  @media only screen and (max-width: ${(props) => props.theme.size.lg}) {
    .forgotPasswordOverlay {
      top: 0px;
      z-index: 99999;
    }
    .forgotButtonsMobileView {
      gap: 10px;
    }
    .customCard {
      .cardBody {
        .requiredHead {
          display: block;
        }
      }
    }
  }
`;
