export const mParticleEventsList = [
  "promo_code_activation",
  "click_share_referral_code",
  "click_apply_reward",
  "click_scan_to_earn",
  "start_check_out",
  "migration_terms_conditions_success",
  "click_order_now",
  "place_order_success",
  "click_reorder",
  "click_recent_orders",
  "click_go_big",
  "click_delivery",
  "sign_up_success",
  "click_sign_up_button",
  "click_faves",
  "click_top_nav_sign_up_button",
  "click_fave_it",
  "update_profile_success",
  "select_text_optin",
  "select_referral_by_friend",
  "click_log_in_button",
  "click_top_nav_log_in_button",
  "login_success",
  "reward_applied_success",
  "click_oops_ok_button",
  "close_ribbon_banner",
  "click_ribbon_banner",
  "click_hi_first_name",
  "click_view_your_order",
  "click_pick_up",
  "click_homepage_tile",
  "click_reorder_product_image",
  "click_reorder_view_your_order",
  "click_reorder_replace_all_items",
  "close_already_started_your_order_popup",
  "click_pay",
];
