import Image from "next/image";
import { useEffect, useState } from "react";
import { PRODUCT_UNAVAILABLE } from "assets/images";
import { UnavailableProductResponse } from "src/types";
import { beautifyPrice } from "utils/helper";

import SubHeadings from "../typography/SubHeadings/SubHeadings";
import Paragraph from "../typography/Paragraphs/Paragraph";
import { ProductCardStyled } from "./ProductCardStyled";

interface Props {
  className?: string;
  item: UnavailableProductResponse;
  isMobile: boolean;
}

const ProductCard = ({ className, item, isMobile }: Props) => {
  const [price, setPrice] = useState<number | undefined>(0);
  useEffect(() => {
    if ("cost" in item) {
      setPrice(item.cost);
    } else {
      setPrice(item.totalCost);
    }
  }, [item]);

  return (
    <ProductCardStyled className={className}>
      <div className="product-image">
        <Image
          loader={() => item.image || PRODUCT_UNAVAILABLE.src}
          src={item.image || PRODUCT_UNAVAILABLE.src}
          alt="product image"
          width={isMobile ? 90 : 126}
          height={isMobile ? 60 : 84}
        />
      </div>
      <div className="foodInfo">
        <SubHeadings variant="productTitle">{item.title ?? item.name}</SubHeadings>
        {price && (
          <Paragraph variant="productPrice" className="price">
            ${price && beautifyPrice(price)} {item.calories && `${"|"}${item.calories}Cal`}
          </Paragraph>
        )}
      </div>
    </ProductCardStyled>
  );
};

export default ProductCard;
