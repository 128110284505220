import { apiCall } from "@zaxbys/wrapper/frontendwrapper/apis/api";
import { CommonLayerResponseDataWithType } from "src/types";
import { PASSWORD_OPTIONS } from "../endpoints";

interface Request {
  username: string;
}

interface Response {
  email: string;
  phone: string;
  status: string;
}

export const passwordOptions = async (data: Request) => {
  return apiCall({
    requestType: "GET",
    apiEndPoint: `${PASSWORD_OPTIONS}?username=${encodeURIComponent(data.username)}`,
  }) as Promise<CommonLayerResponseDataWithType<Response>>;
};
