import styled, { css } from "styled-components";

export interface HeadingProps {
  variant:
    | "primaryHeading1"
    | "primaryHeading2"
    | "headingMedium"
    | "headingMedium2"
    | "headingMedium2Red"
    | "secondaryHeading1"
    | "secondaryHeading2"
    | "primaryHeading3"
    | "primaryHeading3Red"
    | "primaryHeading1Red"
    | "primaryHeading1Mobile";
  fontSize?: number;
  fontWeight?: number;
  className?: string;
  lineHeight?: number;
  mobileFontSize?: number;
  mobileFontWeight?: number;
  mobileLineHeight?: number;
}

const primaryHeading1 = css<HeadingProps>`
  line-height: normal;
  font-size: ${({ fontSize }) => fontSize ?? 51}px;
  font-family: ${(props) => props.theme.font?.ZaxCasual};
  font-style: italic;
  font-weight: 700;
  color: ${(props) => props.theme.color?.primary.foundersBlue};
  ${(props) => props.theme.responsive?.max.lg} {
    font-size: ${({ mobileFontSize }) => mobileFontSize ?? 30}px;
  }
`;

const primaryHeading1Red = css<HeadingProps>`
  line-height: normal;
  font-size: ${({ fontSize }) => fontSize ?? 51}px;
  font-family: ${(props) => props.theme.font?.ZaxCasual};
  font-style: italic;
  font-weight: ${({ fontWeight }) => fontWeight ?? 700};
  color: ${(props) => props.theme.color?.primary.tongueTorchRed};
  ${(props) => props.theme.responsive?.max.lg} {
    font-size: ${({ mobileFontSize }) => mobileFontSize ?? 30}px;
    font-weight: ${({ mobileFontWeight }) => mobileFontWeight ?? 700};
  }
`;

const primaryHeading1Mobile = css<HeadingProps>`
  line-height: normal;
  font-family: ${(props) => props.theme.font?.ZaxCasual};
  font-style: italic;
  color: ${(props) => props.theme.color?.primary.tongueTorchRed};
  font-size: 21px;
  font-weight: 700;
`;

const primaryHeading2 = css<HeadingProps>`
  line-height: normal;
  font-size: ${({ fontSize }) => fontSize ?? 60}px;
  font-family: ${(props) => props.theme.font?.barlowSemiCondensed};
  font-weight: ${({ fontWeight }) => fontWeight ?? 800};
  color: ${(props) => props.theme.color?.primary.foundersBlue};
  ${(props) => props.theme.responsive?.max.lg} {
    font-size: ${({ mobileFontSize }) => mobileFontSize ?? 36}px;
  }
`;

const headingMedium = css<HeadingProps>`
  line-height: normal;
  letter-spacing: 0.96px;
  font-size: ${({ fontSize }) => fontSize ?? 48}px;
  font-family: ${(props) => props.theme.font?.barlow};
  font-weight: ${({ fontWeight }) => fontWeight ?? 700};
  color: ${(props) => props.theme.color?.primary.foundersBlue};
  ${(props) => props.theme.responsive?.max.lg} {
    font-size: ${({ mobileFontSize }) => mobileFontSize ?? 20}px;
    letter-spacing: 0.4px;
  }
`;

const headingMedium2 = css<HeadingProps>`
  line-height: normal;
  font-size: ${({ fontSize }) => fontSize ?? 40}px;
  font-family: ${(props) => props.theme.font?.barlowCondensed};
  font-weight: ${({ fontWeight }) => fontWeight ?? 800};
  color: ${(props) => props.theme.color?.primary.foundersBlue};
  ${(props) => props.theme.responsive?.max.lg} {
    font-size: ${({ mobileFontSize }) => mobileFontSize ?? 28}px;
  }
`;

const headingMedium2Red = css<HeadingProps>`
  line-height: normal;
  font-size: ${({ fontSize }) => fontSize ?? 40}px;
  font-family: ${(props) => props.theme.font?.barlowCondensed};
  font-weight: ${({ fontWeight }) => fontWeight ?? 800};
  color: ${(props) => props.theme.color?.primary.foundersBlue};
  ${(props) => props.theme.responsive?.max.lg} {
    font-size: ${({ mobileFontSize }) => mobileFontSize ?? 28}px;
  }
`;

const secondaryHeading1 = css<HeadingProps>`
  line-height: normal;
  font-size: ${({ fontSize }) => fontSize ?? 34}px;
  font-family: ${(props) => props.theme.font?.ZaxCasual};
  font-style: italic;
  font-weight: 700;
  color: ${(props) => props.theme.color?.primary.foundersBlue};
  ${(props) => props.theme.responsive?.max.lg} {
    font-size: ${({ mobileFontSize }) => mobileFontSize ?? 24}px;
  }
`;

const secondaryHeading2 = css<HeadingProps>`
  line-height: normal;
  letter-spacing: 0.96px;
  font-size: ${({ fontSize }) => fontSize ?? 40}px;
  font-family: ${(props) => props.theme.font?.barlow};
  font-weight: ${({ fontWeight }) => fontWeight ?? 800};
  color: ${(props) => props.theme.color?.primary.foundersBlue};
  ${(props) => props.theme.responsive?.max.lg} {
    font-size: ${({ mobileFontSize }) => mobileFontSize ?? 28}px;
  }
`;

const primaryHeading3 = css<HeadingProps>`
  line-height: normal;
  font-size: ${({ fontSize }) => fontSize ?? 40}px;
  font-family: ${(props) => props.theme.font?.barlowSemiCondensed};
  font-weight: ${({ fontWeight }) => fontWeight ?? 800};
  color: ${(props) => props.theme.color?.primary.foundersBlue};
  ${(props) => props.theme.responsive?.max.lg} {
    font-size: 24px;
  }
`;

const primaryHeading3Red = css<HeadingProps>`
  line-height: normal;
  font-size: ${({ fontSize }) => fontSize ?? 40}px;
  font-family: ${(props) => props.theme.font?.barlowSemiCondensed};
  font-weight: ${({ fontWeight }) => fontWeight ?? 800};
  color: ${(props) => props.theme.color?.primary.tongueTorchRed};
  ${(props) => props.theme.responsive?.max.lg} {
    font-size: ${({ mobileFontSize }) => mobileFontSize ?? 28}px;
  }
`;

export const StyledHeading = styled.h1<HeadingProps>`
  font-style: normal;
  text-transform: uppercase;
  ${(props) =>
    ({
      primaryHeading1,
      headingMedium,
      headingMedium2,
      headingMedium2Red,
      secondaryHeading2,
      primaryHeading2,
      secondaryHeading1,
      primaryHeading3,
      primaryHeading1Red,
      primaryHeading3Red,
      primaryHeading1Mobile,
    }[props.variant])}
`;
