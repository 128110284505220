import { useSelector } from "react-redux";
import NavigationButton from "components/global/Buttons/NavigationButton/NavigationButton";
import { RootState } from "types/index";
import {
  IC_ARROW_THICK,
  IC_ARROW_THICK_WHITE,
  IC_ARROW_LEFT_SHARP_EDGES,
  IC_ARROW_LEFT_SHARP_EDGES_WHITE,
  IC_ARROW_THIN_LEFT_FOUNDERS_BLUE,
  IC_ARROW_SHARP_LEFT_FOUNDERS_BLUE,
  CHEVRON_LEFT_BLUE,
} from "assets/images";
import { NavigationButtonProps } from "../types";

const BackButton = ({ variant, children, isCheckout = false, ...rest }: NavigationButtonProps) => {
  const { device } = useSelector((state: RootState) => ({
    device: state.currentDevice.device,
  }));
  const returnImgSrc = () => {
    if (isCheckout) {
      if (device === "MOBILE") {
        return IC_ARROW_LEFT_SHARP_EDGES_WHITE;
      } else {
        return IC_ARROW_LEFT_SHARP_EDGES;
      }
    }
    if (variant === "third") {
      return IC_ARROW_THICK_WHITE;
    } else if (variant === "fourth") {
      return IC_ARROW_THIN_LEFT_FOUNDERS_BLUE;
    } else if (variant === "eleventh") {
      return IC_ARROW_SHARP_LEFT_FOUNDERS_BLUE;
    } else if (variant === "twelveth") {
      return CHEVRON_LEFT_BLUE;
    } else return IC_ARROW_THICK;
  };
  return (
    <NavigationButton imgPosition="left" imgSrc={returnImgSrc()} variant={variant} data-testid="backButton" {...rest}>
      {children || "BACK"}
    </NavigationButton>
  );
};

export default BackButton;
