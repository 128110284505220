import { apiCall } from "@zaxbys/wrapper/frontendwrapper/apis/api";
import {
  itemsNotTransferred,
  setBasketDetails,
  setFaveDisableFlag,
  setFlagOnProductLength,
  setTime,
} from "@zaxbys/wrapper/frontendwrapper/redux/basket/actions";
import { CreateUserFavFromOrder, ReorderRequest, CreateNewFavBasket, CommonLayerResponseData, RecentOrderResponse } from "src/types";
import { BASKET, USERS } from "api/endpoints";
import { setDataToCookie } from "utils/storage";
import { logAnalyticsEvents } from "utils/mparticle/mparticleHelper";
import { setErrorMessageToModal, setErrorMessageToModalFlag } from "../UserStatus/UserStatusAction";
import {
  MARK_FAVE_FROM_ORDERS,
  SET_ORDERID_FROM_RECENT_ORDER,
  REORDER_IS_LOADING,
  SET_REORDER_NOTIFICATION_POPUP,
  SET_IS_FAVE_REORDER,
} from "./ReOrderConst";

export const reOrderIsLoading = (isLoading: boolean) => ({
  type: REORDER_IS_LOADING,
  payload: isLoading,
});

export const setFavFlagFromOrder = (payload: boolean) => {
  return { type: MARK_FAVE_FROM_ORDERS, payload: payload };
};

export const setOrderIdFromRecentOrder = (payload: boolean) => {
  return { type: SET_ORDERID_FROM_RECENT_ORDER, payload: payload };
};

export const createFavFromRecentOrder = (payload: CreateUserFavFromOrder) => {
  return apiCall({
    apiEndPoint: `${USERS}/baskets-from-order`,
    requestType: "POST",
    isAuth: true,
    token: payload.idToken,
    apiPostData: {
      orderId: payload.orderId,
      accessToken: payload.accessToken,
      name: payload.name,
      default: payload.default,
    },
  });
};

// TODO check with services about unavailableProducts always being empty array
// as a quickfix, we will be comparing with the requested items
export const updatingBasketDetailsBasedOnAPIResponse = (
  dispatch: any,
  response: CommonLayerResponseData,
  orderDetails?: RecentOrderResponse
) => {
  dispatch(reOrderIsLoading(false));
  if (response.success) {
    const responseData: any = response.response;
    setDataToCookie("basketId", responseData.basketId);
    if (responseData.products.length > 1) {
      dispatch(setFlagOnProductLength(true));
    }
    if (responseData?.products?.length > 0) {
      dispatch(setFaveDisableFlag(true));
      dispatch(setTime(3));
      dispatch(setBasketDetails(responseData));
    }

    dispatch(
      itemsNotTransferred(responseData?.unavailableProducts?.length ? responseData.unavailableProducts : orderDetails?.unavailableProducts)
    );
  } else {
    dispatch(setErrorMessageToModal(response.error ? response.error.message : "Something went wrong"));
    dispatch(setErrorMessageToModalFlag(true));
  }
};

// NOTE: that calling this endpoint without the order details of the selected order it will not use the new services flow
// if in the future the old flow is deprecated or removed make the orderDetails in ReorderRequest mandatory not optional
export const reorderRecentOrders = (payload: ReorderRequest, isProductCard: boolean = false) => {
  return (dispatch: any) => {
    dispatch(reOrderIsLoading(true));
    apiCall({
      apiEndPoint: `${BASKET}/create-from-order`,
      requestType: "POST",
      apiPostData: {
        orderId: payload.id,
        storeId: payload.storeId,
        accessToken: payload.accessToken,
        handoffMode: payload.handoffMode,
        address: payload.address,
        orderDetails: payload.orderDetails,
      },
      token: payload.idToken,
    }).then((response: CommonLayerResponseData) => {
      if (response?.success) {
        logAnalyticsEvents(response?.response, payload);
        if (response?.response?.products?.length > 0 && response?.response?.unavailableProducts?.length === 0) {
          dispatch(setReorderNotificationPopup(true));
        }
      }

      updatingBasketDetailsBasedOnAPIResponse(dispatch, response, payload.orderDetails);
    });
  };
};

export const setNewBasketFromFavReorder = (payload: CreateNewFavBasket) => {
  return (dispatch: any) => {
    dispatch(reOrderIsLoading(true));
    apiCall({
      apiEndPoint: `${BASKET}/create-from-favorite`,
      requestType: "POST",
      apiPostData: {
        favoriteId: payload.favoriteId,
        storeId: payload.storeId,
        accessToken: payload.accessToken,
        handoffMode: payload.handoffMode,
        address: payload.address,
      },
      token: payload?.idToken,
    }).then((response) => {
      if (response?.success) {
        logAnalyticsEvents(response?.response, payload);
        if (response?.response?.products?.length > 0 && response?.response?.unavailableProducts?.length === 0) {
          dispatch(setReorderNotificationPopup(true));
        }
      }
      updatingBasketDetailsBasedOnAPIResponse(dispatch, response);
    });
  };
};

export const linkBasketToUser = (accessToken: string, basketId: string, idToken: string, userId: string) => {
  return apiCall({
    apiEndPoint: `${USERS}/baskets/link`,
    requestType: "POST",
    isAuth: true,
    token: idToken,
    apiPostData: {
      accessToken: accessToken,
      basketId: basketId,
    },
  });
};

export const setReorderNotificationPopup = (payload: boolean) => {
  return { type: SET_REORDER_NOTIFICATION_POPUP, payload: payload };
};

export const setIsFaveReorder = (payload: boolean) => {
  return { type: SET_IS_FAVE_REORDER, payload: payload };
};
