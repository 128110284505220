import styled from "styled-components";

interface StyledProps {
  fontSize: string;
  lineHeight: string;
  variant?: string;
}

export const HeadingStyled = styled.h1<StyledProps>`
  font-family: ${(props) => props.theme.font.scriptRough};
  color: ${(props) => props.theme.color.core.blue};
  font-size: ${(props) => props.fontSize};
  line-height: ${(props) => props.lineHeight};
  font-weight: 500;
  text-align: center;

  ${(props) => props.variant === "RED" && `color: ${props.theme.color.core.red}`}
`;
