import React, { useEffect } from "react";
import Image from "next/image";
import { useDispatch, useSelector } from "react-redux";
import { CHECKMARK_ROUND_BLACK } from "assets/images";
import { setAddedNotificationClose } from "redux/reducer/Global/GlobalAction";
import { RootState } from "types/index";
import { CheckmarkIconWrapper, ItemName, AddedToOrderText } from "./CheckmarkIconStyled";

const NotificationPresentation = ({ duration, itemName }: any) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(setAddedNotificationClose());
    }, duration);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <CheckmarkIconWrapper>
      <Image src={CHECKMARK_ROUND_BLACK} alt="Checkmark" width={68} height={68} />
      <ItemName>{itemName}</ItemName>
      <AddedToOrderText>ADDED TO ORDER</AddedToOrderText>
    </CheckmarkIconWrapper>
  );
};

export const CheckmarkIcon = () => {
  const { addedNotificationIsOpened, duration, device } = useSelector((state: RootState) => ({
    addedNotificationIsOpened: state.globalReducer.addedNotificationIsOpened,
    duration: state.globalReducer.addedNotificationDuration,
    device: state.currentDevice.device,
  }));

  return (
    <>
      {typeof addedNotificationIsOpened === "string" && device === "MOBILE" && (
        <NotificationPresentation duration={duration} itemName={addedNotificationIsOpened} />
      )}
    </>
  );
};
