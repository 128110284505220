import { SocialMedia } from "src/types";
import SocialIcon from "components/global/SocialIcon/SocialIcon";
import { SocialLinks, SocialMediaName } from "types/cms/footer";
import { FooterSocialIconsContainerStyled } from "./FooterSocialIconsContainerStyled";

interface Props {
  socialLinks: SocialLinks;
}

const FooterSocialIconsContainer = ({ socialLinks }: Props) => {
  const socialMediaNames = ["Facebook", "Twitter", "Instagram", "Youtube", "TikTok"] as SocialMediaName[];
  return (
    <FooterSocialIconsContainerStyled>
      {socialMediaNames.map(
        (socialMediaName) =>
          socialMediaName && (
            <SocialIcon
              mobileStyle="DEFAULT"
              icon={socialMediaName.toLowerCase() as SocialMedia}
              url={socialLinks[socialMediaName] ?? ""}
              key={socialMediaName}
            />
          )
      )}
    </FooterSocialIconsContainerStyled>
  );
};

export default FooterSocialIconsContainer;
