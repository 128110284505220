import { ReactNode } from "react";
import useWindowDimensions from "hooks/useWindowDimensions";
import Heading from "../Heading/Heading";
import StackedButtonsContainer from "../StackedButtonsContainer/StackedButtonsContainer";
import TextBlock from "../TextBlock/TextBlock";
import { ValueUpdatedStyled } from "./ValueUpdatedStyled";

interface Props {
  valueName: string;
  buttons: ReactNode;
  successMessage?: string;
  actionType?: string;
}
const ValueUpdated = ({ valueName, buttons, successMessage, actionType = "updated" }: Props) => {
  const { width } = useWindowDimensions();
  return (
    <ValueUpdatedStyled>
      <Heading size="md" className="heading">
        Your {valueName} has been {actionType}
      </Heading>
      {width < 786 && <hr className="horizontalDivider" />}
      {successMessage && (
        <TextBlock size="lg" maxWidth={390} className="text">
          {successMessage}
        </TextBlock>
      )}
      <StackedButtonsContainer direction={width < 786 ? "col" : "row"} className="btnWrapper" mobileDirection="col-reverse">
        {buttons}
      </StackedButtonsContainer>
    </ValueUpdatedStyled>
  );
};

export default ValueUpdated;
