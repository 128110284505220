import { useSelector } from "react-redux";
import useWindowDimensions from "hooks/useWindowDimensions";
import { RootState } from "types/index";
import StackedButtonsContainer from "components/global/StackedButtonsContainer/StackedButtonsContainer";
import Paragraph from "components/atomic-components/atoms/typography/Paragraphs/Paragraph";
import TextField from "components/atomic-components/atoms/form-elements/TextField/TextField";
import Button from "components/atomic-components/atoms/button/Button";
import Headings from "components/atomic-components/atoms/typography/Headings/Headings";
import ForgotPasswordStyled from "./ForgotPasswordPromptStyled";
import useForgotPasswordEmail from "./useForgotPasswordPrompt";

const ForgotPasswordPrompt = () => {
  const { isPasswordResetLimitExceeded } = useSelector((state: RootState) => ({
    isPasswordResetLimitExceeded: state.userStatus.isPasswordResetLimitExceeded,
  }));

  const { values, errors, handleChange, handleBlur, handleSubmit } = useForgotPasswordEmail(isPasswordResetLimitExceeded);
  const { ForgotMyPassword } = useSelector((state: RootState) => ({
    ForgotMyPassword: state?.cms?.signinLoginMessage?.ForgotMyPassword,
  }));
  const screen = useWindowDimensions();

  return (
    <ForgotPasswordStyled>
      <Headings className="heading" variant="secondaryHeading2">
        {isPasswordResetLimitExceeded ? "Verify Your Account" : ForgotMyPassword?.Title}
      </Headings>
      {screen.width < 787 && <hr className="border" />}
      {isPasswordResetLimitExceeded && (
        <Paragraph className="information" variant={"primaryParagraph1"}>
          You entered the wrong login information too many times. For your security, we’ll email you a verification code to reset your
          password.
        </Paragraph>
      )}
      {!isPasswordResetLimitExceeded && (
        <Paragraph className="enterEmailText" variant={"primaryParagraph1"}>
          {ForgotMyPassword?.Subtitle}
        </Paragraph>
      )}
      <TextField
        name="username"
        label={ForgotMyPassword?.EmailField}
        value={values.username}
        error={errors.username}
        onChange={handleChange}
        onBlur={handleBlur}
        maxLength={294}
      />
      <StackedButtonsContainer direction="col" mobileDirection="col-reverse" className="stackedForgotButtons forgotButtonsMobileView">
        <Button
          variant="primaryBlue"
          onClick={handleSubmit}
          disabled={!values.username || errors.username.length !== 0}
          className="forgetPasswordButton"
          data-testid="forgotPasswordButton"
        >
          NEXT
        </Button>
      </StackedButtonsContainer>
    </ForgotPasswordStyled>
  );
};

export default ForgotPasswordPrompt;
