import { apiCall } from "@zaxbys/wrapper/frontendwrapper/apis/api";
import { CommonLayerResponseDataWithType } from "types/index";
import { SEND_USER_VERIFY_CODE } from "../endpoints";

interface Request {
  idToken: string;
  accessToken: string;
  attribute: "PHONE";
}

interface Response {
  destination: string;
  deliveryMedium: "SMS";
  attribute: "phone";
}

export const sendUserVerificationCode = async (data: Request) => {
  return apiCall({
    requestType: "POST",
    apiEndPoint: SEND_USER_VERIFY_CODE,
    isAuth: true,
    apiPostData: {
      accessToken: data.accessToken,
      attribute: data.attribute,
    },
    token: data.idToken,
  }) as Promise<CommonLayerResponseDataWithType<Response>>;
};
