import Image from "next/image";
import { StaticImageData } from "src/types";
import {
  IC_LOCATION_PIN_RED,
  IC_LOCATION_PIN_BLUE,
  IC_LOCATION_PIN_GREY,
  IC_LOCATION_PIN_LIGHT_BLUE,
  IC_LOCATION_PIN_BLUE_HOME,
  IC_LOCATION_PIN_RED_HOME,
  IC_LOCATION_PIN_UNDERLINE,
} from "assets/images";
import { LocationPinStyled } from "./LocationPinStyled";

type LocationPinStatus = "ACTIVE" | "OFFLINE" | "NEW" | "CLOSED";

type Props = {
  isSelected?: boolean;
  className?: string;
} & (
  | {
      place: "HOME";
      status: null;
    }
  | {
      place: "STORE";
      status: LocationPinStatus;
    }
);

type LocationPinStatusHashmap = {
  // eslint-disable-next-line no-unused-vars
  [key in LocationPinStatus]: Array<StaticImageData>;
};

const LocationPin = ({ place, status, isSelected, className = "" }: Props) => {
  let image: StaticImageData;
  const altText = `Location pin ${place} ${status || ""} ${isSelected && "selected"}`;
  const statusImages: LocationPinStatusHashmap = {
    ACTIVE: [IC_LOCATION_PIN_RED, IC_LOCATION_PIN_BLUE],
    NEW: [IC_LOCATION_PIN_RED, IC_LOCATION_PIN_BLUE],
    OFFLINE: [IC_LOCATION_PIN_GREY, IC_LOCATION_PIN_GREY],
    CLOSED: [IC_LOCATION_PIN_LIGHT_BLUE, IC_LOCATION_PIN_LIGHT_BLUE],
  };
  if (place === "HOME" || status === null || typeof status === "undefined")
    image = isSelected ? IC_LOCATION_PIN_RED_HOME : IC_LOCATION_PIN_BLUE_HOME;
  else image = statusImages[status][isSelected ? 0 : 1];

  return (
    <LocationPinStyled className={className}>
      <Image src={image} alt={altText} />
      {isSelected && <Image src={IC_LOCATION_PIN_UNDERLINE} alt="Location pin underline" />}
    </LocationPinStyled>
  );
};

export default LocationPin;
