import { apiCall } from "@zaxbys/wrapper/frontendwrapper/apis/api";
import { CommonLayerResponseDataWithType } from "src/types";
import { CMS_WHY_ZAXBYS } from "api/endpoints";
import { WhyZaxbysCMSData } from "types/cms/why-zaxbys";

export const getWhyZaxbysCMSData = () => {
  return apiCall({
    requestType: "GET",
    apiEndPoint: CMS_WHY_ZAXBYS,
  }) as Promise<CommonLayerResponseDataWithType<WhyZaxbysCMSData>>;
};
