import { useSelector, useDispatch } from "react-redux";
import { setUserAddress } from "redux/reducer/Address/AddressAction";
import { setLocationOrderModal, setLocationStep } from "redux/reducer/Location/LocationAction";
import { RootState } from "types/index";

export const useNotNearAddress = () => {
  const dispatch = useDispatch();
  const { userAddressFromModal, userAddressConfirmation } = useSelector((state: RootState) => ({
    userAddressFromModal: state.address?.userAddressFromModal,
    userAddressConfirmation: state.address?.userAddressConfirmation,
  }));

  const handleConfirm = () => {
    dispatch(setUserAddress(userAddressFromModal));
    dispatch(setLocationOrderModal(""));
    if (!userAddressConfirmation) {
      dispatch(setLocationStep("DELIVERY_OUTSIDE_RADIUS"));
    }
  };

  return {
    handleConfirm,
  };
};
