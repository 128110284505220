export const SET_NEWLY_ADDED_CARD = "SET_NEWLY_ADDED_CARD";
export const SET_MULTIPLE_CARDS_ERROR_POPUP = "SET_MULTIPLE_CARDS_ERROR_POPUP";
export const SET_MULTIPLE_CARDS_ERROR_POPUP_CREDIT_CARD = "SET_MULTIPLE_CARDS_ERROR_POPUP_CREDIT_CARD";
export const SET_IS_SINGLE_CARD_PAYMENT = "SET_IS_SINGLE_CARD_PAYMENT";
export const SET_DELETE_CARD_DATA = "SET_DELETE_CARD_DATA";
export const SET_TRIGGER_INIT_API_CALL = "SET_TRIGGER_INIT_API_CALL";

export const INITIAL_DELETE_CARDS_STATE = {
  isModalOpen: false,
  display: false,
  cardData: { accountId: "", isNewCard: false },
  cardType: "",
};
