import { useRouter } from "next/router";
import { useDispatch } from "react-redux";
import ErrorModal from "components/atomic-components/molecules/ErrorModal/ErrorModal";
import useModal from "hooks/useModal";
import { setPreviousRouteUrl, setSelectedLocationProduct } from "redux/reducer/Menu/MenuAction";
import Analytics from "analytics/Analytics";

const ProductNotAvailableModal = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { modal, setModal } = useModal();

  const onHandleClick = () => {
    // Remove query parameter(showProductNotAvailableModal) on closing the modal
    const { pathname, query } = router;
    if (query?.showProductNotAvailableModal) {
      delete query.showProductNotAvailableModal;
      router.push({ pathname, query }, undefined, { shallow: true });
    }
    dispatch(setPreviousRouteUrl(""));
    dispatch(
      setSelectedLocationProduct({
        productId: 0,
        productName: "",
        categoryName: "",
      })
    );
    Analytics.getInstance().logEvent("click_oops_ok_button", {}, true, false);
    setModal("");
  };

  return (
    <ErrorModal
      isOpen={modal === "PRODUCT_NOT_AVAILABLE"}
      heading="Oops!"
      subHeading="The item you wanted isn’t available at the location you chose."
      buttonCTA="OK"
      onClose={onHandleClick}
    />
  );
};

export default ProductNotAvailableModal;
