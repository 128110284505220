import styled from "styled-components";
import { IC_CHECKMARK_ROUND_RED } from "assets/images";
interface props {
  buttonDisabled?: boolean;
}
export const AddressFormStyled = styled.div<props>`
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    margin-bottom: 6px;
    max-width: 368px;
    font-family: ${(props) => props.theme.font.barlowSemiCondensed};
    font-size: 40px;
    font-weight: 800;
    line-height: 42px;
    text-align: center;
  }

  .requiredText {
    margin-bottom: 25px;
  }
  .genericError {
    font-size: 16px;
    color: ${(props) => props.theme.color.core.red};
    margin-bottom: 10px;
  }
  .driver-notes-heading {
    font-family: ${(props) => props.theme.font.sansRegular};
    font-size: 16px;
    margin-bottom: 20px;
    line-height: 19px;
  }
  .businessCheckBox {
    display: flex;
    width: 100%;
    margin-bottom: 52px !important;
    justify-content: flex-start;
    label::before {
      background: ${(props) => props.theme.color.core.white};
    }
  }
  .checkBoxText {
    font-family: ${(props) => props.theme.font.barlow};
    color: ${(props) => props.theme.color.primary.foundersBlue};
    font-size: 16px;
    font-weight: 500;
    line-height: 14px;
    margin-left: 8px;
  }
  .radioAsCheckboxUi {
    .checkbox {
      border-radius: 50px;
      &:checked {
        + span {
          background: url(${IC_CHECKMARK_ROUND_RED.src});
          background-repeat: no-repeat;
          width: 8px;
          height: 8px;
          display: inline-block;
          position: absolute;
          left: 6px;
          top: 6px;
          cursor: pointer;
        }
      }
    }
  }
  .favouriteMessage {
    display: flex;
    cursor: pointer;
    justify-content: flex-start;
    width: 100%;
    label::before {
      background: ${(props) => props.theme.color.core.white};
    }
    .text {
      font-family: ${(props) => props.theme.font.barlow};
      font-size: 16px;
      font-weight: 500;
      line-height: 19.2px;
      text-align: left;
      margin-left: 8px;
      .alt {
        font-family: ${(props) => props.theme.font.barlow};
        font-weight: 700;
      }
    }
  }

  .description {
    margin-bottom: 40px;
    font-family: ${(props) => props.theme.font.barlow};
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: center;
  }
  .AddressFormBackButton {
    position: absolute;
    left: 130px;
  }

  .confirmAddressButtons {
    gap: 12px;
    > button {
      width: 300px;
      height: 44px;
    }
    .saveButton {
      background: ${(props) => props.theme.color.core.white};
      ${(props) =>
        props.buttonDisabled &&
        `
              opacity: 1;
      border: 3px solid rgba(4, 30, 66, 0.3);
      color: rgba(4, 30, 66, 0.3);
      `}
    }
  }
  .inputsContainer {
    margin-bottom: 40px;
    border-top: 1px solid ${(props) => props.theme.color.secondary.coolGray4};
    padding-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: auto;

    .textAreaInput {
      .input {
        max-height: 220px;
      }

      textarea {
        min-height: 100%;
      }
    }

    .textInputGroupClass {
      &.invalid {
        input {
          color: ${(props) => props.theme.color.core.red};
          &:focus {
            color: ${(props) => props.theme.color.core.red};
          }
        }

        label {
          &.inputLabel {
            color: ${(props) => props.theme.color.core.red};
          }
        }
      }

      .errorRed {
        color: ${(props) => props.theme.color.core.red};
      }
    }
  }
  .genericError:empty {
    display: none;
  }
  @media only screen and (max-width: ${(props) => props.theme.size.lg}) {
    .title {
      font-size: 28px;
      max-width: 240px;
      line-height: 28px;
      text-align: center;
    }
    .display-linebreak {
      white-space: pre-line;
    }

    .favouriteMessage {
      .text {
        font-family: ${(props) => props.theme.font.barlow};
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
        text-align: left;
        margin-left: 0;
        .alt {
          font-size: 14px;
        }
      }
    }
    .businessCheckBox {
      margin-bottom: 60px !important;
    }
    .checkBoxText {
      font-family: ${(props) => props.theme.font.barlow};
      color: ${(props) => props.theme.color.primary.foundersBlue};
      font-size: 14px;
      font-weight: 400;
      line-height: 15px;
      margin-left: 0;
    }
    .driver-notes-heading {
      margin-bottom: 13px;
    }

    .description {
      font-weight: 500;
      margin-bottom: 32px;
    }

    .inputsContainer {
      padding-top: 20px;
      .input-wrapper {
        width: 294px;
        height: 44px;
        input {
          font-size: 16px;
        }
        .inputLabel {
          font-size: 16px;
        }
        &.input-wrapper input:focus ~ label,
        &.input-wrapper input:valid ~ label {
          font-size: 10px;
        }
      }
    }

    .textAreaInput {
      textarea {
        max-height: 124px;
        width: 294px;
        margin-top: 16px;
      }
      .totalCharacter {
        width: 294px;
      }
    }
    .confirmAddressButtons {
      gap: 10px;
      > button {
        width: 200px;
        height: 36px;
      }
    }
  }
`;

export const Favorite = styled.span<{ isActive: boolean }>`
  ${(props) => props.isActive && `color: ${props.theme.color.secondary.red}`};
`;
