import { apiCall } from "@zaxbys/wrapper/frontendwrapper/apis/api";
import { CommonLayerResponseDataWithType } from "src/types";
import { CMS_CONTACT_US } from "api/endpoints";
import { ContactUsCMSData } from "types/cms/contact-us";

export const getContactUsCMSData = () => {
  return apiCall({
    requestType: "GET",
    apiEndPoint: CMS_CONTACT_US,
  }) as Promise<CommonLayerResponseDataWithType<ContactUsCMSData>>;
};
