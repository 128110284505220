import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "components/global/Modal/Modal";
import Headings from "components/atomic-components/atoms/typography/Headings/Headings";
import CloseButton from "components/atomic-components/atoms/button/CloseButton/CloseButton";
import Paragraph from "components/atomic-components/atoms/typography/Paragraphs/Paragraph";
import { HorizontalLine } from "components/atomic-components/atoms/HorizontalLineStyled/HorizontalLineStyled";
import useModal from "hooks/useModal";
import { RootState } from "types/index";
import { showCurbsideReadyButtonFlag } from "redux/reducer/PlaceOrder/PlaceOrderActions";
import { CheckinSuccessModalStyled } from "./styles/CheckinSuccessModalStyled";

const CheckinSuccessModal = () => {
  const { successModalData } = useSelector((state: RootState) => ({
    successModalData: state?.placeOrder?.successModalData,
  }));

  const dispatch = useDispatch();
  const { modal, setModal } = useModal();
  return (
    <CheckinSuccessModalStyled>
      <Modal isOpen={modal === "CURBSIDE_CHECKIN_SUCCESS_MODAL"} className="CheckinSuccessModal">
        <div className="headerButtons">
          <CloseButton
            variant="eleventh"
            showText={false}
            className="closeBtn"
            onClick={() => {
              setModal("");
              if (successModalData?.title !== "FAILED") dispatch(showCurbsideReadyButtonFlag(false));
            }}
          />
        </div>
        <div className="modalContent">
          <Headings variant="primaryHeading2" mobileFontSize={28} className="headings">
            {successModalData?.title}
          </Headings>
          <HorizontalLine className="hr" />
          <Paragraph variant="primaryParagraph1" mobileFontWeight={500} mobileFontSize={16} className="subContent">
            {successModalData?.message}
          </Paragraph>
        </div>
      </Modal>
    </CheckinSuccessModalStyled>
  );
};

export default CheckinSuccessModal;
