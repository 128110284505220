import { useDispatch, useSelector } from "react-redux";
import { setLocationOrderModal } from "redux/reducer/Location/LocationAction";
import { RootState } from "types/index";

export const useOrderNowModal = () => {
  const dispatch = useDispatch();
  const orderModal = useSelector((state: RootState) => state.location?.orderModal);

  const closeModal = () => {
    dispatch(setLocationOrderModal(""));
  };

  return {
    orderModal,
    closeModal,
  };
};
