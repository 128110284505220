import router from "next/router";
import { useDispatch, useSelector } from "react-redux";
import Image from "next/image";
import { setStoreLocation } from "@zaxbys/wrapper/frontendwrapper/redux/store/actions";
import { ANALYTICS_CONST } from "@zaxbys/wrapper";
import CloseButton from "components/global/Buttons/CloseButton/CloseButton";
import PrimaryButton from "components/global/Buttons/PrimaryButton/PrimaryButton";
import Modal from "components/global/Modal/Modal";
import { DEFAULT_LAT, DEFAULT_LNG } from "constants/constant";
import useModal from "hooks/useModal";
import { CATEGORY_ITEM_DEFAULT_ICON } from "assets/images";
import { Group, Option, RootState } from "src/types";
import Analytics from "analytics/Analytics";
import { setLocationOrderType } from "redux/reducer/Location/LocationAction";
import { NotAvailableForDeliveryStyled } from "./NotAvailableForDeliveryStyled";

const NotAvailableForDelivery = () => {
  const dispatch = useDispatch();
  const { modal, setModal } = useModal();
  const { selectedProduct } = useSelector((state: any) => ({
    selectedProduct: state.menu?.selectedProduct,
  }));
  const { userGeolocationStatus, userLocation, device, corporateStoreId } = useSelector((state: RootState) => ({
    userGeolocationStatus: state?.userStatus?.presentUserGeolocationStatus,
    userLocation: state?.store?.userLocation,
    device: state.currentDevice.device,
    corporateStoreId: state.store?.corporateStoreId,
  }));

  let redirectLat = DEFAULT_LAT;
  let redirectLng = DEFAULT_LNG;
  if (userGeolocationStatus === true) {
    redirectLat = userLocation?.lat;
    redirectLng = userLocation?.lng;
  } else {
    redirectLat = DEFAULT_LAT;
    redirectLng = DEFAULT_LNG;
  }

  const onTryPickup = () => {
    // Added outofdelivery try pickup event in amplitude
    Analytics.getInstance().logEvent(ANALYTICS_CONST.ORDER.OUTOFDELIVERY_TRY_PICKUP, {
      ecommerce: { store_id: corporateStoreId },
    });

    dispatch(setLocationOrderType("PICKUP"));
    router.push("/locations");
    dispatch(
      setStoreLocation({
        lat: redirectLat,
        lng: redirectLng,
      })
    );
  };

  const onCloseModal = () => {
    setModal("");
  };
  const primaryChoices = selectedProduct?.groups?.map((item: Group) => {
    return (
      item.options &&
      item.options
        ?.filter((itemDefaultName: Option) => itemDefaultName.default && itemDefaultName.name)
        .map((itemName: Option) => itemName.name)
    );
  });
  return (
    <NotAvailableForDeliveryStyled>
      <Modal isOpen={modal === "DELIVERY_NOT_AVAILABLE"} className="notAvailableForDelivery">
        <CloseButton variant="first" onClick={onCloseModal} className="modalCloseButon modalNavigatiomButton" />
        <div className="notAvailableForDeliveryContainer">
          <div className="blue-container">
            <div className="heading-container">
              <span className="heading">Say it ain&#8217;t so...</span>
              <span className="sub-heading">Looks like this item can&#8217;t be delivered.</span>
              <span className="describtion">Try one of our other flavor-packed items to satisfy that craving.</span>
            </div>
          </div>
          <div className="white-container">
            <div className="footer-container">
              <div className="un-image-container">
                <div className="un-item-image" style={{ borderRadius: "4px", overflow: "hidden" }}>
                  <Image
                    height={device === "MOBILE" ? 145 : 330}
                    width={device === "MOBILE" ? 265 : 330}
                    layout="responsive"
                    src={selectedProduct?.image !== null ? selectedProduct?.image : CATEGORY_ITEM_DEFAULT_ICON}
                    alt="DEFAULT ITEM ICON"
                  />
                </div>
                <div className="item-detail">
                  <span className="item-title">{selectedProduct?.title}</span>
                  <span className="item-modifiers">{primaryChoices}</span>
                  <span className="item-size">{selectedProduct?.size}</span>
                  <span
                    className="item-cost"
                    style={{
                      paddingTop: selectedProduct?.size ? "30px" : `16px`,
                    }}
                  >
                    ${selectedProduct?.cost ? selectedProduct?.cost.toFixed(2) : selectedProduct?.calculatedCost.toFixed(2)} |{" "}
                    {selectedProduct?.calories}Cal
                  </span>
                </div>
              </div>
              <div className="un-button-cotainer">
                <PrimaryButton variant="second" onClick={onTryPickup}>
                  Try Pick Up?
                </PrimaryButton>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </NotAvailableForDeliveryStyled>
  );
};
export default NotAvailableForDelivery;
