import { useSelector } from "react-redux";
import { RootState } from "types/index";
import AddressModal from "../AddressModal/AddressModal";

const defaultValues = {
  addressType: "",
  addressLineOne: "",
  addressLineTwo: "",
  city: "",
  state: "",
  zip: "",
  favorite: false,
  latitude: 0,
  longitude: 0,
  business: false,
  id: "",
  notes: "",
  isBusiness: false,
};

const NewAddress = () => {
  const { device } = useSelector((state: RootState) => ({
    device: state.currentDevice.device,
  }));

  return (
    <AddressModal
      device={device}
      title={"ADD A NEW DELIVERY"}
      description={device === "DESKTOP" ? "The start of something tasty" : "The start of something tasty."}
      modalName="NEW_ADDRESS"
      defaultValues={defaultValues}
    />
  );
};

export default NewAddress;
