import { StrictMode, useEffect, useState } from "react";
import { AppProps } from "next/app";
import { Provider } from "react-redux";
import dynamic from "next/dynamic";
import Script from "next/script";
import { ThemeProvider } from "styled-components";
import { PersistGate } from "redux-persist/integration/react";
import Fonts from "assets/fonts";
import persistor from "redux/persistor";
import { ModalProvider } from "context/ModalContext";
import { DeviceProvider } from "context/DeviceContext";
import store from "redux/store";
import theme from "theme/theme";
import GlobalStyle from "theme/globalStyle";
import AppWrapper from "containers/AppWrapper/AppWrapper";
import ErrorBoundary from "containers/ErrorBoundary/ErrorBoundary";
import AnalyticsWrapper from "containers/AppWrapper/AnalyticsWrapper";
import { CheckmarkIcon } from "components/ItemCustomization/CheckmarkIcon/CheckmarkIcon";
import { GMapsProvider } from "context/GMapsContext";
import { MobileMenuProvider } from "context/MobileMenuContext";
const Loader = dynamic(() => import("components/Loader/Loader"), {
  ssr: false,
});

const googleKey = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY as string;
const src = `https://maps.googleapis.com/maps/api/js?key=${googleKey}&libraries=places,geometry&rankby=distance`;
const freedomPaysrc = `${process.env.NEXT_PUBLIC_FREEDOM_PAY_URL}/api/v1.5/cdn/applepay_min.js`;
const dataDomainString =
  process.env.NODE_ENV === "production" ? "7006de5f-9d39-483f-9de1-ca2fcabae94e" : "7006de5f-9d39-483f-9de1-ca2fcabae94e-test";

function MyApp({ Component, pageProps }: AppProps) {
  const [appboy, setAppboy] = useState<any>(null);
  const [isGmapsLoaded, setGmapsLoaded] = useState(false);

  useEffect(() => {
    window.history.scrollRestoration = "manual";
  }, []);

  useEffect(() => {
    import("@braze/web-sdk").then((sdk) => {
      if (sdk) setAppboy(sdk);
    });
  }, []);

  useEffect(() => {
    const registerServiceWorker = async () => {
      if ("serviceWorker" in navigator) {
        try {
          window.addEventListener("load", async () => {
            const registration = await navigator.serviceWorker.register("/service-worker.js");
            console.log("Registration successful", registration);
          });
        } catch (error) {
          console.log("Service Worker registration failed:", error);
        }
      }
    };

    registerServiceWorker();
  }, [appboy]);

  return (
    <StrictMode>
      <>
        {/* Adding this to the top level to ensure it will be added to every page per doc Input error currently comes from OperationWrapper script will fix in follow-up */}
        <Script
          async
          src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
          type="text/javascript"
          data-domain-script={dataDomainString}
        />
        <Script id="my-script" type="text/javascript">{`function OptanonWrapper() {}`}</Script>
        <Script async type="text/javascript" src={process.env.NEXT_PUBLIC_ONE_TRUST_AUTO_BLOCK_URL}></Script>
        <Script async id="usntA40start" type="text/javascript" src={process.env.NEXT_PUBLIC_ADA_SCRIPT}></Script>
        <Script async type="text/javascript" src={freedomPaysrc}></Script>
        <Script async src="https://www.googleoptimize.com/optimize.js?id=OPT-K5HL4FK"></Script>
        <Script async type="text/javascript" src={src} onLoad={() => setGmapsLoaded(true)}></Script>
      </>
      <Provider store={store}>
        {/* Due SSR issue with PersistGate I need wrap every thing in on function Read More: https://github.com/vercel/next.js/issues/8240 */}
        <PersistGate loading={null} persistor={persistor}>
          {() => (
            <>
              <AnalyticsWrapper />
              <AppWrapper>
                <ModalProvider>
                  <ThemeProvider theme={theme}>
                    <GlobalStyle />
                    <Fonts />
                    <ErrorBoundary>
                      <Loader />
                      <DeviceProvider />
                      <GMapsProvider isGmapScriptLoaded={isGmapsLoaded}>
                        <MobileMenuProvider>
                          <Component {...pageProps} />
                        </MobileMenuProvider>
                      </GMapsProvider>
                      <CheckmarkIcon />
                    </ErrorBoundary>
                  </ThemeProvider>
                </ModalProvider>
              </AppWrapper>
            </>
          )}
        </PersistGate>
      </Provider>
    </StrictMode>
  );
}

export default MyApp;
