import Image from "next/image";
import { StaticImageData } from "src/types";

interface Props {
  src: StaticImageData | string;
  alt?: string;
  layout?: "intrinsic" | "fixed" | "responsive" | "fill";
  width?: number;
  height?: number;
  [key: string]: any;
}

const CMSImage = ({ src, alt, width, height, layout = "intrinsic", ...rest }: Props) => {
  return (
    <Image
      src={src}
      alt={alt}
      width={width}
      height={height}
      layout={layout}
      objectFit="cover"
      objectPosition="center"
      unoptimized
      {...rest}
    />
  );
};

export default CMSImage;
