import { ChangeEvent, FocusEvent } from "react";
import { StyledError, TextAreaStyled } from "./TextAreaStyled";

interface Props {
  name: string;
  value: string;
  cols?: number;
  className?: string;
  maxLength?: number;
  error?: string;
  onChange: (name: string, value: string) => void;
  onBlur?: (name: string, value: string) => void;
  rows?: number;
  placeholder?: string;
  showCharacterLength?: boolean;
  label?: string;
  variant?: "largeLabel" | "smallLabel" | string;
}

const TextArea = ({
  name,
  value,
  onChange,
  onBlur,
  className,
  maxLength,
  error,
  rows,
  placeholder,
  showCharacterLength,
  label,
  variant,
}: Props) => {
  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { name: inputName, value: inputValue } = e.target;
    onChange(inputName, inputValue);
  };
  const handleBlur = (e: FocusEvent<HTMLTextAreaElement>) => {
    if (onBlur) {
      const { name: inputName, value: inputValue } = e.target;
      onBlur(inputName, inputValue);
    }
  };
  return (
    <TextAreaStyled className={className} variant={variant}>
      <label htmlFor={name}>{label}</label>
      <textarea
        value={value}
        name={name}
        rows={rows ?? 8}
        placeholder={placeholder}
        onChange={handleChange}
        onBlur={handleBlur}
        maxLength={maxLength}
      />
      {showCharacterLength && (
        <div className="totalCharacter">
          <span>
            {value?.length ? value?.length : 0}/{maxLength} Characters
          </span>
        </div>
      )}
      {error && <StyledError>{error}</StyledError>}
    </TextAreaStyled>
  );
};

export default TextArea;
