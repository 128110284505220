import { apiCall } from "@zaxbys/wrapper/frontendwrapper/apis/api";
import { CommonLayerResponseDataWithType } from "src/types";
import { CMS_FRANCHISE_AWARD } from "api/endpoints";
import { FranchiseAwardsCMSData } from "types/cms/franchise-awards";

export const getFranchiseAwardsCMSData = () => {
  return apiCall({
    requestType: "GET",
    apiEndPoint: CMS_FRANCHISE_AWARD,
  }) as Promise<CommonLayerResponseDataWithType<FranchiseAwardsCMSData>>;
};
