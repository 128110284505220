import { LocationAction } from "./types";
import {
  SET_STEP,
  SET_ORDER_MODAL,
  SET_ORDER_TYPE,
  DISABLE_PINS_ON_LOCATION,
  SET_ALTERNATIVE_FLOW,
  SET_IS_NOT_SHOW_FAVE_OR_RECENT_TABS,
  SET_IS_MAP_DRAGGED,
} from "./LocationConst";

const initialState = {
  step: "LOGGED_IN_SEARCH",
  orderModal: "",
  orderType: "PICKUP",
  isAlternativeFlow: false,
  disablePinsOnLocation: false,
  isNotShowFaveOrRecentTabs: false,
  isMapDragged: false,
};

export default function LocationReducer(state = initialState, action: LocationAction) {
  switch (action.type) {
    case SET_STEP:
      return {
        ...state,
        step: action.payload,
      };
    case SET_ORDER_MODAL:
      return {
        ...state,
        orderModal: action.payload,
      };
    case SET_ORDER_TYPE:
      return {
        ...state,
        orderType: action.payload,
      };
    case SET_ALTERNATIVE_FLOW:
      return {
        ...state,
        isAlternativeFlow: action.payload,
      };

    case DISABLE_PINS_ON_LOCATION:
      return {
        ...state,
        disablePinsOnLocation: action.payload,
      };

    case SET_IS_NOT_SHOW_FAVE_OR_RECENT_TABS:
      return {
        ...state,
        isNotShowFaveOrRecentTabs: action.payload,
      };
    case SET_IS_MAP_DRAGGED:
      return {
        ...state,
        isMapDragged: action.payload,
      };
    default:
      return state;
  }
}
