import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ANALYTICS_CONST } from "@zaxbys/wrapper";
import Analytics from "analytics/Analytics";
import { setLocationOrderType, setLocationStep } from "redux/reducer/Location/LocationAction";
import { setPresentUserOrderType, setSelectStoreModalFlag } from "redux/reducer/UserStatus/UserStatusAction";
import AuthCard from "components/Auth/AuthCard/AuthCard";
import useModal from "hooks/useModal";
import { getSelectStoreModalDataFromCMS } from "api/cms/store-details/get-select-store-modal-data-cms";
import { StoreModalCMSData } from "types/cms/store-details";
import PrimaryButton from "components/global/Buttons/PrimaryButton/PrimaryButton";
import Modal from "components/global/Modal/Modal";
import Prompt from "components/global/Prompt/Prompt";
import StackedButtonsContainer from "components/global/StackedButtonsContainer/StackedButtonsContainer";
import TextBlock from "components/global/TextBlock/TextBlock";
import { SelectStoreModalStyled } from "./SelectStoreModalStyled";

const SelectStoreModal = () => {
  const dispatch = useDispatch();

  const router = useRouter();
  const { modal, setModal } = useModal();
  const [cmsData, setCMSData] = useState<StoreModalCMSData>({ Body: "", Title: "", ReBrandTitle: "" });
  const titleArray: Array<string> = cmsData?.ReBrandTitle?.split(" ");
  useEffect(() => {
    getSelectStoreModalDataFromCMS().then((res: any) => {
      setCMSData(res.response);
    });

    // Log the event on opening the popup for select store
    Analytics.getInstance().logEvent(ANALYTICS_CONST.STORE.SELECT_A_STORE_POPUP);
  }, []);

  const BODY = (
    <div className="headings">
      <Prompt size="lg" color="blue" className="title">
        {titleArray?.map((word) => {
          return word.startsWith("_**") || word.endsWith("**_") ? (
            <span className="locationString">{word.replace("_**", "").replace("**_", "") + " "}</span>
          ) : (
            word + " "
          );
        })}
      </Prompt>

      <TextBlock size="lg" className="subHeading">
        {cmsData?.Body}
      </TextBlock>
    </div>
  );
  const handlePickup = () => {
    dispatch(setSelectStoreModalFlag(true));
    dispatch(setLocationOrderType("PICKUP"));
    dispatch(setPresentUserOrderType("PICKUP"));
    dispatch(setLocationStep("LOGGED_IN_SEARCH"));
    router.push("/locations");
  };

  const handleDelivery = () => {
    dispatch(setSelectStoreModalFlag(true));
    dispatch(setLocationOrderType("DELIVERY"));
    dispatch(setPresentUserOrderType("DELIVERY"));
    dispatch(setLocationStep("LOGGED_IN_SEARCH"));
    router.push("/locations");
  };

  const FOOTER = (
    <StackedButtonsContainer direction="col" mobileDirection="col" className="stackedButton">
      <PrimaryButton variant="fourth" onClick={handlePickup} className="pickupButton">
        PICKUP
      </PrimaryButton>
      <PrimaryButton variant="fourth" onClick={handleDelivery} className="deliveryButton">
        DELIVERY
      </PrimaryButton>
    </StackedButtonsContainer>
  );

  return (
    <SelectStoreModalStyled>
      <Modal isOpen={modal === "SELECT_STORE_MODAL"} className="modal">
        <div className="selectStoreModalContainer">
          <AuthCard
            className="authCardStoreModal"
            body={BODY}
            footer={FOOTER}
            isCloseRequired={true}
            onClose={() => setModal("")}
            isButtonTextVisible={true}
          />
        </div>
      </Modal>
    </SelectStoreModalStyled>
  );
};

export default SelectStoreModal;
