import { useEffect } from "react";
import kountSDK from "@kount/kount-web-client-sdk";
import isClient from "utils/helper/default";
import { generateUUID } from "utils/generateUUID";
import { getSessionStorageData, setSessionStorageData } from "utils/storage";

export const useKountInit = () => {
  const isNotProductionEnv = process.env.NEXT_PUBLIC_APP_ENV !== "prod";
  const kountConfig = {
    clientID: process.env.NEXT_PUBLIC_KOUNT_CLIENT_ID,
    environment: isNotProductionEnv ? "TEST" : "PROD",
    isSinglePageApp: true,
    isDebugEnabled: isNotProductionEnv,
    callbacks: {
      "collect-begin": function (params: any) {
        console.log({ params }, "collect-begin");
      },
      "collect-end": function (params: any) {
        console.log({ params }, "collect-end");
      },
    },
  };

  useEffect(() => {
    if (isClient() && kountSDK) {
      const kountSessionId = getSessionStorageData("kountSessionId");

      const sessionID = generateUUID()?.substring(0, 32);

      setSessionStorageData("kountSessionId", kountSessionId ?? sessionID);

      kountSDK(kountConfig, kountSessionId ?? sessionID);
    }
  }, [isClient(), kountSDK]);
};
