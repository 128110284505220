import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Slider from "react-slick";
import {
  getFavouriteStore,
  setFavouriteStore,
  setFilteredStoreList,
  setStoreDetailsPin,
} from "@zaxbys/wrapper/frontendwrapper/redux/store/actions";
import CarouselButton from "components/home/Homepage/HomepageCarousel/CarouselButton/CarouselButton";
import AddressDeliveryCard from "components/OrderNow/AddressSelectedCard/AddressDeliveryCard/AddressDeliveryCard";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { RootState, Store } from "src/types";
import { sortList } from "utils/objectSorting";
import { NearByCardStyled } from "./NearByCardStyled";

interface Props {
  className?: string;
  isNearByCard?: boolean;
  isSlider?: boolean;
  isPinnedLocation?: boolean;
}
const NearByCard = ({ className, isNearByCard, isSlider, isPinnedLocation }: Props) => {
  const dispatch = useDispatch();
  const { storeList, filteredRadius, favouriteStore, details, tokens, selectedStoreId } = useSelector((state: RootState) => ({
    storeList: state.store?.storeList,
    filteredRadius: state.store?.filteredRadius,
    favouriteStore: state.store?.favouriteStore,
    details: state.user.details,
    tokens: state.user.tokens,
    selectedStoreId: state.store?.selectedStoreId,
  }));

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    variableWidth: true,
    adaptiveHeight: false,
    nextArrow: <CarouselButton id="next-button" direction="right" />,
    prevArrow: <CarouselButton id="back-button" direction="left" />,
  };

  // Sort the store list in ascending order
  const list = sortList(storeList, "distance", "asc");
  let filteredList = list;

  useEffect(() => {
    filteredList = list?.filter((item: any) => item.distance <= filteredRadius);
  }, [filteredRadius]);

  // Set filtered stored list
  useEffect(() => {
    dispatch(setFilteredStoreList(filteredList));
  }, [filteredList]);

  // No idea why it is set
  useEffect(() => {
    dispatch(setStoreDetailsPin(false));
  }, []);

  // Get Favourite store list
  useEffect(() => {
    if (tokens && details) {
      dispatch(
        getFavouriteStore({
          idToken: tokens?.idToken,
          externalId: details?.externalId,
        })
      );
    }
  }, [favouriteStore]);

  // Filter the selected store from the Slider on Store Detail page
  const UpdatedList = isSlider ? filteredList?.filter((x: Store) => x.storeId !== selectedStoreId) : filteredList;

  // Make Fav/UnFave the store
  const handleUpdateFavoriteStore = async (favorite: boolean, storeId?: number) => {
    if (tokens && details && storeId) {
      const payload = {
        storeId,
        default: favorite,
        idToken: tokens?.idToken || "",
        externalId: details?.externalId || "",
      };
      dispatch(setFavouriteStore(payload));
    }
  };

  const storeCardDetails = UpdatedList?.map((store: Store, index: number) => {
    return (
      <AddressDeliveryCard
        key={index}
        storeData={store}
        className={className}
        Footer={<></>}
        onUpdateFavoriteStore={handleUpdateFavoriteStore}
        isNearByCard={isNearByCard}
        isPinnedLocation={isPinnedLocation}
      />
    );
  });
  return (
    <>
      {isSlider ? (
        <NearByCardStyled>
          <Slider {...settings}>{storeCardDetails}</Slider>
        </NearByCardStyled>
      ) : (
        <>{storeCardDetails}</>
      )}
    </>
  );
};

export default NearByCard;
