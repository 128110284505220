import router from "next/router";
import { ReactNode, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { datadogRum } from "@datadog/browser-rum";
import { setUserDetails, setUserTokens } from "@zaxbys/wrapper/frontendwrapper/redux/user/actions";
import {
  getFavouriteStore,
  setPickupOrderType,
  setSelectedStore,
  setSelectedStoreName,
} from "@zaxbys/wrapper/frontendwrapper/redux/store/actions";
import { setBasketStoreId } from "@zaxbys/wrapper/frontendwrapper/redux/basket/actions";
import { useBasket } from "@zaxbys/wrapper";
import { getMe } from "api/users/me";
import { RootState } from "src/types";
import useFingerPrinting from "hooks/useFingerPrinting";
import {
  deleteLocalStorageData,
  deleteSessionStorageData,
  getDataFromCookie,
  getLocalStorageData,
  getSessionStorageData,
  setLocalStorageData,
  setSessionStorageData,
} from "utils/storage";
import { getAddressList, setUserAddress } from "redux/reducer/Address/AddressAction";
import { linkBasketToUser } from "redux/reducer/ReOrder/ReOrderAction";
import { useCurbsideOrder } from "containers/CurbsideBanner/hooks/useCurbsideOrder";
import { setPresentUserOrderType, setRememberStatus } from "redux/reducer/UserStatus/UserStatusAction";
import { handleUserLogout } from "utils/helper";
interface Props {
  children: ReactNode;
}

const AppWrapper = ({ children }: Props) => {
  const dispatch = useDispatch();
  const { getBasketDetails } = useBasket();

  const { tokens, details, basket } = useSelector((state: RootState) => ({
    tokens: state.user.tokens,
    details: state.user.details,
    basket: state.basket?.basket,
  }));

  // fetch signatures data from API & update in the headers
  useFingerPrinting();

  useEffect(() => {
    function tokenHandle(tokenDetails: any) {
      if (getSessionStorageData("tokens")) {
        setSessionStorageData("tokens", JSON.stringify(tokenDetails.detail));
      } else {
        setLocalStorageData("tokens", JSON.stringify(tokenDetails.detail));
      }

      dispatch(setUserTokens(tokenDetails.detail));
      loadMeData(tokenDetails.idToken);
    }

    if (typeof window !== "undefined") {
      window.addEventListener("setTokenInRedux", tokenHandle);
    }
    return () => {
      window.removeEventListener("setTokenInRedux", tokenHandle);
    };
  }, []);

  useEffect(() => {
    function tokenExpireHandle(tokenDetails: any) {
      handleUserLogout();
      router.push("/account/logged-out");
    }

    if (typeof window !== "undefined") {
      window.addEventListener("refreshTokenExpired", tokenExpireHandle);
    }
    return () => {
      window.removeEventListener("refreshTokenExpired", tokenExpireHandle);
    };
  }, []);

  useEffect(() => {
    // if tokens exists on session storage, then take it from there otherwise check in local storage
    const stringCred = getSessionStorageData("tokens") != null ? getSessionStorageData("tokens") : getLocalStorageData("tokens");
    if (stringCred) {
      try {
        const parsedCred = JSON.parse(stringCred);
        if (parsedCred) {
          loadMeData(parsedCred?.idToken);
          dispatch(setUserTokens(parsedCred));
        }
      } catch (err) {
        deleteLocalStorageData("tokens");
        deleteSessionStorageData("tokens");
      }
    }
  }, []);

  useEffect(() => {
    // This is used to check if migrated user opens legal page in new tab then dont navigate to home
    if (details?.acceptedMigrationTerms === false && !router.pathname.includes("/legal/")) {
      router.push("/");
    }
  }, [details?.acceptedMigrationTerms]);

  const loadMeData = async (idToken: string) => {
    const response = await getMe({ idToken: idToken });

    if (response.success) {
      // Added user context in datadog
      // https://docs.datadoghq.com/real_user_monitoring/browser/advanced_configuration/?tab=npm#identify-user-sessions
      datadogRum.setUser({
        id: response.response.cdpId,
        name: `${response.response.firstName} ${response.response.lastName}`,
        email: response.response.email,
      });
    }
    dispatch(setUserDetails(response.response));
  };

  useEffect(() => {
    if (details?.externalId) {
      const decryptedBasketId = getDataFromCookie("basketId");

      dispatch(
        getAddressList({
          idToken: tokens?.idToken,
          externalId: details.externalId,
        })
      );
      dispatch(
        getFavouriteStore({
          externalId: details.externalId,
          idToken: tokens?.idToken,
        })
      );
      try {
        if (decryptedBasketId) {
          const localData = getLocalStorageData("store");
          if (localData) {
            const parsedData = JSON.parse(localData);
            if (parsedData) {
              dispatch(setPickupOrderType(parsedData.pickupOrderType));
              dispatch(setBasketStoreId(parsedData.selectedStoreId));
              dispatch(setSelectedStore(parsedData.selectedStoreId));
              dispatch(setSelectedStoreName(parsedData.selectedStoreDetail));
              dispatch(setPresentUserOrderType(parsedData.presentUserOrderType));
              dispatch(setUserAddress(parsedData.userAddress));
            }
          }
          getBasketDetails(decryptedBasketId);
          dispatch(setRememberStatus(true));
        }
        if (decryptedBasketId || basket?.basketId) {
          dispatch(linkBasketToUser(tokens.accessToken, decryptedBasketId || basket.basketId, tokens.idToken, details.externalId));
        }
      } catch (err) {
        // This below is created problem to delete a token from local storage
      }
    }
  }, [details?.externalId]);

  // Check is any Curbside order ready to be delievered
  useCurbsideOrder();

  return <>{children}</>;
};

export default AppWrapper;
