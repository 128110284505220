import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "src/types";
import { ADD_NEW_DELIVERY_ADDRESS } from "constants/constant";
import { Values } from "containers/Order/modals/AddressModal/types";
import Headings from "components/atomic-components/atoms/typography/Headings/Headings";
import Paragraph from "components/atomic-components/atoms/typography/Paragraphs/Paragraph";
import Checkbox from "components/atomic-components/atoms/form-elements/Checkbox/Checkbox";
import TextArea from "components/atomic-components/atoms/form-elements/TextArea/TextArea";
import TextField from "components/atomic-components/atoms/form-elements/TextField/TextField";
import Button from "components/atomic-components/atoms/button/Button";
import AddressSuggestions from "components/atomic-components/atoms/form-elements/AddressSuggestions/AddressSuggestions";
import GenericError from "components/atomic-components/atoms/GenericError/GenericError";
import StackedInputsContainer from "components/atomic-components/molecules/StackedInputsContainer/StackedInputsContainer";
import StackedButtonsContainer from "../StackedButtonsContainer/StackedButtonsContainer";
import { AddressFormStyled } from "./AddressFormStyled";
import { useAddressForm } from "./useAddressForm";

interface Props {
  title: string;
  description: string;
  defaultValues: Values;
  from?: string;
  className?: string;
  modalName?: string;
}

const AddressForm = ({ title, from, description, defaultValues, className, modalName }: Props) => {
  const { details, tokens, isAuthenticated, device } = useSelector((state: RootState) => ({
    details: state.user.details,
    tokens: state.user.tokens,
    isAuthenticated: state.user.isAuthenticated,
    device: state.currentDevice.device,
  }));
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const {
    values,
    isLoading,
    myRef,
    handleSave,
    genericError,
    errors,
    handleCleanCustomName,
    handleCleanAddressType,
    handleCleanCityName,
    handleCleanStateName,
    handleCleanZip,
    handleChange,
    handleBlur,
    smartySuggestion,
    selectedSuggestion,
    onOptionClick,
    handleDeliverHere,
  } = useAddressForm(defaultValues, from, title, modalName);

  useEffect(() => {
    if (className === "address-screen") {
      document.body.classList.add("address-screen");
    }
    return () => document.body.classList.remove("address-screen");
  });
  useEffect(() => {
    if (values.addressLineOne === "" || values.city === "" || values.state === "" || values.zip === "") {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  }, [values]);
  return (
    <AddressFormStyled className={className} ref={myRef} buttonDisabled={isLoading || isButtonDisabled}>
      <Headings variant="headingMedium" className={`title ${device === "MOBILE" && "display-linebreak"}`}>
        {title} ADDRESS
      </Headings>
      <Paragraph variant="primaryParagraph1" className="description">
        {description}
      </Paragraph>
      <StackedInputsContainer className="inputsContainer">
        {details && tokens && (
          <Checkbox
            name="prefferedAddress"
            value={values.favorite}
            className="favouriteMessage"
            onChange={() => handleChange("favorite", !values.favorite)}
            variant="primaryBlueFilled"
          >
            <Paragraph variant="" className="text">
              Make this your <span className="alt">PREFERRED ADDRESS</span>
            </Paragraph>
          </Checkbox>
        )}
        <TextField
          name="addressType"
          value={values.addressType}
          onChange={handleChange}
          cleanFunction={handleCleanCustomName}
          label="Custom Name (optional)"
          onBlur={handleBlur}
        />
        <AddressSuggestions suggestions={smartySuggestion} query={values.addressLineOne} onSelect={onOptionClick}>
          {selectedSuggestion && (
            <TextField
              name="addressLineOne"
              value={values.addressLineOne}
              error={errors.addressLineOne}
              onChange={handleChange}
              cleanFunction={handleCleanAddressType}
              label="Address line 1"
              id="address1"
              required
              className="customAutoComplete"
              onBlur={handleBlur}
            />
          )}
        </AddressSuggestions>
        <TextField
          name="addressLineTwo"
          value={values.addressLineTwo}
          onChange={handleChange}
          cleanFunction={handleCleanAddressType}
          label="Address line 2 (optional)"
          onBlur={handleBlur}
        />
        <TextField
          name="city"
          value={values.city}
          error={errors.city}
          onChange={handleChange}
          cleanFunction={handleCleanCityName}
          label="City"
          onBlur={handleBlur}
          required
        />
        <TextField
          name="state"
          value={values.state}
          error={errors.state}
          onChange={handleChange}
          cleanFunction={handleCleanStateName}
          onBlur={handleBlur}
          label="State"
          required
        />
        <TextField
          name="zip"
          value={values.zip}
          error={errors.zip}
          onChange={handleChange}
          cleanFunction={handleCleanZip}
          onBlur={handleBlur}
          label="Zip code"
          required
        />
        <Checkbox
          variant="primaryBlueFilled"
          name="isBusiness"
          value={values.isBusiness}
          onChange={handleChange}
          className="businessCheckBox"
        >
          <Paragraph variant="primaryParagraph1" className="checkBoxText noRedText">
            This is a Business
          </Paragraph>
        </Checkbox>
        <TextArea
          variant="largeLabel"
          label="LEAVE YOUR DRIVER A MESSAGE"
          name="notes"
          value={values.notes}
          onChange={handleChange}
          maxLength={100}
          placeholder="Have a request? Instructions? Tell us!"
          showCharacterLength
          className="textAreaInput"
        />
      </StackedInputsContainer>

      <GenericError className="genericError">{genericError}</GenericError>
      {from !== "account" && (
        <StackedButtonsContainer direction="col" className="confirmAddressButtons" mobileDirection="col">
          {isAuthenticated && modalName !== "CONFIRM_ADDRESS" && (
            <Button
              variant="secondaryBlue"
              className="saveButton"
              onClick={() => handleSave("fromSave")}
              disabled={isLoading || isButtonDisabled}
            >
              SAVE
            </Button>
          )}

          <Button
            variant="primaryRed"
            onClick={() => {
              handleDeliverHere(true);
              setIsButtonDisabled(true);
            }}
            disabled={isLoading || isButtonDisabled}
          >
            {isAuthenticated ? `Save & Deliver Here` : `Deliver Here`}
          </Button>
        </StackedButtonsContainer>
      )}
      {from === "account" && (
        <StackedButtonsContainer direction="row" mobileDirection="col">
          <Button variant="primaryRed" onClick={() => handleSave()} disabled={isLoading || isButtonDisabled} className="addressSaveBtn">
            {title === ADD_NEW_DELIVERY_ADDRESS ? "Save" : "Update"}
          </Button>
        </StackedButtonsContainer>
      )}
    </AddressFormStyled>
  );
};

export default AddressForm;
