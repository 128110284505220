import { PropsWithChildren, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "types/index";
import { ModalStyled, ModalOverlay } from "./ModalStyled";

interface Props extends PropsWithChildren {
  isOpen: boolean;
  className?: string;
  overlayClassName?: string;
  [key: string]: any;
  isCheckoutModal?: boolean;
  isAccountCreatedModal?: boolean;
}

const Modal = ({ isOpen, children, className, overlayClassName, ...rest }: Props) => {
  useEffect(() => {
    document.body.classList.add("modalOpen");

    return () => {
      document.body.classList.remove("modalOpen");
    };
  }, []);

  useEffect(() => {
    if (!isOpen) {
      document.body.classList.remove("modalOpen");
    }
  }, [isOpen]);

  const orderModal = useSelector((state: RootState) => state.location?.orderModal);

  useEffect(() => {
    if (orderModal === "CONFIRM_ADDRESS" || orderModal === "NEW_ADDRESS") {
      document.body.classList.add("addressAutoComplete");
    } else {
      document.body.classList.remove("addressAutoComplete");
    }
    document.body.classList.add("modalOpen");
    return () => {
      document.body.classList.remove("addressAutoComplete");
      document.body.classList.remove("modalOpen");
    };
  }, [orderModal]);

  return isOpen ? (
    <>
      <ModalOverlay className={overlayClassName}>
        <ModalStyled className={className} {...rest}>
          {children}
        </ModalStyled>
      </ModalOverlay>
    </>
  ) : null;
};

export default Modal;
