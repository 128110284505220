import { apiCall } from "@zaxbys/wrapper/frontendwrapper/apis/api";
import { CommonLayerResponseDataWithType } from "src/types";
import { VERIFY_USER } from "../endpoints";

interface Request {
  idToken: string;
  code: string;
  accessToken: string;
  attribute: "PHONE" | "EMAIL";
}

interface Response {
  message: string;
}

export const verifyUserAttribute = async (data: Request) => {
  return apiCall({
    apiEndPoint: VERIFY_USER,
    requestType: "PUT",
    isAuth: true,
    apiPostData: {
      code: data.code,
      accessToken: data.accessToken,
      attribute: data.attribute,
    },
    token: data.idToken,
  }) as Promise<CommonLayerResponseDataWithType<Response>>;
};
