import styled from "styled-components";

export const DeliveryCardSelectedAddressStyled = styled.div`
  .btnWrapper {
    gap: 12px;
  }
  .overlay {
    .title {
      margin-bottom: 10px;
    }

    .inputsContainer {
      .input {
        max-height: 220px;

        textarea {
          min-height: unset;
        }
      }
    }
  }

  .deliveryText {
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0px;
  }

  .etaText {
    font-size: 20px;
    font-weight: 700;
    color: ${(props) => props.theme.color.primary.tongueTorchRed};
    margin-bottom: 20px;
    letter-spacing: 0;
  }

  .ctaButtons {
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 220px;
    height: 36px;
  }

  @media only screen and (max-width: ${(props) => props.theme.size.lg}) {
    &.deliveryCardSelectedAddress {
      .addressDeliveryCard {
        margin-bottom: 75px;
        .cardBody {
          .favoriteStore {
            position: absolute;
            top: 17px;
            right: 21px;
          }
          .address-wrapper {
            &.deliveryAuthWrapper {
              margin-top: 5px;
            }
          }
        }
        .cardFooter {
          .btnWrapper {
            button {
              width: 171px;
            }
          }
        }
      }
    }
    .ctaButtons {
      width: 220px !important;
    }
  }
`;
